import React from 'react';

import { FormControl, Select, OutlinedInput } from '@material-ui/core';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import commonStyles from '../../../../commonUtils/commonStyles';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.blue,
    },
  },
});

function DropDownComponent(props) {
  /* props:
        id
        values
        selValue
        onChange
    */
  let inputPropsObj;
  if (props.tabIndex !== undefined) {
    inputPropsObj = { style: { padding: '13px' }, tabIndex: props.tabIndex };
  } else {
    inputPropsObj = { style: { padding: '13px' } };
  }
  let allValues = [];
  props.values.forEach((item, index) => {
    allValues.push(
      <option value={index} key={index}>
        {item}
      </option>
    );
  });
  return (
    <FormControl
      variant="outlined"
      style={{
        width: '100%',
      }}
    >
      <MuiThemeProvider theme={theme}>
        <Select
          native
          id={props.id}
          //variant="outlined"
          disabled={props.disabled ? props.disabled : false}
          value={props.selValue}
          //margin="dense"
          input={<OutlinedInput inputProps={inputPropsObj} />}
          onChange={props.onChange}
        >
          {allValues}
        </Select>
      </MuiThemeProvider>
    </FormControl>
  );
}

export default DropDownComponent;
