import React from 'react';
import { Card, Box } from '@material-ui/core';
import ButtonComponent from '../../Components/Elements/Button/ButtonComponent';
import PropTypes from 'prop-types';

const Head = ({ selectedItem, downloadDocument, openCart, setCount }) => {
  const { boxRight, card } = getStyles();

  const isExpired = () => {
    const today = new Date();
    const expirationDate = new Date(selectedItem.expiryDate);
    return today > expirationDate;
  };

  return (
    <Card style={{ ...card }}>
      <Box display="flex" padding="10px">
        <ButtonComponent disabled={!selectedItem} variant="outlined" onClick={downloadDocument} color="primary">
          Скачать коммерческое предложение
        </ButtonComponent>
        <Box flexGrow={1} display="flex" justifyContent="flex-end" alignItems="center">
          <ButtonComponent
            disabled={!selectedItem}
            onClick={openCart}
            style={{ ...boxRight }}
            variant="contained"
            color="primary"
          >
            Открыть в корзине
          </ButtonComponent>
          <ButtonComponent
            disabled={!selectedItem || isExpired()}
            onClick={setCount}
            variant="contained"
            color="primary"
          >
            Выставить счет
          </ButtonComponent>
        </Box>
      </Box>
    </Card>
  );
};

const getStyles = () => {
  return {
    boxRight: {
      marginRight: '20px',
    },
    card: {
      marginTop: '175px',
    },
  };
};

Head.propTypes = {
  selectedItem: PropTypes.object,
};

Head.defaultProps = {
  selectedItem: null,
};

export default Head;
