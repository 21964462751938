import React, { useState, useEffect, useContext } from 'react';
import { Paper, Box, Container, createMuiTheme, ThemeProvider } from '@material-ui/core';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import HydraulicCalculation from './Components/HydraulicCalculation';
import HeatingSystem from './Components/HeatingSystem';

import { GlobalContext } from '../..';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../constants';

import commonStyles from '../../commonUtils/commonStyles';
import {
  powerTypes,
  HWSTemperatureCheckInit,
  updateHWSTemperatureCheck,
  makeHWSTemperatureCheck,
} from '../../commonUtils/utilsOVIC';
import { validateText, validateText2 } from '../../commonUtils/commonUtils';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const HotWaterSupply = () => {
  useEffect(() => {
    HWSTemperatureCheckInit();
  }, []);

  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: raschetGVS } = useServerRequest(setGlobalLoading);
  const { consultant } = useContext(GlobalContext);

  const workingPressureMaxValues = [
    { value: '10 кгс/см2', label: '10 кгс/см2' },
    { value: '16 кгс/см2', label: '16 кгс/см2' },
  ];
  const powerTypeValues = powerTypes.map((type) => ({ value: type, label: type }));

  const setValueHandler = (event, field) => {
    const val = event.target.value;
    switch (field) {
      case 'heatLoad':
        setDataValue((prev) => ({ ...prev, heatLoad: val, massFlowHeated: '' }));
        break;
      case 'massFlowHeated':
        setDataValue((prev) => ({ ...prev, massFlowHeated: val, heatLoad: '' }));
        break;
      default:
        if (field === 'inletTemperatureHeating') updateHWSTemperatureCheck('hot_temperature_vhod', val);
        if (field === 'outletTemperatureHeating') updateHWSTemperatureCheck('hot_temperature_vyhod', val);
        if (field === 'inletTemperatureHeated') updateHWSTemperatureCheck('cold_temperature_vhod', val);
        if (field === 'outletTemperatureHeated') updateHWSTemperatureCheck('cold_temperature_vyhod', val);
        setDataValue((prev) => ({ ...prev, [field]: val }));
        break;
    }
  };

  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);

  const [temperatureFieldsIncorrect, setTemperatureFieldsIncorrect] = useState({
    inletTemperatureHeating: false,
    outletTemperatureHeating: false,
    inletTemperatureHeated: false,
    outletTemperatureHeated: false,
  });

  const { titleText, formBox, formContainer, labelPosition } = getStyles();

  const [data, setDataValue] = useState({
    //Данные для тепло-гидравлического расчета
    name: '', //Название объекта
    workingTemperatureMax: '150', //Рабочая температура
    workingPressureMax: workingPressureMaxValues[1].value, //Рабочее давление
    heatLoad: '', //Тепловая нагрузка
    heatLoadOption: powerTypeValues[0].value, //Тепловая нагрузка - размерность
    surfaceMargin: '', //Запас по поверхности
    mediumTypeHeating: 'Вода', //Греющая
    mediumTypeHeated: 'Вода', //Нагреваемая
    massFlowHeated: '', // Масовый расход нагреваемая
    inletTemperatureHeating: '', //Температура на входе Греющая
    inletTemperatureHeated: '', //Температура на входе Нагреваемая
    outletTemperatureHeating: '', //Температура на выходе Греющая
    outletTemperatureHeated: '', //Температура на выходе Нагреваемая
    allowableHeadLossHeating: '', //Допускаемые потери напора в ПТО Греющая
    allowableHeadLossHeated: '', //Допускаемые потери напора в ПТО Нагреваемая
    //Данные системы отопления
    returnFlowHeatLoad: '', //Расход обратной отопительной воды - Тепловая нагрузка
    scheduleHeatLoad: '', //Тепловая нагрузка и отопительный график - Тепловая нагрузка
    scheduleHeatLoadType: powerTypeValues[0].value, //Тепловая нагрузка и отопительный график - Тепловая нагрузка Размерность
    heatingScheduleFrom: '', //Тепловая нагрузка и отопительный график - Отопительный график От
    heatingScheduleTo: '', //Тепловая нагрузка и отопительный график - Отопительный график До
    additionalComment: '', //Комментарий
    //RadioButtons
    returnFlowVSschedule: '', //Расход обратной отопительной воды (waterReturnFlow) ИЛИ Тепловая нагрузка и отопительный график (heatLoadSchedule)
    monoblockVSseparateStages: '', //Моноблок (monoblock) ИЛИ Раздельные 1 и 2 ступень (separateStages)
  });

  //Validation
  useEffect(() => {
    const dataForTest = { ...data };
    let skippedKeys = [
      'workingTemperatureMax',
      'workingPressureMax',
      'heatLoadOption',
      'mediumTypeHeating',
      'mediumTypeHeated',
      'returnFlowVSschedule',
      'scheduleHeatLoadType',
      'additionalComment',
    ];
    if (dataForTest.returnFlowVSschedule === 'waterReturnFlow') {
      skippedKeys.push('scheduleHeatLoad');
      skippedKeys.push('heatingScheduleFrom');
      skippedKeys.push('heatingScheduleTo');
    }
    if (dataForTest.returnFlowVSschedule === 'heatLoadSchedule') {
      skippedKeys.push('returnFlowHeatLoad');
    }
    if (dataForTest.heatLoad !== '') skippedKeys.push('massFlowHeated');
    if (dataForTest.massFlowHeated !== '') skippedKeys.push('heatLoad');
    const objValid = checkAllDataFields(dataForTest, skippedKeys);

    setSendButtonDisabled(!objValid);
  }, [data]);

  const checkAllDataFields = (dataForTest, skippedKeys) => {
    for (const key in dataForTest) {
      if (!skippedKeys.includes(key)) {
        if (key === 'name') {
          if (!validateText(dataForTest[key])) return false;
        } else {
          if (!validateText2(dataForTest[key])) return false;
        }
      }
    }
    return true;
  };
  /*
  const buildDataObjForServer = () => {
    const dataCopy = { ...data };
    const dataObj = {
      thcData: {
        nameOfObject: dataCopy.name,
        workingTemperature: '150 ℃',
        workingPressure: dataCopy.workingPressureMax,
        heatLoad: (dataCopy.heatLoad !== '' ? dataCopy.heatLoad : '0') + ' ' + dataCopy.heatLoadOption,
        surfaceMargin: dataCopy.surfaceMargin + ' %',
        heating: {
          mediaType: 'Вода',
          massFlow: '0 т/ч',
          inletTemperature: dataCopy.inletTemperatureHeating + ' ℃',
          outletTemperature: dataCopy.outletTemperatureHeating + ' ℃',
          allowableLoss: dataCopy.allowableHeadLossHeating + ' м.в.с',
        },
        heated: {
          mediaType: 'Вода',
          massFlow: (dataCopy.massFlowHeated !== '' ? dataCopy.massFlowHeated : '0') + ' т/ч',
          inletTemperature: dataCopy.inletTemperatureHeated + ' ℃',
          outletTemperature: dataCopy.outletTemperatureHeated + ' ℃',
          allowableLoss: dataCopy.allowableHeadLossHeated + ' м.в.с',
        },
      },
      heatingInformation: {
        isReturnWaterFlow: dataCopy.returnFlowVSschedule === 'waterReturnFlow' ? true : false,
        massFlow:
          (dataCopy.returnFlowVSschedule === 'waterReturnFlow'
            ? dataCopy.returnFlowHeatLoad !== ''
              ? dataCopy.returnFlowHeatLoad
              : '0'
            : '0') + ' т/ч',
        heatLoad:
          dataCopy.returnFlowVSschedule === 'heatLoadSchedule'
            ? dataCopy.scheduleHeatLoad !== ''
              ? dataCopy.scheduleHeatLoad + ' ' + dataCopy.scheduleHeatLoadType
              : '0 кВт'
            : '0 кВт',
        heatingSchedule:
          dataCopy.returnFlowVSschedule === 'heatLoadSchedule'
            ? dataCopy.heatingScheduleFrom + ' - ' + dataCopy.heatingScheduleTo
            : '0 - 0',
        isMonoblock: dataCopy.monoblockVSseparateStages === 'monoblock' ? true : false,
        commentaries: dataCopy.additionalComment,
      },
    };
    return dataObj;
  };
  */

  function setSelectBoxByIndex(docum, eid, elemval) {
    var elem = docum.getElementById(eid);

    var optionsArr = elem.getElementsByTagName('option');
    var selectedOption;
    for (var i = 0; i < optionsArr.length; ++i) {
      selectedOption = optionsArr[i].outerHTML;
      selectedOption = selectedOption.replace('selected="selected" ', '');
      optionsArr[i].outerHTML = selectedOption;
      if (optionsArr[i].value === elemval) {
        selectedOption = optionsArr[i].outerHTML;
        selectedOption = selectedOption.replace('<option ', '<option selected="selected" ');
        optionsArr[i].outerHTML = selectedOption;
      }
    }
    return docum.getElementById(eid).outerHTML;
  }

  function sendButtonClickedHandler() {
    const checkResult = makeHWSTemperatureCheck();
    let newTemperatureFieldsIncorrect = {
      inletTemperatureHeating: false,
      outletTemperatureHeating: false,
      inletTemperatureHeated: false,
      outletTemperatureHeated: false,
    };
    if (checkResult.success === false) {
      let errorsArr = checkResult.error.code;
      errorsArr.forEach((errorCode) => {
        if (errorCode === 2) {
          newTemperatureFieldsIncorrect.inletTemperatureHeating = true;
          newTemperatureFieldsIncorrect.outletTemperatureHeating = true;
        }
        if (errorCode === 3) {
          newTemperatureFieldsIncorrect.inletTemperatureHeated = true;
          newTemperatureFieldsIncorrect.outletTemperatureHeated = true;
        }
        if (errorCode === 4) {
          newTemperatureFieldsIncorrect.inletTemperatureHeating = true;
          newTemperatureFieldsIncorrect.outletTemperatureHeated = true;
        }
        if (errorCode === 5) {
          newTemperatureFieldsIncorrect.outletTemperatureHeating = true;
          newTemperatureFieldsIncorrect.inletTemperatureHeated = true;
        }
        if (errorCode === 6) {
          newTemperatureFieldsIncorrect.outletTemperatureHeating = true;
          newTemperatureFieldsIncorrect.inletTemperatureHeating = true;
        }
        if (errorCode === 7) {
          newTemperatureFieldsIncorrect.outletTemperatureHeated = true;
          newTemperatureFieldsIncorrect.inletTemperatureHeated = true;
        }
      });
      setGlobalState((prevState) => ({
        ...prevState,
        toastOpen: true,
        toastMessage: 'Ошибка! ' + checkResult.error.message,
        toastStyle: 'error',
      }));
    } else {
      //everything is correct - send data to server

      // Set dropdown elements to fix default states in innerHTML
      let dataForServer = document;
      const dataCopy = { ...data };
      // Данные для тепло-гидравлического расчета -> Рабочее давление, макс.
      var workingPressureElement = setSelectBoxByIndex(
        dataForServer,
        'working-pressure-max-value',
        dataCopy.workingPressureMax
      );
      dataForServer.getElementById('working-pressure-max-value').innerHTML = workingPressureElement;
      // Данные для тепло-гидравлического расчета -> Тепловая нагрузка
      var hydraulicPowerTypesElement = setSelectBoxByIndex(
        dataForServer,
        'hydraulic-power-types-value',
        dataCopy.heatLoadOption
      );
      dataForServer.getElementById('hydraulic-power-types-value').innerHTML = hydraulicPowerTypesElement;
      // Данные системы отопления -> Тепловая нагрузка и отопительный график -> Тепловая нагрузка
      var heatingPowerTypesValueElement = setSelectBoxByIndex(
        dataForServer,
        'heating-power-types-value',
        dataCopy.scheduleHeatLoadType
      );
      dataForServer.getElementById('heating-power-types-value').innerHTML = heatingPowerTypesValueElement;
      //console.log(dataForServer.documentElement.innerHTML);
      dataForServer = document.documentElement.innerHTML;
      raschetGVS(API_ENDPOINTS_LIST.raschet_gvs, dataForServer, 'POST', true, 'text/html').then((res) => {
        if (res && res.status === 200) {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            //toastAutohideDuration: 15000,
            toastMessage:
              'Расчет отправлен на рассмотрение инженеру-консультанту: ' +
              (consultant !== null ? consultant.consultantName : '') +
              ', тел.: ' +
              (consultant !== null ? consultant.consultantPhone : '') +
              ' Результат расчета будет отправлен вам по электронной почте.',
            toastStyle: 'success',
          }));
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
            toastStyle: 'error',
          }));
        }
      });
    }
    setTemperatureFieldsIncorrect(newTemperatureFieldsIncorrect);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className="root-container">
        <HeaderComponent screenNumber={0} topTabBarEnabled={true} topTabValue={2} />
        <Container>
          <Box
            style={getStyles().container}
            //display="flex"
            //flexDirection="row"
            justifyContent="center"
            //flexWrap="nowrap"
          >
            <HydraulicCalculation
              data={data}
              setValueHandler={setValueHandler}
              stylesContext={{ titleText, formBox, formContainer, labelPosition }}
              temperatureFieldsIncorrect={temperatureFieldsIncorrect}
            />
            <HeatingSystem
              data={data}
              setValueHandler={setValueHandler}
              sendButtonDisabled={sendButtonDisabled}
              stylesContext={{ titleText, formBox, formContainer, labelPosition }}
              sendButtonClicked={() => {
                sendButtonClickedHandler();
              }}
            />
          </Box>
        </Container>
        <FooterComponent />
      </Box>
    </ThemeProvider>
  );
};

const getStyles = () => {
  return {
    container: {
      marginTop: '198px',
      marginBottom: '37px',
      //border: '1px solid',
      //borderColor: 'red',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      display: 'flex',
    },
    titleText: {
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    formBox: {
      background: commonStyles.color.white,
      margin: '20px 5px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      paddingBottom: '34px',
    },
    formContainer: {
      padding: '0 20px',
    },
    labelPosition: {
      display: 'flex',
      alignItems: 'center',
    },
  };
};

export default HotWaterSupply;
