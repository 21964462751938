import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';

import commonStyles from '../../../../commonUtils/commonStyles';

import MainClass from '../../../../main/MainClass';

import TextInputComponent from '../../../Components/Elements/TextInput/TextInputComponent';
import DropDownComponent from '../../../Components/Elements/DropDown/DropDownComponent';
import CustomSelectComponent from '../../../Components/Elements/CustomSelect/CustomSelectComponent';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

import { powerTypes } from '../../../../commonUtils/utilsOVIC';
import { validateText2 } from '../../../../commonUtils/commonUtils';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const powerTypeValues = powerTypes.map((type) => ({ value: type, label: type }));

const initialStateObject = {
  raschetnaya_temperature_select: 0,
  raschetnoe_davlenie_select: 1,
  material_plastin: 'AISI316',
  material_prokladok: ['EPDM', 'VITON'],
  filtr_select: 0,
  filtrTypes: [],
  heatingSystem: '',
  heatingType: '',
  returnFlowHeatLoad: '',
  scheduleHeatLoad: '',
  scheduleHeatLoadType: powerTypeValues[0].value,
  heatingScheduleFrom: '',
  heatingScheduleTo: '',
  //preparedForServer: {},
};
const raschetnaya_temperatureValue = [150, 190];
const raschetnoe_davlenieValue = [10, 16];
const material_prokladokValues = ['EPDM', 'VITON'];
//const raschetnaya_temperatureTypes = ['150 °C', '190 °C'];
const raschetnaya_temperatureTypes = ['150 °C'];
const raschetnoe_davlenieTypes = ['10 атм', '16 атм'];

const RPTOPanel2Component = (props) => {
  //console.log('RPTOPanel2Component');
  //const [heatingSystem, setHeatingSystem] = useState('');
  //const [heatingType, setHeatingType] = useState('');
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [state, setState] = useState(initialStateObject);

  //Validation
  useEffect(() => {
    MainClass.mainDataObj.TechnologyDGVSPanel2StateObject = state;
    //console.log('heating system: ' + state.heatingSystem);
    //console.log('heating type: ' + state.heatingType);
    const dataForTest = { ...state };
    let skippedKeys = [
      'raschetnaya_temperature_select',
      'raschetnoe_davlenie_select',
      'material_plastin',
      'material_prokladok',
      'filtr_select',
      'filtrTypes',
      'scheduleHeatLoadType',
    ];
    if (dataForTest.heatingSystem === 'waterReturnFlow') {
      skippedKeys.push('scheduleHeatLoad');
      skippedKeys.push('heatingScheduleFrom');
      skippedKeys.push('heatingScheduleTo');
    }
    if (dataForTest.heatingSystem === 'heatLoadSchedule') {
      skippedKeys.push('returnFlowHeatLoad');
    }
    const objValid = checkAllDataFields(dataForTest, skippedKeys);

    setSendButtonDisabled(!objValid);
  }, [state]);

  const checkAllDataFields = (dataForTest, skippedKeys) => {
    //console.log('validate: ' + JSON.stringify(dataForTest) + ' : ' + JSON.stringify(skippedKeys));
    for (const key in dataForTest) {
      if (!skippedKeys.includes(key)) {
        if (!validateText2(dataForTest[key])) {
          //console.log('validate: ' + JSON.stringify(dataForTest[key]));
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    if (props.ptoList !== '') {
      setState((prevState) => ({
        ...prevState,
        filtrTypes: props.ptoList,
      }));
    }
  }, [props.ptoList]);

  const setHeatingSystemHandler = (event) => {
    //setHeatingSystem(event.target.value);
    setState((prevState) => ({
      ...prevState,
      heatingSystem: event.target.value,
    }));
    //setValueHandler(event, 'returnFlowVSschedule');
  };

  const setHeatingTypeHandler = (event) => {
    //setHeatingType(event.target.value);
    setState((prevState) => ({
      ...prevState,
      heatingType: event.target.value,
    }));
    //setValueHandler(event, 'monoblockVSseparateStages');
  };

  const onDataInputChanged = (event) => {
    //console.log('onDataInputChanged id:' + event.target.id + ' val: ' + event.target.value);

    props.dataChangeEventHandler();

    const id = event.target.id;
    const val = event.target.value;

    setState((prevState) => ({
      ...prevState,
      [id]: val,
    }));

    var infoObj;

    if (id === 'raschetnaya_temperature_select') {
      infoObj = { arr: raschetnaya_temperatureValue, idName: 'temperature' };
    }
    if (id === 'raschetnoe_davlenie_select') {
      infoObj = { arr: raschetnoe_davlenieValue, idName: 'pressure' };
    }
    if (id === 'material_prokladok') {
      infoObj = { arr: material_prokladokValues, idName: 'gasket_material' };
    }
    if (id === 'raschetnaya_temperature_select' || id === 'raschetnoe_davlenie_select' || id === 'material_prokladok') {
      props.setFieldsData((prev) => ({
        ...prev,
        additionalData: {
          ...prev?.additionalData,
          [infoObj.idName]: infoObj.arr[val],
        },
      }));
    } else {
      props.setFieldsData((prev) => ({ ...prev, additionalData: { ...prev?.additionalData, [id]: val } }));
    }
  };

  const setValueHandler = (event, field) => {
    let val = event.target.value;
    if (
      field === 'returnFlowHeatLoad' ||
      field === 'scheduleHeatLoad' ||
      field === 'heatingScheduleFrom' ||
      field === 'heatingScheduleTo'
    ) {
      if (val < 0) val = Math.abs(val);
    }

    setState((prev) => ({ ...prev, [field]: val }));
  };

  function sendButtonClickedHandler() {
    const resObj = {
      temperature: raschetnaya_temperatureValue[state.raschetnaya_temperature_select] || 150,
      pressure: raschetnoe_davlenieValue[state.raschetnoe_davlenie_select] || 16,
      pate_material: state?.material_plastin || 'AISI316',
      gasket_material: state.raschetnaya_temperature_select
        ? material_prokladokValues[state.raschetnaya_temperature_select]
        : 'EPDM',
    };
    //setState((prev) => ({ ...prev, preparedForServer: resObj }));
    props.setFieldsData((prev) => ({ ...prev, additionalData: resObj }));
    props.raschetButtonPressed(resObj, state);
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper style={getStyles().paper} elevation={3}>
        <Grid container style={getStyles().mainCell}>
          <Grid container style={getStyles().topItemCell}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular24,
                ...getStyles().headerText,
              }}
            >
              Дополнительные параметры
            </Typography>
          </Grid>

          <Grid container style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <Grid container alignItems="center" style={getStyles().middleItemCell}>
              <Grid item style={getStyles().middleItemCellText}>
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular18,
                  }}
                >
                  Расчетная температура
                </Typography>
              </Grid>
              <Grid item style={getStyles().middleItemCellElement}>
                <DropDownComponent
                  disabled={true}
                  id="raschetnaya_temperature_select"
                  values={raschetnaya_temperatureTypes}
                  selValue={state.raschetnaya_temperature_select}
                  onChange={(event) => onDataInputChanged(event)}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={getStyles().middleItemCell}>
              <Grid item style={getStyles().middleItemCellText}>
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular18,
                  }}
                >
                  Расчетное давление
                </Typography>
              </Grid>
              <Grid item style={getStyles().middleItemCellElement}>
                <DropDownComponent
                  id="raschetnoe_davlenie_select"
                  values={raschetnoe_davlenieTypes}
                  selValue={state.raschetnoe_davlenie_select}
                  onChange={(event) => onDataInputChanged(event)}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={getStyles().middleItemCell}>
              <Grid item style={getStyles().middleItemCellText}>
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular18,
                  }}
                >
                  Материал пластин
                </Typography>
              </Grid>
              <Grid item style={getStyles().middleItemCellElement}>
                <TextInputComponent
                  id="material_plastin"
                  value={state.material_plastin}
                  value_type="only_value"
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={getStyles().middleItemCell}>
              <Grid item style={getStyles().middleItemCellText}>
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular18,
                  }}
                >
                  Материал прокладок
                </Typography>
              </Grid>
              <Grid item style={getStyles().middleItemCellElement}>
                <TextInputComponent
                  id="material_prokladok"
                  value={state.material_prokladok[state.raschetnaya_temperature_select]}
                  value_type="only_value"
                  disabled={true}
                />
              </Grid>
            </Grid>
            {/*<Grid container alignItems="center" style={getStyles().middleItemCell}>
              <Grid item style={getStyles().middleItemCellText}>
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular18,
                  }}
                >
                  Фильтр
                </Typography>
              </Grid>
              <Grid item style={getStyles().middleItemCellElement}>
                <DropDownComponent
                  id="filtr_select"
                  values={state.filtrTypes}
                  selValue={state.filtr_select}
                  onChange={(event) => onDataInputChanged(event)}
                />
              </Grid>
            </Grid>*/}
          </Grid>

          <Grid container style={getStyles().topItemCell}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular24,
                ...getStyles().headerText,
              }}
            >
              Данные системы отопления
            </Typography>
          </Grid>

          <Grid item style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <RadioGroup value={state.heatingSystem} onChange={setHeatingSystemHandler}>
              <Grid container alignItems="center" style={getStyles().middleItemCell}>
                <FormControlLabel
                  value="waterReturnFlow"
                  control={<Radio color="primary" />}
                  label={'Расход обратной отопительной воды'}
                />
              </Grid>

              <Grid container alignItems="center" style={getStyles().middleItemCell}>
                <Grid item style={getStyles().middleItemCellText}>
                  <Typography
                    style={{
                      ...commonStyles.text.robotoRegular18,
                      color: state.heatingSystem !== 'waterReturnFlow' ? commonStyles.color.tabInactive : '',
                    }}
                  >
                    Расход СО
                  </Typography>
                </Grid>
                <Grid item style={getStyles().middleItemCellElement}>
                  <TextInputComponent
                    value_type={'т/ч'}
                    input_type={'number'}
                    disabled={state.heatingSystem !== 'waterReturnFlow'}
                    value={state.returnFlowHeatLoad}
                    onChange={(e) => setValueHandler(e, 'returnFlowHeatLoad')}
                  />
                </Grid>
              </Grid>

              <Grid container alignItems="center" style={getStyles().middleItemCell}>
                <FormControlLabel
                  value="heatLoadSchedule"
                  control={<Radio color="primary" />}
                  label={'Тепловая нагрузка и отопительный график'}
                />
              </Grid>

              <Grid container alignItems="center" style={getStyles().middleItemCell}>
                <Grid item style={getStyles().middleItemCellText}>
                  <Typography
                    style={{
                      ...commonStyles.text.robotoRegular18,
                      color: state.heatingSystem !== 'heatLoadSchedule' ? commonStyles.color.tabInactive : '',
                    }}
                  >
                    Тепловая нагрузка
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextInputComponent
                    value_type={''}
                    input_type={'number'}
                    disabled={state.heatingSystem !== 'heatLoadSchedule'}
                    value={state.scheduleHeatLoad}
                    onChange={(e) => setValueHandler(e, 'scheduleHeatLoad')}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomSelectComponent
                    id="heating-power-types-value"
                    values={powerTypeValues}
                    disabled={state.heatingSystem !== 'heatLoadSchedule'}
                    onChange={(e) => setValueHandler(e, 'scheduleHeatLoadType')}
                    selected={state.scheduleHeatLoadType}
                  />
                </Grid>
              </Grid>

              <Grid container alignItems="center" style={getStyles().middleItemCell}>
                <Grid item style={getStyles().middleItemCellText}>
                  <Typography
                    style={{
                      ...commonStyles.text.robotoRegular18,
                      color: state.heatingSystem !== 'heatLoadSchedule' ? commonStyles.color.tabInactive : '',
                    }}
                  >
                    Отопительный график
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextInputComponent
                    value_type={''}
                    input_type={'number'}
                    disabled={state.heatingSystem !== 'heatLoadSchedule'}
                    value={state.heatingScheduleFrom}
                    onChange={(e) => setValueHandler(e, 'heatingScheduleFrom')}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextInputComponent
                    value_type={''}
                    input_type={'number'}
                    disabled={state.heatingSystem !== 'heatLoadSchedule'}
                    value={state.heatingScheduleTo}
                    onChange={(e) => setValueHandler(e, 'heatingScheduleTo')}
                  />
                </Grid>
              </Grid>
            </RadioGroup>

            <RadioGroup value={state.heatingType} onChange={setHeatingTypeHandler}>
              <Grid container alignItems="center" style={getStyles().middleItemCell}>
                <FormControlLabel value="monoblock" control={<Radio color="primary" />} label={'Моноблок'} />
              </Grid>
              <Grid container alignItems="center" style={getStyles().middleItemCell}>
                <FormControlLabel
                  value="separateStages"
                  control={<Radio color="primary" />}
                  label={'Раздельные 1 и 2 ступень'}
                />
              </Grid>

              <Grid item xs={12} style={getStyles().bottomItemCell}>
                <Grid container style={getStyles().bottomItemElement}>
                  <ButtonComponent
                    style={getStyles().sendButton}
                    disabled={sendButtonDisabled}
                    onClick={sendButtonClickedHandler}
                  >
                    Расчет
                  </ButtonComponent>
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
};

const getStyles = () => {
  const styles = {
    paper: {
      background: '#FFFFFF',
      height: '953px',
      //width: '495px',
      width: '480px',
    },
    mainCell: {
      width: '100%',
      //border: '1px solid',
      //borderColor: 'red',
    },
    topItemCell: {
      width: '100%',
      height: '71px',
      //border: '1px solid',
      alignItems: 'center',
      justifyContent: 'center',
    },
    middleItemCell: {
      width: '100%',
      height: '60px',
      //border: '1px solid',
      //borderColor: 'orange',
    },
    middleItemCellComment: {
      width: '100%',
      //border: '1px solid',
      //borderColor: 'orange',
    },
    middleItemCellText: {
      width: '50%',
      //border: '1px solid',
      //borderColor: 'red',
    },
    middleItemCellTextComment: {
      width: '100%',
      //border: '1px solid',
      //borderColor: 'red',
    },
    middleItemCellElement: {
      width: '50%',
      //border: '1px solid',
      //borderColor: 'red',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    bottomItemCell: {
      width: '100%',
      height: '71px',
      marginTop: '30px',
      //border: '1px solid',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bottomItemElement: {
      width: '100%',
      //border: '1px solid',
      //borderColor: 'green',
      justifyContent: 'flex-end',
    },
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    sendButton: {
      width: '210px',
    },
  };
  return styles;
};

export default RPTOPanel2Component;
