import React, { useState, memo } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import commonStyles from '../../../../commonUtils/commonStyles';
import TableComponent from '../../../Components/Elements/Table/TableComponent';
import { CATALOG_AUTOCAD_TABLE, CATALOG_AUTODESC_TABLE } from '../../../../constants/tables';
import PropTypes from 'prop-types';

function CatalogTableComponent({ rows, selectedPlatform }) {
  const [selectedRow, setSelectedRow] = useState(null);

  return (
    <Paper style={getStyles().paper}>
      <Grid container style={getStyles().tableName}>
        <Grid item>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...getStyles().headerText,
            }}
          >
            {selectedPlatform === 'Autocad' ? 'Каталог 3D моделей для Autocad' : 'Каталог 3D моделей Autodesk Revit'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        {rows?.autocad && (
          <TableComponent
            headCells={selectedPlatform === 'Autocad' ? CATALOG_AUTOCAD_TABLE : CATALOG_AUTODESC_TABLE}
            rows={selectedPlatform === 'Autocad' ? rows.autocad : rows.revit}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            canSelect={Boolean(true)}
          />
        )}
      </Grid>
    </Paper>
  );
}

const getStyles = () => {
  const styles = {
    paper: {
      padding: '16px',
    },
    tableName: {
      height: '49px',
      justifyContent: 'center',
    },
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
  };
  return styles;
};

CatalogTableComponent.propTypes = {
  rows: PropTypes.object.isRequired,
  selectedPlatform: PropTypes.string,
};

CatalogTableComponent.defaultProps = {
  selectedPlatform: '0',
};

export default memo(CatalogTableComponent);
