import React from 'react';
import { Box, Typography, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import TextInputComponent from '../../Components/Elements/TextInput/TextInputComponent';

import commonStyles from '../../../commonUtils/commonStyles';
import CustomCheckbox from '../../Components/Elements/CustomCheckbox';

const title = 'Система вентиляции (СВ)';
const labels = {
  connectionTypeSV: 'Тип подключения',
  regulationTypeSV: 'Тип регулирования',
  heatingEnvironmentSV: 'Нагреваемая среда',
  ptoReservationSV: 'Резервирование ПТО',
  temperatureGraphSV: 'Температурный график СВ',
  hydraulicResistanceSV: 'Гидравлическое сопротивление СВ',
  waterVolumeSV: 'Объем воды в СВ',
  designPressureSV: 'Расчетное давление в СВ',
  staticPressureSV: 'Статический напор в СВ',
  circulationPumpSV: 'Циркуляционный насос для СВ',
  other: 'иное (уточнить)',
};

const VentilationSystem = ({ data, setValueHandler, setBoolHandler, stylesContext, formGridParameters }) => {
  const { titleText, formBox, box, formContainer, labelPosition, radioGroup, inputRightMargin } = stylesContext;
  const { md, xs } = formGridParameters;
  const { heatingEnvironmentForm } = getStyles();

  return (
    <Box style={{ ...formBox, ...box }}>
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {title}
      </Typography>

      <Grid container style={{ ...formContainer }} spacing={3}>
        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.connectionTypeSV}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup value={data.connectionTypeSV} onChange={(e) => setValueHandler(e, 'connectionTypeSV')}>
            <Box display="flex">
              <FormControlLabel
                value="independent straight"
                control={<Radio color="primary" />}
                label="непосредственная (прямые параметры)"
              />
              <FormControlLabel
                value="dependent"
                control={<Radio color="primary" />}
                label="зависимая через насосы смешения"
              />
              <FormControlLabel
                value="independent"
                control={<Radio color="primary" />}
                label="независимая через теплообменники"
              />
            </Box>
            <Box display="flex">
              <Box>
                <FormControlLabel value="other" control={<Radio color="primary" />} label={labels.other} />
              </Box>
              <Box flexGrow={1}>
                <TextInputComponent
                  value_type=""
                  disabled={data.connectionTypeSV !== 'other'}
                  value={data.connectionTypeSVOther}
                  onChange={(e) => setValueHandler(e, 'connectionTypeSVOther')}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.heatingEnvironmentSV}
          </Typography>
        </Grid>

        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup value={data.heatingEnvironmentSV} onChange={(e) => setValueHandler(e, 'heatingEnvironmentSV')}>
            <Box display="flex">
              <FormControlLabel value="water" control={<Radio color="primary" />} label="вода" />
              <FormControlLabel value="ethylene glycol" control={<Radio color="primary" />} label="этиленгликоль" />
              <Box style={{ ...heatingEnvironmentForm, marginRight: '16px' }}>
                <TextInputComponent
                  value_type="%"
                  value={data.heatingEnvironmentEthyleneGlycolSV}
                  onChange={(e) => setValueHandler(e, 'heatingEnvironmentEthyleneGlycolSV')}
                />
              </Box>

              <FormControlLabel value="propylene glycol" control={<Radio color="primary" />} label="пропиленгликоль" />
              <Box style={{ ...heatingEnvironmentForm }}>
                <TextInputComponent
                  value_type="%"
                  style={{ ...heatingEnvironmentForm }}
                  value={data.heatingEnvironmentPropyleneGlycolSV}
                  onChange={(e) => setValueHandler(e, 'heatingEnvironmentPropyleneGlycolSV')}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.ptoReservationSV}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup
            style={{ ...radioGroup }}
            value={data.ptoReservationSV}
            onChange={(e) => setValueHandler(e, 'ptoReservationSV')}
          >
            <Box display="flex">
              <FormControlLabel value="no" control={<Radio color="primary" />} label="нет" />
              <FormControlLabel
                value="2 by 100% power"
                control={<Radio color="primary" />}
                label="2 шт. по 100% мощности "
              />
              <FormControlLabel
                value="2 by 50% power"
                control={<Radio color="primary" />}
                label="2 шт. по 50% мощности "
              />
            </Box>
            <Box display="flex">
              <Box>
                <FormControlLabel value="other" control={<Radio color="primary" />} label={labels.other} />
              </Box>
              <Box flexGrow={1}>
                <TextInputComponent
                  value_type=""
                  disabled={data.ptoReservationSV !== 'other'}
                  value={data.ptoReservationOtherSV}
                  onChange={(e) => setValueHandler(e, 'ptoReservationOtherSV')}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.temperatureGraphSV}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <Box style={{ ...inputRightMargin }} width="50%">
            <TextInputComponent
              value_type="°С, Вход (Т21)"
              value={data.temperatureGraphSVInput}
              onChange={(e) => setValueHandler(e, 'temperatureGraphSVInput')}
            />
          </Box>
          <Box width="50%">
            <TextInputComponent
              value_type="°С, Выход (Т11)"
              value={data.temperatureGraphSVOutput}
              onChange={(e) => setValueHandler(e, 'temperatureGraphSVOutput')}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.hydraulicResistanceSV}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="кг\см²"
            value={data.hydraulicResistanceSV}
            onChange={(e) => setValueHandler(e, 'hydraulicResistanceSV')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.waterVolumeSV}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="л"
            value={data.waterVolumeSV}
            onChange={(e) => setValueHandler(e, 'waterVolumeSV')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.designPressureSV}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="кг\см²"
            value={data.designPressureSV}
            onChange={(e) => setValueHandler(e, 'designPressureSV')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.staticPressureSV}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="м"
            value={data.staticPressureSV}
            onChange={(e) => setValueHandler(e, 'staticPressureSV')}
          />
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.circulationPumpSV}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form}>
          <Box display="flex">
            <CustomCheckbox
              checked={data.circulationPumpCOReservationSV}
              label="резервирование"
              onChange={(e) => setBoolHandler(e, 'circulationPumpCOReservationSV')}
            />
            <CustomCheckbox
              checked={data.circulationPumpCODoubleSV}
              label="сдвоенный"
              onChange={(e) => setBoolHandler(e, 'circulationPumpCODoubleSV')}
            />
            <CustomCheckbox
              checked={data.circulationPumpCOReservationFrequencyRegulationSV}
              label="частотное регулирование"
              onChange={(e) => setBoolHandler(e, 'circulationPumpCOReservationFrequencyRegulationSV')}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const getStyles = () => {
  return {
    heatingEnvironmentForm: {
      maxWidth: '100px',
    },
  };
};

export default VentilationSystem;
