import React from 'react';
import { Box, Typography, Grid, TextField } from '@material-ui/core';
import TextInputComponent from '../../Components/Elements/TextInput/TextInputComponent';
import CustomSelectComponent from '../../Components/Elements/CustomSelect/CustomSelectComponent';

import commonStyles from '../../../commonUtils/commonStyles';
import { powerTypes } from '../../../commonUtils/utilsOVIC';

const hydraulicCalculationTitle = 'Данные для тепло-гидравлического расчета';
const labels = {
  name: 'Название обьекта',
  workingTemperatureMax: 'Рабочая температура, макс.',
  workingPressureMax: 'Рабочее давление, макс.',
  heatLoad: 'Тепловая нагрузка ',
  surfaceMargin: 'Запас по поверхности',
  mediumType: 'Тип среды',
  massFlow: 'Массовый расход',
  inletTemperature: 'Температура на входе',
  outletTemperature: 'Температура на выходе',
  allowableHeadLoss: 'Допускаемые потери напора в ПТО',
  heatingTitle: 'Греющая',
  heatedTitle: 'Нагреваемая',
};

const HydraulicCalculation = ({ data, setValueHandler, stylesContext, temperatureFieldsIncorrect }) => {
  const workingPressureMaxValues = [
    { value: '10 кгс/см2', label: '10 кгс/см2' },
    { value: '16 кгс/см2', label: '16 кгс/см2' },
  ];
  const powerTypeValues = powerTypes.map((type) => ({ value: type, label: type }));
  const { titleText, formBox, formContainer, labelPosition } = stylesContext;

  const { heatingSystem, heatingTitle, mediumType } = getStyles();

  return (
    <Box style={{ ...heatingSystem, ...formBox }}>
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {hydraulicCalculationTitle}
      </Typography>

      <Grid container style={{ ...formContainer }} spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            multiline={true}
            fullWidth={true}
            variant="outlined"
            rows={3}
            value={data.name}
            onChange={(e) => setValueHandler(e, 'name')}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.workingTemperatureMax}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={'℃'}
            disabled={true}
            value={data.workingTemperatureMax}
            onChange={(e) => setValueHandler(e, 'workingTemperatureMax')}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.workingPressureMax}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomSelectComponent
            id="working-pressure-max-value"
            values={workingPressureMaxValues}
            onChange={(e) => setValueHandler(e, 'workingPressureMax')}
            selected={data.workingPressureMax}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.heatLoad}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={''}
            input_type="number"
            value={data.heatLoad}
            onChange={(e) => setValueHandler(e, 'heatLoad')}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CustomSelectComponent
            id="hydraulic-power-types-value"
            values={powerTypeValues}
            onChange={(e) => setValueHandler(e, 'heatLoadOption')}
            selected={data.heatLoadOption}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.surfaceMargin}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={'%'}
            input_type="number"
            value={data.surfaceMargin}
            onChange={(e) => setValueHandler(e, 'surfaceMargin')}
          />
        </Grid>

        <Grid item xs={12} md={6} />
        <Grid item xs={12} md={3} style={{ ...heatingTitle }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
              color: commonStyles.color.hotWater,
            }}
          >
            {labels.heatingTitle}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} style={{ ...heatingTitle }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
              color: commonStyles.color.coldWater,
            }}
          >
            {labels.heatedTitle}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.mediumType}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} style={{ ...mediumType }}>
          <TextInputComponent
            variant={'outlined'}
            value_type={''}
            disabled={true}
            value={data.mediumTypeHeating}
            onChange={(e) => setValueHandler(e, 'mediumTypeHeating')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...mediumType }}>
          <TextInputComponent
            variant={'outlined'}
            value_type={''}
            disabled={true}
            value={data.mediumTypeHeated}
            onChange={(e) => setValueHandler(e, 'mediumTypeHeated')}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.massFlow}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={'т/ч'}
            input_type="number"
            value={data.massFlowHeated}
            onChange={(e) => setValueHandler(e, 'massFlowHeated')}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.inletTemperature}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={'℃'}
            input_type="number"
            value={data.inletTemperatureHeating}
            fieldIncorrect={temperatureFieldsIncorrect.inletTemperatureHeating}
            onChange={(e) => setValueHandler(e, 'inletTemperatureHeating')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={'℃'}
            input_type="number"
            value={data.inletTemperatureHeated}
            fieldIncorrect={temperatureFieldsIncorrect.inletTemperatureHeated}
            onChange={(e) => setValueHandler(e, 'inletTemperatureHeated')}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.outletTemperature}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={'℃'}
            input_type="number"
            value={data.outletTemperatureHeating}
            fieldIncorrect={temperatureFieldsIncorrect.outletTemperatureHeating}
            onChange={(e) => setValueHandler(e, 'outletTemperatureHeating')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={'℃'}
            input_type="number"
            value={data.outletTemperatureHeated}
            fieldIncorrect={temperatureFieldsIncorrect.outletTemperatureHeated}
            onChange={(e) => setValueHandler(e, 'outletTemperatureHeated')}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
            }}
          >
            {labels.allowableHeadLoss}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={'м.в.с'}
            input_type="number"
            value={data.allowableHeadLossHeating}
            onChange={(e) => setValueHandler(e, 'allowableHeadLossHeating')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type={'м.в.с'}
            input_type="number"
            value={data.allowableHeadLossHeated}
            onChange={(e) => setValueHandler(e, 'allowableHeadLossHeated')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const getStyles = () => {
  return {
    heatingSystem: {
      maxWidth: '58%',
    },
    heatingTitle: {
      paddingBottom: 0,
    },
    mediumType: {
      paddingTop: '5px',
    },
  };
};

export default HydraulicCalculation;
