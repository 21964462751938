import React from 'react';
import {FormControlLabel, Checkbox, FormGroup} from '@material-ui/core';
import PropTypes from "prop-types";

const CustomCheckbox = (props) => {
    /* props:
      checked,
      color,
      disabled,
      indeterminate,
      label,
      onChange,
      required,
      size,
      value
  */

    const {checked, color, disabled, indeterminate, label, onChange, required, size, value} = props;

    return (
        <FormGroup>
            <FormControlLabel
                label={label}
                control={
                    <Checkbox
                        checked={checked}
                        value={value}
                        color={color}
                        onChange={onChange}
                        disabled={disabled}
                        indeterminate={indeterminate}
                        required={required}
                        size={size}
                    />
                }
            />
        </FormGroup>
    )
}

CustomCheckbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    disableRipple: PropTypes.bool,
    indeterminate: PropTypes.bool
};

CustomCheckbox.defaultProps = {
    color: 'primary',
};


export default CustomCheckbox;
