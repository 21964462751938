import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import AuthTextInputComponent from '../../../Components/Elements/AuthTextInput';
import AuthPasswordInputComponent from '../../../Components/Elements/AuthPasswordInput';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ROUTES_LIST } from '../../../../constants';
import { GlobalContext } from '../../../..';
import useServerRequest from '../../../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../../../constants';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

function LogingForm({ handleLoginValuesChange, loginValues, setLoginValues, handlePasswordReset }) {
  const { globalLoading, setAuthTokens, setGlobalState, setGlobalLoading, setUserRole } = useContext(GlobalContext);
  const { getRequest: login } = useServerRequest(setGlobalLoading);
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();

    const userLoginValues = {
      email: loginValues.email,
      password: loginValues.password,
    };

    login(API_ENDPOINTS_LIST.login, JSON.stringify(userLoginValues), 'POST', false).then((res) => {
      //console.log('Loging: ' + JSON.stringify(res));
      if (res && !res.code) {
        const {
          result: { token },
        } = res;
        const tokenTimeStamp = Date.now();
        setAuthTokens({ token, tokenTimeStamp });
        const decoded = jwt_decode(token);
        if (decoded.role.admin) {
          setUserRole('admin');
          history.push(ROUTES_LIST.adminPanel);
        } else if (decoded.role.calculator) {
          setUserRole('calculator');
          history.push(ROUTES_LIST.rptoCalculation);
        } else {
          setUserRole('user');
          history.push(ROUTES_LIST.rptoCalculation);
        }
      } else {
        let errorText = '';

        if (res.status === 401) {
          errorText = 'Неверный логин или пароль';
        }

        if (res.status === 403) {
          errorText = 'Пользователь не подтвержден администратором';
        }

        setLoginValues((prevState) => ({
          ...prevState,
          loginError: true,
        }));

        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Ошибка авторизации! ${errorText}`,
          toastStyle: 'error',
        }));
      }
    });
  };

  return (
    <form noValidate autoComplete="off" onSubmit={(e) => handleLogin(e)}>
      <Grid container style={getStyles().root}>
        <AuthTextInputComponent
          id="email"
          style={{ marginBottom: '30px' }}
          type="email"
          label="Электронная почта"
          variant="outlined"
          value={loginValues.email}
          onChange={handleLoginValuesChange('email')}
        />
        <AuthPasswordInputComponent
          label="Пароль"
          value={loginValues.password}
          onChange={handleLoginValuesChange('password')}
          hasError={loginValues.loginError}
        />
        <Typography style={getStyles().link}>
          <Link href="#" onClick={handlePasswordReset}>
            Забыли пароль?
          </Link>
        </Typography>
        <Grid container justify="flex-end">
          <Grid item>
            <ButtonComponent
              variant="contained"
              color="primary"
              disabled={globalLoading}
              size="large"
              type="submit"
              onClick={() => false}
            >
              Войти
            </ButtonComponent>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

const getStyles = () => {
  const styles = {
    root: {
      padding: '47px 80px 50px',
      position: 'relative',
    },
    link: {
      textDecoration: 'underline',
      marginBottom: '30px',
      marginTop: '15px',
    },
    spinner: {
      position: 'absolute',
      left: '0',
      right: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
  return styles;
};

export default LogingForm;
