import React from 'react';
import { Card, Box } from '@material-ui/core';
import ButtonComponent from '../../Components/Elements/Button/ButtonComponent';

const ShowCount = ({ showCount, activeItem }) => {
  const { card } = getStyles();

  return (
    <Card style={{ ...card }}>
      <Box display="flex" padding="10px">
        <Box flexGrow={1} display="flex" justifyContent="flex-end" alignItems="center">
          <ButtonComponent width="220px" onClick={showCount} disabled={!activeItem} variant="contained" color="primary">
            Показать Счет
          </ButtonComponent>
        </Box>
      </Box>
    </Card>
  );
};

const getStyles = () => {
  return {
    card: {
      marginTop: '10px',
    },
  };
};

export default ShowCount;
