import React, { useState } from 'react';

import AuthTextInputComponent from '../../../Components/Elements/AuthTextInput';
import AuthSelectComponent from '../../../Components/Elements/AuthSelect';

import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';

import { isCyrillic } from '../../../../authUtils/isCyrillic';
import { validateEmail, validatePassword } from '../../../../authUtils/validators';
import AuthPasswordInputComponent from '../../../Components/Elements/AuthPasswordInput';
import { ERROR_TYPES_LIST, COUNTRY_NAMES_LIST } from '../../../../constants';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

const initialErrors = {
  lastName: '',
  firstName: '',
  middleName: '',
  position: '',
  companyName: '',
  inn: '',
  password: '',
  email: '',
};

function FirstStep({ setCurrentStep, firstStepValues, handleFirstStepChange }) {
  const [errorsText, setErrorsText] = useState(initialErrors);

  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  const handleSubmit = () => {
    const inputsToValidate = [
      'lastName',
      'firstName',
      'middleName',
      'position',
      'companyName',
      'inn',
      'password',
      'email',
    ];
    const newErrorTexts = {};

    inputsToValidate.forEach((field) => {
      if (field === 'password') {
        const isCorrect = validatePassword(firstStepValues[field]);
        newErrorTexts[field] = isCorrect ? '' : ERROR_TYPES_LIST.invalidPassword;
      }
      if (field === 'inn') {
        newErrorTexts[field] =
          firstStepValues[field].length === 10 || firstStepValues[field].length === 12
            ? ''
            : ERROR_TYPES_LIST.invalidInn;
      } else if (field === 'email') {
        const isCorrect = validateEmail(firstStepValues[field]);
        newErrorTexts[field] = isCorrect ? '' : ERROR_TYPES_LIST.invalidEmail;
      } else if (
        firstStepValues[field] === '' &&
        (field === 'lastName' ||
          field === 'firstName' ||
          field === 'middleName' ||
          field === 'position' ||
          field === 'companyName')
      ) {
        const errorText = 'Поле обязательно для заполнения!';
        //console.log('firstStepValues[field] ' + firstStepValues[field]);
        newErrorTexts[field] = errorText;
      } else if (
        firstStepValues[field] !== '' &&
        (field === 'lastName' ||
          field === 'firstName' ||
          field === 'middleName' ||
          field === 'position' ||
          field === 'companyName')
      ) {
        const errorText = isCyrillic(firstStepValues[field]) ? '' : ERROR_TYPES_LIST.nonCyrillic;
        //console.log('firstStepValues[field] ' + firstStepValues[field]);
        newErrorTexts[field] = errorText;
      } else {
        newErrorTexts[field] = '';
      }
    });

    setErrorsText(newErrorTexts);
    //console.log(newErrorTexts);

    const formHasNoErrors = Object.values(newErrorTexts).every((error) => error === '');
    //console.log('formHasErrors', formHasNoErrors);

    if (formHasNoErrors) {
      setCurrentStep(2);
      //console.log('form has no errors');
    }
  };

  return (
    <StyledGrid container style={getStyles().root}>
      <Grid container>
        <Grid item xs={6} style={getStyles().gridItemLeft}>
          <AuthTextInputComponent
            id="lastName"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Фамилия сотрудника"
            variant="outlined"
            value={firstStepValues.lastName}
            onChange={handleFirstStepChange('lastName')}
            error={!!errorsText.lastName}
            helperText={errorsText.lastName}
          />
          <AuthTextInputComponent
            id="firstName"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Имя сотрудника"
            variant="outlined"
            value={firstStepValues.firstName}
            onChange={handleFirstStepChange('firstName')}
            error={!!errorsText.firstName}
            helperText={errorsText.firstName}
          />
          <AuthTextInputComponent
            id="middleName"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Отчество сотрудника"
            variant="outlined"
            value={firstStepValues.middleName}
            onChange={handleFirstStepChange('middleName')}
            error={!!errorsText.middleName}
            helperText={errorsText.middleName}
          />
          <AuthTextInputComponent
            id="position"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Должность сотрудника"
            variant="outlined"
            value={firstStepValues.position}
            onChange={handleFirstStepChange('position')}
            error={!!errorsText.position}
            helperText={errorsText.position}
          />
          <AuthPasswordInputComponent
            id="password"
            style={{ marginBottom: '30px' }}
            label="Пароль"
            value={firstStepValues.password}
            onChange={handleFirstStepChange('password')}
            error={!!errorsText.password}
            helperText={errorsText.password}
            hasError={!!errorsText.password}
          />
        </Grid>
        <Grid item xs={6} style={getStyles().gridItemRight}>
          <AuthTextInputComponent
            id="companyName"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Наименование организации"
            variant="outlined"
            value={firstStepValues.companyName}
            onChange={handleFirstStepChange('companyName')}
            error={!!errorsText.companyName}
            helperText={errorsText.companyName}
          />
          <AuthTextInputComponent
            id="inn"
            type="number"
            label="ИНН"
            variant="outlined"
            style={{ marginBottom: '30px' }}
            value={firstStepValues.inn}
            onChange={handleFirstStepChange('inn')}
            error={!!errorsText.inn}
            helperText={errorsText.inn}
          />
          <AuthSelectComponent
            value={firstStepValues.country}
            onChange={handleFirstStepChange('country')}
            id="select-country"
            variant="outlined"
            label="Страна мобильного оператора"
            name="country"
            style={{ marginBottom: '30px' }}
            options={COUNTRY_NAMES_LIST}
          />
          <AuthTextInputComponent
            id="phone"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Номер телефона"
            variant="outlined"
            value={firstStepValues.phone}
            onChange={handleFirstStepChange('phone')}
          />
          <AuthTextInputComponent
            id="email"
            type="email"
            label="Электронная почта"
            variant="outlined"
            value={firstStepValues.email}
            onChange={handleFirstStepChange('email')}
            error={!!errorsText.email}
            helperText={errorsText.email}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid item>
          <ButtonComponent variant="contained" color="primary" disabled={nextButtonDisabled} onClick={handleSubmit}>
            Далее
          </ButtonComponent>
        </Grid>
      </Grid>
    </StyledGrid>
  );
}

const StyledGrid = styled(Grid)({
  padding: '0 40px 30px',
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    bottom: '-22px',
    fontSize: '0.85rem',
  },
  '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
    marginBottom: '30px',
  },
});

const getStyles = () => {
  const styles = {
    gridItemLeft: {
      paddingRight: '15px',
    },
    gridItemRight: {
      paddingLeft: '15px',
    },
    rulesContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: '1rem',
    },
    checkBox: {
      padding: '0 20px 0 0',
    },
    formControl: {
      width: '100%',
    },
  };
  return styles;
};

export default FirstStep;
