export function validateEmail(mail) {
  const emailRulet = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

  if (emailRulet.test(mail)) {
    return true;
  }
  return false;
}

export function validatePassword(password) {
  /* The string must contain: 
    - at least 1 lowercase alphabetical character
    - at least 1 numeric character
    - at least one special character
    - must be at least 8 characters
  */
  const passwordRule = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;

  if (passwordRule.test(password)) {
    return true;
  }
  return false;
}
