import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TableContainer, Paper, Container, Box, Typography, Radio } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import commonStyles from '../../commonUtils/commonStyles';
import { roundToDecimals } from '../../commonUtils/commonUtils';

import { ROUTES_LIST, API_ENDPOINTS_LIST, BASE_URL } from '../../constants';
import { GlobalContext } from '../..';
import useServerRequest from '../../hooks/serverRequest';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import TableComponent from '../Components/Elements/Table/TableComponent';
import Head from './Components/Head';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const initialStateObject = {
  data: [],
};

const CommercialOfferHistoryScreen = () => {
  const [state, setState] = useState(initialStateObject);
  const [activeItem, setActiveItem] = useState(null);

  const { setGlobalLoading, setGlobalState } = useContext(GlobalContext);

  const { getRequest: getActualOffersData } = useServerRequest(setGlobalLoading);
  const { getRequest: getPDFToken } = useServerRequest(setGlobalLoading);
  const { getRequest: setTemporaryCart } = useServerRequest(setGlobalLoading);

  const history = useHistory();

  useEffect(() => {
    getActualData();
    //eslint-disable-next-line
  }, [getActualOffersData]);

  const title = 'История коммерческих предложений';
  const getCellStyle = (selectedRow, rowId) => ({
    ...rowText,
    ...(selectedRow.some(({ id }) => id === rowId) && rowActive),
  });

  const getActualData = () => {
    getActualOffersData(API_ENDPOINTS_LIST.get_offers, '', 'GET').then((res) => {
      if (res && res.status === 200) {
        if (res.result !== []) {
          setState((prevState) => ({
            ...prevState,
            data: res.result,
          }));
        }
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Данные истории коммерческих предложений получены!`,
          toastStyle: 'success',
        }));
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Ошибка при получении данных истории коммерческих предложений`,
          toastStyle: 'error',
        }));
      }
    });
  };

  const downloadDocument = () => {
    //console.log(JSON.stringify(activeItem));
    getPDFToken(API_ENDPOINTS_LIST.pdf_get_offer_token, JSON.stringify({ offerId: activeItem.id }), 'POST').then(
      (res) => {
        if (res && res.status === 200) {
          const pdfToken = res.result.token;
          const downloadlink = pdfToken && `${BASE_URL}${API_ENDPOINTS_LIST.pdf_offer_download}/${pdfToken}/`;
          window.location.href = downloadlink;
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
            toastStyle: 'error',
          }));
        }
      }
    );
  };
  const openCart = () => {
    setTemporaryCart(API_ENDPOINTS_LIST.set_temp_cart + '/' + activeItem.id, '', 'POST').then((res) => {
      if (res && res.status === 200) {
        history.push({ pathname: ROUTES_LIST.commercialOffer, state: { type: 'temporary' } });
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };
  const setCount = () => {
    //выставить счет
    //console.log(activeItem);
    setGlobalState((prevState) => ({
      ...prevState,
      toastOpen: true,
      toastMessage: `Раздел находится в разработке!`,
      toastStyle: 'error',
    }));
  };
  const sectionArr = [
    {
      id: 'head_1',
      label: '',
      componentRender: ({ id: rowId, selectedRow }) => (
        <Radio
          style={{ marginLeft: '5px' }}
          color="primary"
          name="table-select"
          checked={selectedRow.some(({ id }) => id === rowId)}
        />
      ),
    },
    {
      id: 'head_2',
      label: 'Название объекта',
      componentRender: ({ nameOfObject, selectedRow, id: rowId }) => (
        <Typography
          style={{
            ...rowTextName,
            ...(selectedRow.some(({ id }) => id === rowId) && rowActive),
          }}
        >
          {nameOfObject}
        </Typography>
      ),
    },
    {
      id: 'head_3',
      label: 'Дата КП',
      componentRender: ({ date, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>
          {new Date(date).toLocaleDateString('ru-RU', { day: 'numeric', month: 'numeric', year: 'numeric' })}
        </Typography>
      ),
    },
    {
      id: 'head_4',
      label: 'Номер КП',
      componentRender: ({ number, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>{number}</Typography>
      ),
    },
    {
      id: 'head_5',
      label: 'Сумма КП, руб.',
      align: 'left',
      width: '150px',
      componentRender: ({ totalPrice, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>
          {totalPrice !== 'null' ? roundToDecimals(totalPrice, 2) : 0}
        </Typography>
      ),
    },
    {
      id: 'head_6',
      label: 'Скидка%',
      align: 'left',
      componentRender: ({ discount, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>{discount}</Typography>
      ),
    },
    {
      id: 'head_7',
      label: 'Годен до',
      componentRender: ({ expiryDate, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>
          {new Date(expiryDate).toLocaleDateString('ru-RU', { day: 'numeric', month: 'numeric', year: 'numeric' })}
        </Typography>
      ),
    },
  ];

  const { tableContainer, table, titleText, rowTextName, rowText, rowActive } = getStyles();
  return (
    <ThemeProvider theme={theme}>
      <Box className="root-container">
        <HeaderComponent screenNumber={9} />
        <Container className="aaa" style={tableContainer}>
          <Head selectedItem={activeItem} downloadDocument={downloadDocument} openCart={openCart} setCount={setCount} />
          <TableContainer style={table} component={Paper} className="table-container">
            <Typography
              style={{
                ...commonStyles.text.robotoRegular24,
                ...titleText,
              }}
            >
              {title}
            </Typography>

            <TableComponent
              headCells={sectionArr}
              rows={state.data}
              canSelect={true}
              setSelectedRow={setActiveItem}
              selectedRow={activeItem ? [activeItem] : []}
            />
          </TableContainer>
        </Container>
        <FooterComponent />
      </Box>
    </ThemeProvider>
  );
};

const getStyles = () => {
  return {
    table: {
      marginTop: '10px',
      marginBottom: '56px',
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
    },
    titleText: {
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    rowTextName: {
      textTransform: 'none',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      overflow: 'hidden',
      //minWidth: '450px',
      //maxWidth: '450px',
      width: '450px',
      whiteSpace: 'nowrap',
      ...commonStyles.text.robotoRegular18,
      fontWeight: 400,
      color: '#202020',
    },
    rowText: {
      ...commonStyles.text.robotoRegular18,
      textAlign: 'left',
      fontWeight: 400,
      color: '#202020',
    },
    rowActive: {
      color: '#0C63AD',
    },
    tableContainer: {
      overflowX: 'hidden',
    },
  };
};

export default CommercialOfferHistoryScreen;
