import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import TextInputComponent from '../../Components/Elements/TextInput/TextInputComponent';

import commonStyles from '../../../commonUtils/commonStyles';

const title = 'Тепловая нагрузка';
const labels = {
  heatingSystem: 'Система отопления (СО)',
  ventilationSystem: 'Система вентиляции (СВ)',
  dhwSystem: 'Система ГВС',
};

const heatLoadValueType = 'МВт';

const HeatLoad = ({ data, setValueHandler, stylesContext }) => {
  const { titleText, formBox, box, formContainer, labelPosition } = stylesContext;
  const { label } = getStyles();

  return (
    <Box style={{ ...formBox, ...box }}>
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {title}
      </Typography>

      <Grid container style={{ ...formContainer }} spacing={3}>
        <Grid item xs={12} md={4} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
              ...label,
            }}
          >
            {labels.heatingSystem}
          </Typography>
          <TextInputComponent
            value_type={heatLoadValueType}
            value={data.heatingSystem}
            onChange={(e) => setValueHandler(e, 'heatingSystem')}
          />
        </Grid>

        <Grid item xs={12} md={4} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
              ...label,
            }}
          >
            {labels.ventilationSystem}
          </Typography>
          <TextInputComponent
            value_type={heatLoadValueType}
            value={data.ventilationSystem}
            onChange={(e) => setValueHandler(e, 'ventilationSystem')}
          />
        </Grid>

        <Grid item xs={12} md={4} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
              ...label,
            }}
          >
            {labels.dhwSystem}
          </Typography>
          <TextInputComponent
            value_type={heatLoadValueType}
            value={data.dhwSystem}
            onChange={(e) => setValueHandler(e, 'dhwSystem')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const getStyles = () => {
  return {
    label: {
      minWidth: '200px',
    },
  };
};

export default HeatLoad;
