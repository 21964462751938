import React, { useState, useEffect } from 'react';
import { Paper, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';
import AuthTextInputComponent from '../../../Components/Elements/AuthTextInput';

import DropDownComponent from '../../../Components/Elements/DropDown/DropDownComponent';

export default function TopBarComponent({
  selectedRow,
  approveUser,
  rejectUser,
  consultantsList,
  setUserDiscount,
  setUserIK,
  clientIdList,
  setClientID,
}) {
  const isSelectedRow = Object.keys(selectedRow).length > 0;
  const canApprove = selectedRow?.accepted;

  const [state, setState] = useState({
    id: selectedRow?.id,
    discount: selectedRow?.discount,
    IKname: selectedRow?.IKname,
    selectedConsultantIndex: 0,
    consultantsList: [],
    selectedClientIdIndex: 0,
    clientIdList: [],
  });

  useEffect(() => {
    //find array id
    let consultantIndex = 0;
    if (selectedRow.consultantId && consultantsList) {
      consultantsList.forEach((obj, index) => {
        if (obj.id === selectedRow.consultantId) {
          consultantIndex = index;
        }
      });
    }
    //console.log('Index: ' + consultantIndex + ' Consultant ' + JSON.stringify(consultantsList[consultantIndex]));

    let clientIdIndex = 0;
    if (selectedRow.clientId && clientIdList) {
      clientIdList.forEach((val, index) => {
        if (val === selectedRow.clientId) {
          clientIdIndex = index;
        }
      });
    }
    setState({
      id: selectedRow?.id,
      discount: selectedRow?.discount,
      IKname: selectedRow?.IKname,
      selectedConsultantIndex: consultantIndex,
      selectedClientIdIndex: clientIdIndex,
    });
    const consultantsArr = consultantsList.map((obj) => {
      return obj.consultantName + ' (ID:' + obj.id + ')';
    });

    setState((prevState) => ({
      ...prevState,
      consultantsList: consultantsArr,
      clientIdList: clientIdList,
    }));
  }, [selectedRow, consultantsList, clientIdList]);

  const handleDiscountChange = (event) => {
    let val = event.target.value;
    if (val >= 0) {
      setState((prevState) => ({
        ...prevState,
        discount: val,
      }));
    }
  };

  const handleIKChange = (event) => {
    let val = event.target.value;

    setState((prevState) => ({
      ...prevState,
      selectedConsultantIndex: Number(val),
    }));
  };

  const handleClientIdChange = (event) => {
    let val = event.target.value;

    setState((prevState) => ({
      ...prevState,
      selectedClientIdIndex: Number(val),
    }));
  };

  return (
    <Paper style={getStyles().paper}>
      <Grid container spacing={3}>
        <Grid item xs={6} style={null}>
          <Grid item>
            <AuthTextInputComponent
              id="discount"
              style={{ width: '80px', marginRight: '20px', marginBottom: '20px' }}
              type="number"
              //helperText={'Скидка%'}
              variant="outlined"
              value={state.discount ? state.discount : 0}
              disabled={selectedRow?.discount === undefined ? true : false}
              onChange={(event) => handleDiscountChange(event)}
            />
            <ButtonComponent
              variant="contained"
              color="primary"
              onClick={() => setUserDiscount(state)}
              disabled={selectedRow?.discount === undefined ? true : false}
              size="large"
            >
              Установить скидку
            </ButtonComponent>
          </Grid>
          <Grid container style={getStyles().IKrow}>
            <Grid item style={{ width: '300px' }}>
              <DropDownComponent
                id="consultants_list_select"
                values={state.consultantsList ? state.consultantsList : []}
                selValue={state.selectedConsultantIndex ? state.selectedConsultantIndex : ''}
                disabled={selectedRow?.discount === undefined ? true : false}
                onChange={(event) => handleIKChange(event)}
              />
            </Grid>
            <Grid item style={{ marginLeft: '20px' }}>
              <ButtonComponent
                variant="contained"
                color="primary"
                onClick={() => {
                  setUserIK(state.id, consultantsList[state.selectedConsultantIndex].id);
                }}
                disabled={selectedRow?.discount === undefined ? true : false}
                size="large"
              >
                Установить ИК
              </ButtonComponent>
            </Grid>
          </Grid>
          <Grid container style={getStyles().clientIdRow}>
            <Grid item style={{ width: '300px' }}>
              <DropDownComponent
                id="clientId_list_select"
                values={state.clientIdList ? state.clientIdList : []}
                selValue={state.selectedClientIdIndex ? state.selectedClientIdIndex : ''}
                disabled={selectedRow?.discount === undefined ? true : false}
                onChange={(event) => handleClientIdChange(event)}
              />
            </Grid>
            <Grid item style={{ marginLeft: '20px' }}>
              <ButtonComponent
                variant="contained"
                color="primary"
                onClick={() => {
                  setClientID(state.id, state.clientIdList[state.selectedClientIdIndex]);
                }}
                disabled={selectedRow?.discount === undefined ? true : false}
                size="large"
              >
                Установить Client ID
              </ButtonComponent>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={getStyles().marginLeft}>
          <ButtonComponent
            variant="contained"
            color="primary"
            onClick={approveUser}
            disabled={!isSelectedRow || canApprove}
            size="large"
          >
            Подтвердить
          </ButtonComponent>
        </Grid>
        <Grid item>
          <ButtonComponent
            variant="contained"
            color="primary"
            onClick={rejectUser}
            disabled={!isSelectedRow || !canApprove}
            size="large"
          >
            Удалить подтверждение
          </ButtonComponent>
        </Grid>
      </Grid>
    </Paper>
  );
}

const getStyles = () => {
  const styles = {
    paper: {
      padding: '16px',
    },
    btn: {
      fontWeight: 600,
      fontSize: '16px',
      padding: '10px 14px',
      textTransform: 'none',
    },
    marginLeft: {
      marginLeft: 'auto',
    },
    IKrow: {
      flexDirection: 'row',
      //border: '1px solid',
      //borderColor: 'red',
    },
    clientIdRow: {
      flexDirection: 'row',
      marginTop: '10px',
    },
  };
  return styles;
};

TopBarComponent.propTypes = {
  selectedRow: PropTypes.object.isRequired,
  approveUser: PropTypes.func.isRequired,
  rejectUser: PropTypes.func.isRequired,
};
