import React from 'react';
import { Box, Typography } from '@material-ui/core';

import commonStyles from '../../../commonUtils/commonStyles';
import CustomCheckbox from '../../Components/Elements/CustomCheckbox';

const title = 'Автоматическое регулирование';

const labels = {
  automaticRegulationSO: 'Автоматическое регулирование СО',
  automaticRegulationSV: 'Автоматическое регулирование СВ',
  automaticRegulationGVS: 'Автоматическое регулирование ГВС',
  automaticRegulationUnit: 'Автоматическое регулирование узла подпитки',
};

const AutomaticRegulation = ({ data, setBoolHandler, stylesContext }) => {
  const { titleText, box } = stylesContext;
  const { regulation } = getStyles();

  return (
    <Box style={{ ...box, minHeight: '303px' }}>
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {title}
      </Typography>
      <Box style={{ ...regulation }}>
        <CustomCheckbox
          checked={data.automaticRegulationSO}
          label={labels.automaticRegulationSO}
          onChange={(e) => setBoolHandler(e, 'automaticRegulationSO')}
        />
        <CustomCheckbox
          checked={data.automaticRegulationSV}
          label={labels.automaticRegulationSV}
          onChange={(e) => setBoolHandler(e, 'automaticRegulationSV')}
        />
        <CustomCheckbox
          checked={data.automaticRegulationGVS}
          label={labels.automaticRegulationGVS}
          onChange={(e) => setBoolHandler(e, 'automaticRegulationGVS')}
        />
        <CustomCheckbox
          checked={data.automaticRegulationUnit}
          label={labels.automaticRegulationUnit}
          onChange={(e) => setBoolHandler(e, 'automaticRegulationUnit')}
        />
      </Box>
    </Box>
  );
};

const getStyles = () => {
  return {
    regulation: {
      padding: '0 20px 20px',
    },
  };
};

export default AutomaticRegulation;
