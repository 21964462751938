import React, { useState, useContext } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import AuthTextInputComponent from '../../../Components/Elements/AuthTextInput';
import commonStyles from '../../../../commonUtils/commonStyles';
import { API_ENDPOINTS_LIST, ERROR_TYPES_LIST } from '../../../../constants';
import { validateEmail } from '../../../../authUtils/validators';
import useServerRequest from '../../../../hooks/serverRequest';
import { GlobalContext } from '../../../..';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

export default function LoginChange() {
  const [errors, setErrors] = useState('');
  const [email, setEmail] = useState('');
  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);

  const { getRequest: setLogin } = useServerRequest(setGlobalLoading);

  const setValueHandler = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    const isCorrect = validateEmail(email);
    if (!isCorrect) {
      setErrors(ERROR_TYPES_LIST.invalidEmail);
    } else {
      setLogin(API_ENDPOINTS_LIST.login_change, JSON.stringify({ newEmail: email }), 'POST', true).then((res) => {
        if (res && !res.code) {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Логин успешно сменен!',
            toastStyle: 'success',
          }));
          setEmail('');
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
            toastStyle: 'error',
          }));
        }
      });
      setErrors('');
    }
  };

  return (
    <Paper style={getStyles().paper}>
      <Typography style={getStyles().header} variant="h6">
        Смена логина
      </Typography>
      <Grid container style={getStyles().lableContainer}>
        <Grid item xs={5}>
          Новый e-mail
        </Grid>
        <Grid item xs={7}>
          <AuthTextInputComponent
            id="email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setValueHandler(e)}
            color="primary"
            error={!!errors}
            helperText={errors}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={5} />
        <Grid item xs={7}>
          <Typography style={getStyles().textHelper} color="textSecondary">
            На указанный почтовый ящик придет <br />
            письмо, содержащее ссылку для <br />
            подтверждения адреса.
          </Typography>
          <ButtonComponent fullWidth variant="contained" color="primary" onClick={handleSubmit}>
            Подтвердить смену e-mail
          </ButtonComponent>
        </Grid>
      </Grid>
    </Paper>
  );
}

const getStyles = () => {
  const styles = {
    header: {
      color: commonStyles.color.blue,
      fontSize: '23px',
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: '33px',
    },
    paper: {
      padding: '15px 20px 30px',
      marginLeft: '10px',
    },
    lableContainer: {
      alignItems: 'center',
      marginBottom: '15px',
    },
    row: {
      alignItems: 'center',
    },
    textHelper: {
      fontSize: '.9rem',
      marginBottom: '96px',
    },
  };
  return styles;
};
