import React, { useState } from 'react';
import { Box, Typography, Grid, Radio, RadioGroup, FormControlLabel, TextField } from '@material-ui/core';

import TextInputComponent from '../../Components/Elements/TextInput/TextInputComponent';
import CustomSelectComponent from '../../Components/Elements/CustomSelect/CustomSelectComponent';
import Button from '../../Components/Elements/Button/ButtonComponent';

import commonStyles from '../../../commonUtils/commonStyles';
import { powerTypes } from '../../../commonUtils/utilsOVIC';

const labels = {
  heatingSystem: 'Данные системы отопления',
  waterReturnFlow: 'Расход обратной отопительной воды',
  heatLoadSchedule: 'Тепловая нагрузка и отопительный график',
  heatLoad1: 'Расход СО',
  heatLoad2: 'Тепловая нагрузка',
  heatingSchedule: 'Отопительный график',
  monoblock: 'Моноблок',
  separateStages: 'Раздельные 1 и 2 ступень',
  additionalComment: 'Дополнительный комментарий',
  send: 'Отправить',
};

const HydraulicCalculation = ({ data, setValueHandler, stylesContext, sendButtonDisabled, sendButtonClicked }) => {
  const { titleText, formBox, formContainer, labelPosition } = stylesContext;
  const powerTypeValues = powerTypes.map((type) => ({ value: type, label: type }));

  const { hydraulicCalculation, sendButton, send, monoblock, separateStages } = getStyles();

  const setHeatingSystemHandler = (event) => {
    setHeatingSystem(event.target.value);
    setValueHandler(event, 'returnFlowVSschedule');
  };

  const setHeatingTypeHandler = (event) => {
    setHeatingType(event.target.value);
    setValueHandler(event, 'monoblockVSseparateStages');
  };

  const [heatingSystem, setHeatingSystem] = useState('');
  const [heatingType, setHeatingType] = useState('');

  return (
    <Box style={{ ...hydraulicCalculation, ...formBox }} display="flex" flexDirection="column">
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {labels.heatingSystem}
      </Typography>
      <RadioGroup value={heatingSystem} onChange={setHeatingSystemHandler}>
        <Grid container style={{ ...formContainer }} spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              value="waterReturnFlow"
              control={<Radio color="primary" />}
              label={labels.waterReturnFlow}
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ ...labelPosition }}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular18,
                color: heatingSystem !== 'waterReturnFlow' ? commonStyles.color.tabInactive : '',
              }}
            >
              {labels.heatLoad1}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInputComponent
              value_type={'т/ч'}
              disabled={heatingSystem !== 'waterReturnFlow'}
              value={data.returnFlowHeatLoad}
              onChange={(e) => setValueHandler(e, 'returnFlowHeatLoad')}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              value="heatLoadSchedule"
              control={<Radio color="primary" />}
              label={labels.heatLoadSchedule}
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ ...labelPosition }}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular18,
                color: heatingSystem !== 'heatLoadSchedule' ? commonStyles.color.tabInactive : '',
              }}
            >
              {labels.heatLoad2}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInputComponent
              value_type={''}
              disabled={heatingSystem !== 'heatLoadSchedule'}
              value={data.scheduleHeatLoad}
              onChange={(e) => setValueHandler(e, 'scheduleHeatLoad')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomSelectComponent
              id="heating-power-types-value"
              values={powerTypeValues}
              disabled={heatingSystem !== 'heatLoadSchedule'}
              onChange={(e) => setValueHandler(e, 'scheduleHeatLoadType')}
              selected={data.scheduleHeatLoadType}
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ ...labelPosition }}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular18,
                color: heatingSystem !== 'heatLoadSchedule' ? commonStyles.color.tabInactive : '',
              }}
            >
              {labels.heatingSchedule}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInputComponent
              value_type={''}
              disabled={heatingSystem !== 'heatLoadSchedule'}
              value={data.heatingScheduleFrom}
              onChange={(e) => setValueHandler(e, 'heatingScheduleFrom')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInputComponent
              value_type={''}
              disabled={heatingSystem !== 'heatLoadSchedule'}
              value={data.heatingScheduleTo}
              onChange={(e) => setValueHandler(e, 'heatingScheduleTo')}
            />
          </Grid>
        </Grid>
      </RadioGroup>

      <RadioGroup value={heatingType} onChange={setHeatingTypeHandler}>
        <Grid container style={{ ...formContainer }} spacing={3}>
          <Grid item xs={12} style={{ ...monoblock }}>
            <FormControlLabel value="monoblock" control={<Radio color="primary" />} label={labels.monoblock} />
          </Grid>

          <Grid item xs={12} style={{ ...separateStages }}>
            <FormControlLabel
              value="separateStages"
              control={<Radio color="primary" />}
              label={labels.separateStages}
            />
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular18,
              }}
            >
              {labels.additionalComment}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline={true}
              fullWidth={true}
              variant="outlined"
              rows={5}
              value={data.additionalComment}
              onChange={(e) => setValueHandler(e, 'additionalComment')}
            />
          </Grid>

          <Grid item xs={12} style={{ ...send }}>
            <Button style={{ ...sendButton }} disabled={sendButtonDisabled} onClick={sendButtonClicked}>
              {labels.send}
            </Button>
          </Grid>
        </Grid>
      </RadioGroup>
    </Box>
  );
};

const getStyles = () => {
  return {
    hydraulicCalculation: {
      maxWidth: '40%',
    },
    send: {
      height: '100%',
      maxHeight: '139px',
      position: 'relative',
      marginTop: '24.5%',
    },
    sendButton: {
      width: '210px',
      position: 'absolute',
      right: '12px',
      bottom: '0',
    },
    monoblock: {
      paddingTop: '24px',
      paddingBottom: '0',
    },
    separateStages: {
      paddingTop: '0',
    },
  };
};

export default HydraulicCalculation;
