import React from 'react';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import CalculateTechnologyMainComponent from './CalculateTechnologyMainComponent';

function CalculateTechnologyScreen() {
  return (
    <React.Fragment>
      <HeaderComponent screenNumber={0} topTabBarEnabled={true} topTabValue={1} />

      <CalculateTechnologyMainComponent />

      <FooterComponent />
    </React.Fragment>
  );
}

export default CalculateTechnologyScreen;
