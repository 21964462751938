import React from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import commonStyles from '../../../../commonUtils/commonStyles';
import TableComponent from '../../../Components/Elements/Table/TableComponent';
import { ARCHIVE_TABLE } from '../../../../constants/tables';
import PropTypes from 'prop-types';

export default function ArchiveTableComponent({ rows, selectedRow, setSelectedRow }) {
  //console.log(rows);
  return (
    <Paper style={getStyles().paper}>
      <Grid container style={getStyles().tableName}>
        <Grid item>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...getStyles().headerText,
            }}
          >
            Архив расчетов
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <TableComponent
          headCells={ARCHIVE_TABLE}
          rows={rows}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          canSelect={Boolean(true)}
          multipleSelect
        />
      </Grid>
    </Paper>
  );
}

const getStyles = () => {
  const styles = {
    paper: {
      padding: '16px',
    },
    tableName: {
      height: '49px',
      justifyContent: 'center',
    },
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
  };
  return styles;
};

ArchiveTableComponent.propTypes = {
  rows: PropTypes.array,
};

ArchiveTableComponent.defaultProps = {
  rows: [],
};
