import React, { useState, useContext } from 'react';

import RegisterIndividualsForm from './RegisterIndividualsForm';
import RegisterLegalEntetiesForm from './RegisterLegalEntetiesForm';
import RegisterSuccess from './RegisterSuccess';
import AuthRadioGroupComponent from '../../../Components/Elements/AuthRadioGroup';

import { USER_TYPES_LIST, REGISTER_OPTIONS_LIST, COUNTRY_CODES_LIST, COUNTRY_NAMES_LIST } from '../../../../constants';
import useServerRequest from '../../../../hooks/serverRequest';
import { GlobalContext } from '../../../..';
import { API_ENDPOINTS_LIST } from '../../../../constants';
import { Box } from '@material-ui/core';

const initialRegisterType = USER_TYPES_LIST.legalEntities;

const initialValuesIndividual = {
  lastName: '',
  firstName: '',
  middleName: '',
  password: '',
  city: '',
  country: COUNTRY_NAMES_LIST.Russia,
  phone: COUNTRY_CODES_LIST.Russia,
  email: '',
};

const legalEntetiesFirstStep = {
  lastName: '',
  firstName: '',
  middleName: '',
  position: '',
  companyName: '',
  inn: '',
  country: COUNTRY_NAMES_LIST.Russia,
  phone: COUNTRY_CODES_LIST.Russia,
  password: '',
  email: '',
};

const legalEntetiesSecondStep = {
  country: '',
  city: '',
  street: '',
  building: '',
  apartment: '',
  postalNumber: '',

  actualCountry: '',
  actualCity: '',
  actualStreet: '',
  actualBuilding: '',
  actualAppartment: '',
  actualPostalNumber: '',
};

function RegisterContainer({ setRegisterSubmitted, setTab }) {
  const [submittedFormType, setSubmittedFormType] = useState('');
  const [registerType, setRegisterType] = useState(initialRegisterType);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [individualValues, setIndividualValues] = useState(initialValuesIndividual);
  const [firstStepValues, setFirstStepValues] = useState(legalEntetiesFirstStep);

  const [secondStepValues, setSecondStepValues] = useState(legalEntetiesSecondStep);

  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: signUpUser } = useServerRequest(setGlobalLoading);

  const handleSubmit = async (values) => {
    signUpUser(API_ENDPOINTS_LIST.sign_up, JSON.stringify(values), 'POST', false).then((res) => {
      if (res && res.status === 200) {
        setRegisterSubmitted(true);
        setIsFormSubmitted(true);
        setSubmittedFormType(values.type);
      } else {
        const errorText =
          res.status === 409
            ? `Пользователь с этим эмейлом уже зарегистрирован`
            : `Ошибка запроса к серверу! ${res.code} ${res.message}`;
        setGlobalState((prev) => ({
          ...prev,
          toastOpen: true,
          toastMessage: errorText,
          toastStyle: 'error',
        }));
      }
    });
  };

  const handleIndividualChange = (prop) => (event) => {
    if (prop === 'country') {
      setIndividualValues({
        ...individualValues,
        [prop]: event.target.value,
        phone: COUNTRY_CODES_LIST[event.target.value],
      });
    } else {
      setIndividualValues({ ...individualValues, [prop]: event.target.value });
    }
  };

  const handleFirstStepChange = (prop) => (event) => {
    if (prop === 'country') {
      setFirstStepValues({
        ...firstStepValues,
        [prop]: event.target.value,
        phoneNumber: COUNTRY_CODES_LIST[event.target.value],
      });
    } else {
      setFirstStepValues({ ...firstStepValues, [prop]: event.target.value });
    }
  };

  const handleSecondStepChange = (prop) => (event) => {
    setSecondStepValues({ ...secondStepValues, [prop]: event.target.value });
  };

  if (isFormSubmitted) {
    let emailToNotify;
    if (submittedFormType === USER_TYPES_LIST.individual) {
      emailToNotify = individualValues.email;
    }

    if (submittedFormType === USER_TYPES_LIST.legalEntities) {
      emailToNotify = firstStepValues.email;
    }

    return <RegisterSuccess email={emailToNotify} setTab={setTab} />;
  }

  return (
    <>
      {/* <AuthRadioGroupComponent
        onChange={(e) => setRegisterType(e.target.value)}
        name="registerType"
        value={registerType}
        options={REGISTER_OPTIONS_LIST}
        style={getStyles().radioBtnsRoot}
      /> */}
      <Box style={{ marginTop: '40px' }}>
        {registerType === USER_TYPES_LIST.individual && (
          <RegisterIndividualsForm
            individualValues={individualValues}
            handleIndividualChange={handleIndividualChange}
            handleSubmit={handleSubmit}
          />
        )}

        {registerType === USER_TYPES_LIST.legalEntities && (
          <RegisterLegalEntetiesForm
            firstStepValues={firstStepValues}
            secondStepValues={secondStepValues}
            handleFirstStepChange={handleFirstStepChange}
            handleSecondStepChange={handleSecondStepChange}
            handleSubmit={handleSubmit}
            setSecondStepValues={setSecondStepValues}
          />
        )}
      </Box>
    </>
  );
}

const getStyles = () => {
  const styles = {
    radioBtnsRoot: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      padding: '30px 0',
    },
  };
  return styles;
};

export default RegisterContainer;
