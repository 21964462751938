import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import commonStyles from '../../../../commonUtils/commonStyles';
import CustomSelectComponent from '../../../Components/Elements/CustomSelect/CustomSelectComponent';
import { buildOptions } from '../../../../commonUtils/commonUtils';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

const platf = ['Autocad', 'Autodesk Revit'];

export default function TopBarComponent({ state, setState, filterArray, clearFilter, modelsFilters }) {
  const filtersList = [
    {
      title: 'Платформа',
      type: 'platf',
      values: buildOptions(platf),
    },
    {
      title: 'Количество пластин, диапазон',
      type: 'plates',
      values: buildOptions(modelsFilters.plates),
    },
    {
      title: 'Типоразмер',
      type: 'typo',
      values: buildOptions(modelsFilters.typo),
    },
    {
      title: 'Давление, МПа',
      type: 'pres',
      values: buildOptions(modelsFilters.pres),
    },
  ];

  return (
    <Paper style={getStyles().paper}>
      <Grid container spacing={4}>
        {filtersList.map(({ title, type, values }, index) => (
          <Grid
            item
            xs={6}
            key={index}
            style={
              state?.platf === 'Autodesk Revit' && (type === 'plates' || type === 'pres') ? getStyles().disabled : null
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular18,
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CustomSelectComponent
                  id={type}
                  values={values}
                  selected={state[type]}
                  onChange={(e) => setState(e, type)}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} justify="flex-end" style={getStyles().marginTop}>
        <Grid item>
          <ButtonComponent variant="outlined" color="primary" onClick={clearFilter}>
            Сброс
          </ButtonComponent>
        </Grid>
        <Grid item>
          <ButtonComponent variant="contained" color="primary" onClick={filterArray}>
            Применить
          </ButtonComponent>
        </Grid>
      </Grid>
    </Paper>
  );
}

const getStyles = () => {
  const styles = {
    paper: {
      padding: '16px',
    },
    marginTop: {
      marginTop: '20px',
    },
    disabled: {
      pointerEvents: 'none',
    },
  };
  return styles;
};

TopBarComponent.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  filterArray: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  modelsFilters: PropTypes.object.isRequired,
};
