import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import KeepAlive from 'react-activation';

import MainClass from '../../main/MainClass';
import * as Utils from '../../commonUtils/commonUtils';
import * as UtilsOVIC from '../../commonUtils/utilsOVIC';

import RPTOPanel1Component from './Components/RPTOPanel1/RPTOPanel1Component';
import RPTOPanel2Component from './Components/RPTOPanel2/RPTOPanel2Component';
import RPTOPanel3Component from './Components/RPTOPanel3/RPTOPanel3Component';

import { API_ENDPOINTS_LIST, BASE_URL } from '../../constants';
import { ROUTES_LIST } from '../../constants';
import { GlobalContext } from '../..';
import useServerRequest from '../../hooks/serverRequest';
import AdditionInfoModalComponent from '../Components/AdditionInfoModalComponent/AdditionInfoModalComponent';
import PDFViewComponent from '../Components/PDFViewComponent/PDFViewComponent';
import { useMultipleModal } from '../../hooks/modalHooks';

const initialStateObject = {
  panel3Enabled: true,
  ptoList: '',
};

const initialPanel1TextFieldErrorState = {
  power: false,
  zapas: false,
  hot_mass_rashod: false,
  hot_temperature_vhod: false,
  hot_temperature_vyhod: false,
  hot_poteri_davlenia: false,
  cold_mass_rashod: false,
  cold_temperature_vhod: false,
  cold_temperature_vyhod: false,
  cold_poteri_davlenia: false,
};

let RPTOPanel1StateObject;

function CalculateRPTOMainComponent() {
  const [state, setState] = useState(initialStateObject);
  const [saveToCartState, setSaveToCartState] = useState(false);
  const [panel1TextFieldErrorState, setPanel1TextFieldErrorState] = useState(initialPanel1TextFieldErrorState);
  const [fieldsData, setFieldsData] = useState({});
  const [pdfToken, setPdfToken] = useState('');
  const [pdfTokenRptoSaved, setPdfTokenRptoSaved] = useState('');
  const [modalOpen, closeModal, setModal] = useMultipleModal(false);
  const [uniqueObjects, setUniqueObjects] = useState([]);
  const [rptoSaved, setRptoSaved] = useState(false);

  const history = useHistory();
  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: getPto } = useServerRequest(setGlobalLoading);
  const { getRequest: raschetOvic } = useServerRequest(setGlobalLoading);
  const { getRequest: setStore } = useServerRequest(setGlobalLoading);
  const { getRequest: getPDFToken } = useServerRequest(setGlobalLoading);
  const { getRequest: getUniqueObjectNames } = useServerRequest(setGlobalLoading);
  const { getRequest: addItemsToCart } = useServerRequest(setGlobalLoading);

  const downloadlink = pdfToken && `${BASE_URL}${API_ENDPOINTS_LIST.pdf_download}/${pdfToken}/`;
  const printLink = pdfToken && `${BASE_URL}${API_ENDPOINTS_LIST.pdf_view}/${pdfToken}/`;

  const downloadlinkRptoSaved =
    pdfTokenRptoSaved && `${BASE_URL}${API_ENDPOINTS_LIST.pdfs_store_download}/${pdfTokenRptoSaved}/`;
  const printLinkRptoSaved =
    pdfTokenRptoSaved && `${BASE_URL}${API_ENDPOINTS_LIST.pdfs_store_view}/${pdfTokenRptoSaved}/`;

  useEffect(() => {
    MainClass.setRaschetResultData(null);
    if (state.ptoList === '') {
      getPto(API_ENDPOINTS_LIST.get_pto, '', 'GET').then((res) => {
        if (res && res.status === 200) {
          const ptoList = [];
          ptoList.push('Искать среди всех');
          res.result.forEach((obj) => {
            ptoList.push(obj.pto);
          });
          setState((prevState) => ({
            ...prevState,
            ptoList: ptoList,
          }));
          setTimeout(() => {
            setGlobalState((prevState) => ({
              ...prevState,
              toastOpen: true,
              toastMessage: 'Список ПТО получен!',
              toastStyle: 'success',
            }));
          }, 1000);
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
            toastStyle: 'error',
          }));
        }
      });
    }
  }, []);

  useEffect(() => {
    MainClass.setCurrentBalanceType('Normal');
  }, []);

  const getUniqueObject = () => {
    getUniqueObjectNames(API_ENDPOINTS_LIST.get_store_object_names_unique, '', 'GET').then((res) => {
      if (res && res.status === 200) {
        //console.log('getUniqueObject ');
        setUniqueObjects(res.result);
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const raschetButtonPressed = (resObj, selectedPto) => {
    RPTOPanel1StateObject = JSON.parse(JSON.stringify(MainClass.mainDataObj.RPTOPanel1StateObject));

    const raschetResult = UtilsOVIC.raschetTeplovoyBalance(RPTOPanel1StateObject);
    if (raschetResult.success) {
      setPanel1TextFieldErrorState({
        power: false,
        zapas: false,
        hot_mass_rashod: false,
        hot_temperature_vhod: false,
        hot_temperature_vyhod: false,
        hot_poteri_davlenia: false,
        cold_mass_rashod: false,
        cold_temperature_vhod: false,
        cold_temperature_vyhod: false,
        cold_poteri_davlenia: false,
      });
      setState((prevState) => ({
        ...prevState,
        panel3Enabled: true,
      }));
      updateUI();

      const resultObj = {
        ...raschetResult.data,
        additionalData: fieldsData?.temperature ? fieldsData : resObj,
      };
      //console.log('selectedPto: ' + selectedPto);
      const ptoFilter = Number(selectedPto) === 0 ? '' : state.ptoList[selectedPto];
      //console.log('?filter=' + ptoFilter);
      raschetOvic(API_ENDPOINTS_LIST.raschet_ovic + '?filter=' + ptoFilter, JSON.stringify(resultObj), 'POST').then(
        (res) => {
          if (res && res.status === 200) {
            //console.log('res: ' + JSON.stringify(res.result));
            MainClass.setRaschetResultData(res.result);
            setGlobalState((prevState) => ({
              ...prevState,
              toastOpen: true,
              toastMessage: 'Расчет успешно выполнен!',
              toastStyle: 'success',
            }));
          } else {
            setGlobalState((prevState) => ({
              ...prevState,
              toastOpen: true,
              toastMessage: 'Ошибка! ' + res.code + ' ' + res.message,
              toastStyle: 'error',
            }));
          }
        }
      );
    } else {
      setGlobalState((prevState) => ({
        ...prevState,
        toastOpen: true,
        toastMessage: 'Ошибка при расчете! ' + raschetResult.error.message,
        toastStyle: 'error',
      }));
      let errorsArr = raschetResult.error.code;
      if (errorsArr.length !== undefined) {
        //reset previous errors
        let newPanel1TextFieldErrorState = {
          power: false,
          zapas: false,
          hot_mass_rashod: false,
          hot_temperature_vhod: false,
          hot_temperature_vyhod: false,
          hot_poteri_davlenia: false,
          cold_mass_rashod: false,
          cold_temperature_vhod: false,
          cold_temperature_vyhod: false,
          cold_poteri_davlenia: false,
        };

        errorsArr.forEach((errorCode) => {
          if (errorCode === 2) {
            newPanel1TextFieldErrorState.hot_temperature_vhod = true;
            newPanel1TextFieldErrorState.hot_temperature_vyhod = true;
          }
          if (errorCode === 3) {
            newPanel1TextFieldErrorState.cold_temperature_vhod = true;
            newPanel1TextFieldErrorState.cold_temperature_vyhod = true;
          }
          if (errorCode === 4) {
            newPanel1TextFieldErrorState.hot_temperature_vhod = true;
            newPanel1TextFieldErrorState.cold_temperature_vyhod = true;
          }
          if (errorCode === 5) {
            newPanel1TextFieldErrorState.hot_temperature_vyhod = true;
            newPanel1TextFieldErrorState.cold_temperature_vhod = true;
          }
          if (errorCode === 6) {
            newPanel1TextFieldErrorState.hot_temperature_vyhod = true;
            newPanel1TextFieldErrorState.hot_temperature_vhod = true;
          }
          if (errorCode === 7) {
            newPanel1TextFieldErrorState.cold_temperature_vyhod = true;
            newPanel1TextFieldErrorState.cold_temperature_vhod = true;
          }
        });

        setPanel1TextFieldErrorState(newPanel1TextFieldErrorState);
      } else {
        //single error - hightlight only error code 0 (find empty field)
        if (errorsArr === 0) {
          //clone object
          let RPTOPanel1StateObject_clone = JSON.parse(JSON.stringify(RPTOPanel1StateObject));
          delete RPTOPanel1StateObject_clone.power_type_select;
          delete RPTOPanel1StateObject_clone.hot_teplonositel_select;
          delete RPTOPanel1StateObject_clone.hot_koncentration_select;
          delete RPTOPanel1StateObject_clone.cold_teplonositel_select;
          delete RPTOPanel1StateObject_clone.cold_koncentration_select;
          delete RPTOPanel1StateObject_clone.mass_rashod_type_select;
          delete RPTOPanel1StateObject_clone.poteri_davlenia_type_select;
          delete RPTOPanel1StateObject_clone.automatic_data_calc_switch;
          const emptyFields = Utils.getPropsByValueInObject(RPTOPanel1StateObject_clone, 0);
          let newPanel1TextFieldErrorState = {
            power: false,
            zapas: false,
            hot_mass_rashod: false,
            hot_temperature_vhod: false,
            hot_temperature_vyhod: false,
            hot_poteri_davlenia: false,
            cold_mass_rashod: false,
            cold_temperature_vhod: false,
            cold_temperature_vyhod: false,
            cold_poteri_davlenia: false,
          };
          emptyFields.forEach((key) => {
            newPanel1TextFieldErrorState[key] = true;
          });
          setPanel1TextFieldErrorState(newPanel1TextFieldErrorState);
        }
        if (errorsArr === 1) {
          let newPanel1TextFieldErrorState = {
            power: false,
            zapas: false,
            hot_mass_rashod: false,
            hot_temperature_vhod: false,
            hot_temperature_vyhod: false,
            hot_poteri_davlenia: false,
            cold_mass_rashod: false,
            cold_temperature_vhod: false,
            cold_temperature_vyhod: false,
            cold_poteri_davlenia: false,
          };
          setPanel1TextFieldErrorState(newPanel1TextFieldErrorState);
        }
      }
    }
  };

  const poverochniyRaschetButtonPressedHandler = (data) => {
    MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject = JSON.parse(
      JSON.stringify(MainClass.mainDataObj.RPTOPanel1StateObject)
    );
    MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject.automatic_data_calc_switch = false;
    MainClass.mainDataObj.selectedReshenieData = data;
    history.push(ROUTES_LIST.rptoCalculationPoverochniy);
  };

  const updateUI = () => {
    //console.log('updateUI');
    const balanceUpdatedItems = UtilsOVIC.getBalanceItemsData();

    RPTOPanel1StateObject.power = balanceUpdatedItems.power;
    RPTOPanel1StateObject.hot_mass_rashod = balanceUpdatedItems.hot_mass_rashod;
    RPTOPanel1StateObject.cold_mass_rashod = balanceUpdatedItems.cold_mass_rashod;
    RPTOPanel1StateObject.hot_temperature_vyhod = balanceUpdatedItems.hot_temperature_vyhod;
    RPTOPanel1StateObject.hot_temperature_vhod = balanceUpdatedItems.hot_temperature_vhod;
    RPTOPanel1StateObject.cold_temperature_vyhod = balanceUpdatedItems.cold_temperature_vyhod;
    RPTOPanel1StateObject.cold_temperature_vhod = balanceUpdatedItems.cold_temperature_vhod;
    MainClass.mainDataObj.RPTOPanel1StateObject = RPTOPanel1StateObject;
  };

  const disablePanel3 = () => {
    if (state.panel3Enabled) {
      setState((prevState) => ({
        ...prevState,
        panel3Enabled: false,
      }));
    }
  };

  const saveCalculationHandler = (data) => {
    const raschetResult = UtilsOVIC.raschetTeplovoyBalance(MainClass.mainDataObj.RPTOPanel1StateObject);
    const resultObj = {
      ...data,
      id: fieldsData?.tableData?.id,
      heatBalanceData: {
        N: {
          value: MainClass.mainDataObj.RPTOPanel1StateObject.power,
          dimension: UtilsOVIC.powerTypes[MainClass.mainDataObj.RPTOPanel1StateObject.power_type_select],
        },
        ...raschetResult.data,
      },
      additionalData: fieldsData?.additionalData?.pressure
        ? fieldsData.additionalData
        : {
            pressure: 16,
            temperature: 150,
            pate_material: 'AISI316',
            gasket_material: 'EPDM',
          },
      tableData: fieldsData.tableData,
    };
    delete resultObj.heatBalanceData.user_restrictions;

    resultObj.isCheck = true;

    setStore(API_ENDPOINTS_LIST.set_store, JSON.stringify(resultObj), 'POST').then((res) => {
      rptoSaved &&
        getPDFToken(API_ENDPOINTS_LIST.pdfs_get_store_token, JSON.stringify([res.result.storeId]), 'POST').then(
          (res) => {
            setPdfTokenRptoSaved(res.result.token);
          }
        );
      if (res && res.status === 200) {
        if (rptoSaved) {
          setModal('pdfViewModal');
        }
        if (saveToCartState) {
          //console.log('setStore: ' + JSON.stringify(res));
          let selectedIds = [res.result.storeId];
          addItemsToCart(API_ENDPOINTS_LIST.set_cart_data, JSON.stringify(selectedIds), 'POST').then((res) => {
            if (res && res.status === 200) {
              //console.log('addItemsToCart: ' + JSON.stringify(res));
              MainClass.shoppingCartGlobalData.cartItemsNum = Number(res.result.itemsCount);
              setGlobalState((prevState) => ({
                ...prevState,
                toastOpen: true,
                toastMessage: 'Расчет успешно сохранен и добавлен в корзину!',
                toastStyle: 'success',
              }));
            } else {
              setGlobalState((prevState) => ({
                ...prevState,
                toastOpen: true,
                toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
                toastStyle: 'error',
              }));
            }
          });
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Расчет успешно сохранен!',
            toastStyle: 'success',
          }));
        }
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const showCalculationHandler = (data) => {
    setRptoSaved(false);
    const raschetResult = UtilsOVIC.raschetTeplovoyBalance(MainClass.mainDataObj.RPTOPanel1StateObject);

    const resObj = {
      ...MainClass.mainDataObj.rtoFieldsData,
      heatBalanceData: raschetResult.data,
    };

    getPDFToken(API_ENDPOINTS_LIST.pdf_get_token, JSON.stringify(resObj), 'POST').then((res) => {
      if (res && res.status === 200) {
        setPdfToken(res.result.token);
        getUniqueObject();
        setModal('pdfViewModal');
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const setFieldsDataHandler = (data) => {
    setFieldsData(data);
    MainClass.mainDataObj.rtoFieldsData = { ...MainClass.mainDataObj.rtoFieldsData, ...data() };
  };

  const rptoCheckHandler = (data) => {
    if (rptoSaved) {
      saveCalculationHandler(data);
    } else {
      setRptoSaved(true);
      setModal('additionInfoModal');
    }
  };

  return (
    <Grid container style={getStyles().container}>
      {modalOpen === 'pdfViewModal' && (
        <PDFViewComponent
          open={modalOpen === 'pdfViewModal'}
          handleClose={closeModal}
          links={{
            downloadlink: rptoSaved ? downloadlinkRptoSaved : downloadlink,
            printLink: rptoSaved ? printLinkRptoSaved : printLink,
          }}
          rptoSaved={rptoSaved}
          rptoCheck={rptoCheckHandler}
        />
      )}
      {modalOpen === 'additionInfoModal' && (
        <AdditionInfoModalComponent
          open={Boolean(modalOpen === 'additionInfoModal')}
          handleClose={closeModal}
          handleSubmit={(e) => (rptoSaved ? rptoCheckHandler(e) : saveCalculationHandler(e))}
          uniqueObjects={uniqueObjects}
          saveButtonName={saveToCartState ? 'Сохранить и добавить в корзину' : 'Сохранить'}
        />
      )}
      <Grid container style={getStyles().topGrid}>
        <Grid container style={getStyles().panelTopRowGrid}>
          <Grid style={{ paddingBottom: '20px', paddingRight: '10px' }}>
            <KeepAlive>
              <RPTOPanel1Component
                dataChangeEventHandler={() => {
                  disablePanel3();
                }}
                initialStateObject={MainClass.mainDataObj.RPTOPanel1StateObject}
                textFieldErrorObj={panel1TextFieldErrorState}
              />
            </KeepAlive>
          </Grid>
          <Grid style={{ paddingBottom: '20px', paddingLeft: '10px' }}>
            <KeepAlive>
              <RPTOPanel2Component
                ptoList={state.ptoList}
                dataChangeEventHandler={() => {
                  disablePanel3();
                }}
                raschetButtonPressed={(obj, selectedPto) => raschetButtonPressed(obj, selectedPto)}
                setFieldsData={setFieldsDataHandler}
              />
            </KeepAlive>
          </Grid>
        </Grid>
        <Grid container style={getStyles().panelBottomRowGrid}>
          <RPTOPanel3Component
            enabled={state.panel3Enabled}
            raschetResultData={MainClass.getRaschetResultData()}
            poverochniyRaschetButtonPressed={poverochniyRaschetButtonPressedHandler}
            saveCalculation={() => {
              setSaveToCartState(false);
              getUniqueObject();
              setModal('additionInfoModal');
            }}
            showCalculation={showCalculationHandler}
            setFieldsData={setFieldsDataHandler}
            addToCart={() => {
              setSaveToCartState(true);
              getUniqueObject();
              setModal('additionInfoModal');
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    container: {
      //border: '1px solid',
      //borderColor: 'red',
      justifyContent: 'center',
    },
    topGrid: {
      //width: '1346px',
      //border: '1px solid',
      marginTop: '218px',
      marginBottom: '56px',
    },
    panelTopRowGrid: {
      //border: '1px solid',
      //borderColor: 'red',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      display: 'flex',
    },
    panelBottomRowGrid: {
      //border: '1px solid',
      //borderColor: 'blue',
      //marginTop: '20px',
      justifyContent: 'center',
      alignItems: 'flex-start',
      display: 'flex',
    },
  };
  return styles;
};

export default CalculateRPTOMainComponent;
