import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Switch } from '@material-ui/core';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import commonStyles from '../../../../commonUtils/commonStyles';

import * as Utils from '../../../../commonUtils/commonUtils';
import * as UtilsTechnology from '../../../../commonUtils/utilsTechnology';
import MainClass from '../../../../main/MainClass';

import TextInputComponent from '../../../Components/Elements/TextInput/TextInputComponent';
import DropDownComponent from '../../../Components/Elements/DropDown/DropDownComponent';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.blue,
    },
  },
});
/*
const initialStateObject = {
  power: 0.647,
  power_type_select: 0,
  zapas: 10,
  hot_teplonositel_select: 1,
  hot_koncentration_select: 0,
  hot_mass_rashod: 21.599,
  hot_temperature_vhod: 70,
  hot_temperature_vyhod: 40,
  hot_poteri_davlenia: 3,
  cold_teplonositel_select: 1,
  cold_koncentration_select: 0,
  cold_mass_rashod: 10.803,
  mass_rashod_type_select: 0,
  cold_temperature_vhod: 5,
  cold_temperature_vyhod: 65,
  cold_poteri_davlenia: 3,
  poteri_davlenia_type_select: 0,
  automatic_data_calc_switch: true,
};
*/

//Drop down values
const powerTypes = UtilsTechnology.powerTypes;
const hot_teplonositelTypes = UtilsTechnology.hot_teplonositelTypes;

const cold_teplonositelTypes = UtilsTechnology.cold_teplonositelTypes;

const mass_rashodType = UtilsTechnology.mass_rashodType;
const poteri_davleniaType = UtilsTechnology.poteri_davleniaType;

let balanceCalculatorInitialized = false;

function RPTOPanel1Component(props) {
  /*
  props:
  initialStateObject
  textFieldErrorObj
  */
  //init balance calculator object
  if (!balanceCalculatorInitialized) {
    MainClass.setCurrentBalanceType('Normal');
    UtilsTechnology.initBalanceCalculator(props.initialStateObject);
    balanceCalculatorInitialized = true;
    //console.log('Normal RPTOPanel1Component balanceCalculatorInitialized');
  }
  const [state, setState] = useState(props.initialStateObject);

  useEffect(() => {
    //also update state representation in singleton
    MainClass.mainDataObj.TechnologyPanel1StateObject = state;
  }, [state]);

  useEffect(() => {
    //console.log('RPTOPanel1Component ' + JSON.stringify(props.initialStateObject));
    setState(props.initialStateObject);
  }, [props.initialStateObject]);

  const onDataInputChanged = (event) => {
    //console.log('onDataInputChanged id:' + event.target.id + ' val: ' + event.target.value);

    props.dataChangeEventHandler();

    const id = event.target.id;
    const val = event.target.value;

    if (
      //state.automatic_data_calc_switch &&
      id === 'power' ||
      id === 'power_type_select' ||
      id === 'hot_mass_rashod' ||
      id === 'cold_mass_rashod' ||
      id === 'mass_rashod_type_select' ||
      id === 'hot_temperature_vhod' ||
      id === 'hot_temperature_vyhod' ||
      id === 'cold_temperature_vhod' ||
      id === 'cold_temperature_vyhod' ||
      id === 'hot_teplonositel_select' ||
      id === 'cold_teplonositel_select' ||
      id === 'hot_koncentration_select' ||
      id === 'cold_koncentration_select' ||
      id === 'automatic_data_calc_switch'
    ) {
      let updatedBalanceItems;
      let prevState;
      switch (id) {
        case 'power':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, powerTypes[state.power_type_select]);
          break;
        case 'power_type_select':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, state.power, powerTypes[val]);
          break;
        case 'hot_mass_rashod':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(
            id,
            val,
            mass_rashodType[state.mass_rashod_type_select]
          );
          break;
        case 'cold_mass_rashod':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(
            id,
            val,
            mass_rashodType[state.mass_rashod_type_select]
          );
          break;
        case 'mass_rashod_type_select':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, 0, mass_rashodType[val], {
            hot_mass_rashod: state.hot_mass_rashod,
            cold_mass_rashod: state.cold_mass_rashod,
          });
          break;
        case 'hot_temperature_vhod':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, 'град. Цельсия');
          break;
        case 'hot_temperature_vyhod':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, 'град. Цельсия');
          break;
        case 'cold_temperature_vhod':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, 'град. Цельсия');
          break;
        case 'cold_temperature_vyhod':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, 'град. Цельсия');
          break;
        case 'hot_teplonositel_select':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, null);
          break;
        case 'cold_teplonositel_select':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, null);
          break;
        case 'hot_koncentration_select':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, null, {
            hot_teplonositelValue: state.hot_teplonositel_select,
          });
          break;
        case 'cold_koncentration_select':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, null, {
            cold_teplonositelValue: state.cold_teplonositel_select,
          });
          break;
        case 'automatic_data_calc_switch':
          updatedBalanceItems = UtilsTechnology.updateTeplovoyBalance(id, val, null);
          break;
        default:
          break;
      }

      //console.log('updatedBalanceItems: ' + JSON.stringify(updatedBalanceItems));
      if (state.automatic_data_calc_switch || (id === 'automatic_data_calc_switch' && val === true)) {
        prevState = state;
        prevState.power = Utils.truncateDecimals(updatedBalanceItems.power, 3);
        prevState.hot_mass_rashod = Utils.truncateDecimals(updatedBalanceItems.hot_mass_rashod, 3);
        prevState.cold_mass_rashod = Utils.truncateDecimals(updatedBalanceItems.cold_mass_rashod, 3);
        prevState.hot_temperature_vhod = Utils.truncateDecimals(updatedBalanceItems.hot_temperature_vhod, 3);
        prevState.hot_temperature_vyhod = Utils.truncateDecimals(updatedBalanceItems.hot_temperature_vyhod, 3);
        prevState.cold_temperature_vhod = Utils.truncateDecimals(updatedBalanceItems.cold_temperature_vhod, 3);
        prevState.cold_temperature_vyhod = Utils.truncateDecimals(updatedBalanceItems.cold_temperature_vyhod, 3);
        setState(prevState);
      }
    }

    setState((prevState) => ({
      ...prevState,
      [id]: val,
    }));
  };

  const KoncentrationRowTextComponent = (props) => {
    return Number(state.hot_teplonositel_select) !== 0 || Number(state.cold_teplonositel_select) !== 0 ? (
      <Typography
        style={{
          ...commonStyles.text.robotoRegular18,
          ...getStyles().rowTextDescription,
        }}
      >
        Концентрация
      </Typography>
    ) : null;
  };

  const KoncentrationRowHotSideComponent = () => {
    let obj = null;
    if (Number(state.hot_teplonositel_select) !== 0)
      obj = (
        <DropDownComponent
          id="hot_koncentration_select"
          values={UtilsTechnology.koncentrationPercentage[state.hot_teplonositel_select]}
          selValue={state.hot_koncentration_select}
          onChange={(event) => onDataInputChanged(event)}
          tabIndex={-1}
        />
      );
    return obj;
  };

  const KoncentrationRowColdSideComponent = () => {
    let obj = null;
    if (Number(state.cold_teplonositel_select) !== 0)
      obj = (
        <DropDownComponent
          id="cold_koncentration_select"
          values={UtilsTechnology.koncentrationPercentage[state.cold_teplonositel_select]}
          selValue={state.cold_koncentration_select}
          onChange={(event) => onDataInputChanged(event)}
          tabIndex={-1}
        />
      );
    return obj;
  };

  return (
    <Paper style={getStyles().paper} elevation={3}>
      <Grid container style={getStyles().mainCell}>
        <Grid container style={getStyles().topItemCell}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...getStyles().headerText,
            }}
          >
            Данные для тепло-гидравлического расчета
          </Typography>
        </Grid>
        <Grid container style={getStyles().middleItemCell}>
          <Grid container style={getStyles().middleItemColumn1}>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Мощность
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Запас (%)
              </Typography>
            </Grid>
            <Grid container style={{ ...getStyles().itemGridCell, ...{ height: '7%' } }}>
              {/* Empty cell */}
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Теплоноситель
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <KoncentrationRowTextComponent />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Массовый расход
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Температура на входе
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Температура на выходе
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Потери давления
              </Typography>
            </Grid>
          </Grid>

          {/* ---- Column 2 ---- */}

          <Grid container style={getStyles().middleItemColumn2}>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                style={{ borderColor: 'red' }}
                id="power"
                value={state.power}
                value_type="only_value"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.power}
                tabIndex={-1}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="zapas"
                value={state.zapas}
                value_type="%"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.zapas}
                tabIndex={-1}
              />
            </Grid>
            <Grid container style={{ ...getStyles().itemGridCell, ...{ height: '7%' } }}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().sectionType1,
                }}
              >
                Греющая
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="hot_teplonositel_select"
                values={hot_teplonositelTypes}
                selValue={state.hot_teplonositel_select}
                onChange={(event) => onDataInputChanged(event)}
                tabIndex={-1}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <KoncentrationRowHotSideComponent />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="hot_mass_rashod"
                value={state.hot_mass_rashod}
                value_type="only_value"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.hot_mass_rashod}
                tabIndex={-1}
                disabled={true}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="hot_temperature_vhod"
                value={state.hot_temperature_vhod}
                value_type="°C"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.hot_temperature_vhod}
                tabIndex={1}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="hot_temperature_vyhod"
                value={state.hot_temperature_vyhod}
                value_type="°C"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.hot_temperature_vyhod}
                tabIndex={2}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="hot_poteri_davlenia"
                value={state.hot_poteri_davlenia}
                value_type="only_value"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.hot_poteri_davlenia}
                tabIndex={-1}
              />
            </Grid>
          </Grid>

          {/* ---- Column 3 ---- */}

          <Grid container style={getStyles().middleItemColumn3}>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="power_type_select"
                values={powerTypes}
                selValue={state.power_type_select}
                onChange={(event) => onDataInputChanged(event)}
                tabIndex={-1}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              {/* Empty cell */}
            </Grid>
            <Grid container style={{ ...getStyles().itemGridCell, ...{ height: '7%' } }}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().sectionType2,
                }}
              >
                Нагреваемая
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="cold_teplonositel_select"
                values={cold_teplonositelTypes}
                selValue={state.cold_teplonositel_select}
                onChange={(event) => onDataInputChanged(event)}
                tabIndex={-1}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <KoncentrationRowColdSideComponent />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="cold_mass_rashod"
                value={state.cold_mass_rashod}
                value_type="only_value"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.cold_mass_rashod}
                tabIndex={-1}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="cold_temperature_vhod"
                value={state.cold_temperature_vhod}
                value_type="°C"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.cold_temperature_vhod}
                tabIndex={3}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="cold_temperature_vyhod"
                value={state.cold_temperature_vyhod}
                value_type="°C"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.cold_temperature_vyhod}
                tabIndex={4}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="cold_poteri_davlenia"
                value={state.cold_poteri_davlenia}
                value_type="only_value"
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.cold_poteri_davlenia}
                tabIndex={-1}
              />
            </Grid>
          </Grid>

          {/* ---- Column 4 ---- */}

          <Grid container style={getStyles().middleItemColumn4}>
            <Grid container style={getStyles().itemGridCell}>
              {/* Empty cell */}
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              {/* Empty cell */}
            </Grid>
            <Grid container style={{ ...getStyles().itemGridCell, ...{ height: '7%' } }}>
              {/* Empty cell */}
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              {/* Empty cell */}
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              {/* Empty cell */}
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="mass_rashod_type_select"
                values={mass_rashodType}
                selValue={state.mass_rashod_type_select}
                onChange={(event) => onDataInputChanged(event)}
                tabIndex={-1}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              {/* Empty cell */}
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              {/* Empty cell */}
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="poteri_davlenia_type_select"
                values={poteri_davleniaType}
                selValue={state.poteri_davlenia_type_select}
                onChange={(event) => onDataInputChanged(event)}
                tabIndex={-1}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={getStyles().bottomItemCell}>
          <MuiThemeProvider theme={theme}>
            <Switch
              id="automatic_data_calc_switch"
              color="secondary"
              inputProps={{ style: { ...getStyles().switch } }}
              checked={state.automatic_data_calc_switch}
              onChange={(event) => {
                const evt = {
                  target: {
                    id: event.target.id,
                    value: event.target.checked,
                  },
                };
                onDataInputChanged(evt);
              }}
            />
          </MuiThemeProvider>
          <Typography style={commonStyles.text.robotoRegular18}>
            Автоматически сводить данные по тепловому балансу
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

const getStyles = () => {
  const styles = {
    paper: {
      background: '#FFFFFF',
      height: '653px',
      //width: '863px',
      width: '846px',
    },
    mainCell: {
      width: '100%',
      height: '100%',
      //border: '1px solid',
      //borderColor: 'red',
      flexDirection: 'column',
    },
    topItemCell: {
      width: '100%',
      height: '71px',
      //border: '1px solid',
      alignItems: 'center',
      justifyContent: 'center',
    },
    middleItemCell: {
      width: '100%',
      height: '78%',
      //border: '1px solid',
      //borderColor: 'cyan',
      flexDirection: 'row',
    },
    middleItemColumn1: {
      width: '242px',
      flexDirection: 'column',
      //border: '1px solid',
      //borderColor: 'red',
    },
    middleItemColumn2: {
      width: '230px',
      flexDirection: 'column',
      //border: '1px solid',
      //borderColor: 'green',
    },
    middleItemColumn3: {
      width: '230px',
      flexDirection: 'column',
      //border: '1px solid',
      //borderColor: 'orange',
    },
    middleItemColumn4: {
      width: '140px',
      flexDirection: 'column',
      //border: '1px solid',
      //borderColor: 'blue',
    },
    itemGridCell: {
      width: 'auto',
      height: '11.6%',
      //border: '1px solid',
      alignItems: 'center',
      paddingRight: '10px',
    },
    bottomItemCell: {
      width: '100%',
      height: '71px',
      //border: '1px solid',
      alignItems: 'center',
      justifyContent: 'center',
    },
    switch: {
      color: 'green',
    },
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    rowTextDescription: {
      marginLeft: '20px',
    },
    sectionType1: {
      fontWeight: 'bold',
      color: commonStyles.color.hotWater,
    },
    sectionType2: {
      fontWeight: 'bold',
      color: commonStyles.color.coldWater,
    },
  };
  return styles;
};

export default RPTOPanel1Component;
