import React from 'react';

import { TextField, InputAdornment } from '@material-ui/core';

const TextInputComponent = (props) => {
  /* props:
        id
        value
        value_type: "only_value", "type"
        input_type: "number"
        fieldIncorrect
        active
        onChange
    */
  let inputPropsObj;
  if (props.tabIndex !== undefined) {
    inputPropsObj = { style: getStyles().textInput, tabIndex: props.tabIndex };
  } else {
    inputPropsObj = { style: getStyles().textInput };
  }
  let component = null;
  if (props.value_type !== 'only_value') {
    component = (
      <TextField
        InputProps={{
          endAdornment: <InputAdornment position="end">{props.value_type}</InputAdornment>,
          inputProps: inputPropsObj, //{ style: getStyles().textInput },
        }}
        fullWidth
        id={props.id}
        variant="outlined"
        value={props.value}
        type={props.input_type}
        onChange={props.onChange}
        error={props.fieldIncorrect}
        disabled={props.disabled !== undefined ? props.disabled : false}
        hidden={true}
        //helperText={'This is error text'}
      />
    );
  } else {
    component = (
      <TextField
        inputProps={inputPropsObj}
        id={props.id}
        variant="outlined"
        value={props.value}
        type={props.input_type}
        onChange={props.onChange}
        error={props.fieldIncorrect}
        disabled={props.disabled !== undefined ? props.disabled : false}
      />
    );
  }
  if (props.visible !== undefined && props.visible === false) component = null;
  return component;
};

const getStyles = () => {
  const styles = {
    textInput: {
      height: '8px',
      paddingRight: '5px',
    },
  };
  return styles;
};

export default TextInputComponent;
