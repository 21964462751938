import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import commonStyles from '../../../../commonUtils/commonStyles';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

function ResetSuccessPage({ emailForReset, setLoginValues }) {
  const handleSubmit = () => {
    // reset to default values
    setLoginValues((prevState) => ({
      ...prevState,
      email: '',
      password: '',
      // step: 1,
      // emailForReset: ''
      loginError: false,
    }));
  };

  return (
    <Grid container>
      <Grid item style={getStyles().helperText}>
        Новый пароль автоматически сгенерирован и выслан на{' '}
        <Box style={getStyles().email} display="inline">
          {emailForReset}
        </Box>
      </Grid>
      <Grid item style={getStyles().btnBox}>
        <ButtonComponent variant="contained" color="primary" onClick={handleSubmit}>
          Завершить
        </ButtonComponent>
      </Grid>
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    helperText: {
      color: commonStyles.color.helperText,
      padding: '47px 80px 188px',
    },
    btnBox: {
      padding: '0 40px 30px',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    email: {
      fontWeight: 'bold',
      color: commonStyles.color.black,
    },
  };
  return styles;
};

export default ResetSuccessPage;
