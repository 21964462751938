import React, { useState, useEffect } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, MenuItem, Typography } from '@material-ui/core';

import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';
import KPDropDown from '../../../Components/Elements/KPDropdown/KPDropdownComponent';
import commonStyles from '../../../../commonUtils/commonStyles';

import MainClass from '../../../../main/MainClass';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.blue,
    },
  },
});

const TableActions = ({ onSubmit, totalPrice }) => {
  const [state, setState] = useState({
    cityId: null,
    cityName: null,
    cities: [],
    terminals: [],
    terminalsAll: [],
    currentAddress: null,
    isOpenedCitiesDropdown: false,
    isOpenedTerminalsDropdown: false,
    selectedRadio: 'first',
    sendOfferButtonDisabled: false,
  });

  useEffect(() => {
    const terminals_arr = MainClass.shoppingCartGlobalData.terminals;
    if (terminals_arr !== '') {
      setState((prevState) => ({
        ...prevState,
        cities: terminals_arr.map((item, i) => {
          let obj = {
            id: i + 1,
            name: item.city,
          };
          return obj;
        }),
        terminalsAll: terminals_arr.map((item) => {
          const terms_arr = item.adress.map((addr, i) => {
            let obj = {
              id: i + 1,
              address: addr,
            };
            return obj;
          });
          return terms_arr;
        }),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MainClass.shoppingCartGlobalData.terminals]);

  useEffect(() => {
    if (state.cities.length && state.cityId) {
      setState((prevState) => ({
        ...prevState,
        terminals: state.terminalsAll[state.cityId - 1],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.cities, state.cityId]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      sendOfferButtonDisabled:
        (state.cityName === null || state.currentAddress === null) && state.selectedRadio === 'third' ? true : false,
    }));
  }, [state.selectedRadio, state.cityName, state.currentAddress]);

  const prepareSubmitObj = () => {
    let obj = {};
    if (state.selectedRadio === 'first') {
      obj.destination = 'Нижегородская обл., г. Бор, ул. Луначарского, дом № 128, оф. 23';
      obj.isPickup = true;
    }
    if (state.selectedRadio === 'second') {
      obj.destination = 'Самовывоз со склада Московская область, г. Старая Купавна';
      obj.isPickup = true;
    }
    if (state.selectedRadio === 'third') {
      obj.destination = state.cityName + ' ' + state.currentAddress;
      obj.isPickup = false;
    }
    return obj;
  };

  const {
    cityId,
    cityName,
    cities,
    terminals,
    currentAddress,
    isOpenedCitiesDropdown,
    isOpenedTerminalsDropdown,
    selectedRadio,
  } = state;

  return (
    <Box style={{ ...getStyles().tableActions }} className="table-actions">
      <Box>
        <RadioGroup value={state.selectedRadio} defaultValue={selectedRadio}>
          <FormControlLabel
            value="first"
            style={{ ...getStyles().radioContainer }}
            control={
              <Radio
                color="primary"
                onChange={() => {
                  setState({
                    ...state,
                    selectedRadio: 'first',
                  });
                }}
              />
            }
            label={
              <Typography style={selectedRadio !== 'first' ? getStyles().disabledRadioLabel : getStyles().radioLabel}>
                Самовывоз со склада Нижегородская обл., г. Бор, ул. Луначарского, дом № 128, оф. 23
              </Typography>
            }
          />
          <FormControlLabel
            value="second"
            style={{ ...getStyles().radioContainer }}
            control={
              <Radio
                color="primary"
                onChange={() => {
                  setState({
                    ...state,
                    selectedRadio: 'second',
                  });
                }}
              />
            }
            label={
              <Typography style={selectedRadio !== 'second' ? getStyles().disabledRadioLabel : getStyles().radioLabel}>
                Самовывоз со склада Московская область, г. Старая Купавна
              </Typography>
            }
          />
          <FormControlLabel
            value="third"
            style={{ ...getStyles().radioContainer, marginBottom: 0 }}
            control={
              <Radio
                color="primary"
                onChange={() => {
                  setState({
                    ...state,
                    selectedRadio: 'third',
                  });
                }}
              />
            }
            label={
              <Typography style={selectedRadio !== 'third' ? getStyles().disabledRadioLabel : getStyles().radioLabel}>
                Организация доставки до терминала транспортной компании в
              </Typography>
            }
          />
          <KPDropDown
            disabled={selectedRadio !== 'third'}
            onOpen={() => {
              if (cities.length) {
                setState({
                  ...state,
                  isOpenedCitiesDropdown: !isOpenedCitiesDropdown,
                });
              }
            }}
            onClose={() => {
              setState({
                ...state,
                isOpenedCitiesDropdown: false,
              });
            }}
            type="city"
          >
            {!isOpenedCitiesDropdown ? (
              <MenuItem disabled value={0}>
                {cityName || 'Выберите город'}
              </MenuItem>
            ) : null}
            {cities.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
                onClick={() => {
                  setState({
                    ...state,
                    cityId: id,
                    cityName: cities.find(({ id: cityId }) => id === cityId).name,
                    isOpenedCitiesDropdown: !isOpenedCitiesDropdown,
                    currentAddress: null,
                  });
                }}
              >
                {name}
              </MenuItem>
            ))}
          </KPDropDown>
          <KPDropDown
            disabled={selectedRadio !== 'third'}
            onOpen={() => {
              if (cityId) {
                setState({
                  ...state,
                  isOpenedTerminalsDropdown: !isOpenedTerminalsDropdown,
                });
              }
            }}
            onClose={() => {
              setState({
                ...state,
                isOpenedTerminalsDropdown: false,
              });
            }}
            type="terminal"
          >
            {!isOpenedTerminalsDropdown ? (
              <MenuItem disabled value={0}>
                {currentAddress || 'Выберите терминалы'}
              </MenuItem>
            ) : null}
            {terminals.map(({ id, address }) => (
              <MenuItem
                key={id}
                value={id}
                onClick={() => {
                  setState({
                    ...state,
                    currentAddress: address,
                    isOpenedTerminalsDropdown: !isOpenedTerminalsDropdown,
                  });
                }}
              >
                {address}
              </MenuItem>
            ))}
          </KPDropDown>
          <Typography style={getStyles().dropdownDesc}>
            ( Оплата доставки производится клиентом при получении груза )
          </Typography>
        </RadioGroup>
      </Box>
      <Box style={getStyles().rightColumn}>
        <Box>
          <Box style={getStyles().totalPriceContainer}>
            <Typography style={getStyles().preTotalPriceText}>Всего с НДС:</Typography>
            <Typography style={getStyles().totalPrice}>{totalPrice} руб.</Typography>
          </Box>
          <MuiThemeProvider theme={theme}>
            <ButtonComponent
              width="255px"
              variant="contained"
              color="primary"
              //disabled={!totalPrice}
              disabled={state.sendOfferButtonDisabled}
              onClick={() => {
                onSubmit(prepareSubmitObj());
              }}
            >
              <Typography style={getStyles().buttonTextStyle}>Сформировать предложение</Typography>
            </ButtonComponent>
          </MuiThemeProvider>
        </Box>
      </Box>
    </Box>
  );
};

const getStyles = () => {
  const styles = {
    buttonTextStyle: {
      ...commonStyles.text.robotoRegular18,
      ...{ textTransform: 'none' },
    },
    tableActions: {
      display: 'grid',
      gridTemplateColumns: 'minmax(auto, 100%) minmax(auto, 311px)',
      gridGap: 74,
      padding: '40px 50px 50px 50px',
    },
    radioContainer: {
      marginBottom: 11,
    },
    checkBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: -11,
      marginBottom: 8,
    },
    checkBoxLabel: {
      ...commonStyles.text.robotoRegular18,
      fontWeight: 400,
      marginLeft: 7,
    },
    disabledRadioLabel: {
      ...commonStyles.text.robotoRegular18,
      color: '#787878',
      fontWeight: 400,
    },
    radioLabel: {
      ...commonStyles.text.robotoRegular18,
      fontWeight: 400,
    },
    dropdownDesc: {
      ...commonStyles.text.robotoRegular18,
      fontWeight: 400,
      padding: '10px 0 0 30px',
    },
    rightColumn: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    totalPriceContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 21,
    },
    preTotalPriceText: {
      ...commonStyles.text.robotoRegular18,
      color: '#787878',
      fontWeight: 400,
      marginRight: 20,
    },
    totalPrice: {
      ...commonStyles.text.robotoRegular18,
      fontWeight: 400,
      color: '#202020',
    },
  };
  return styles;
};

export default TableActions;
