import React from 'react';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import HotWaterSupplyCalcMainComponent from './HotWaterSupplyCalcMainComponent';

function HotWaterSupplyCalcScreen() {
  return (
    <React.Fragment>
      <HeaderComponent screenNumber={0} topTabBarEnabled={true} topTabValue={2} />

      <HotWaterSupplyCalcMainComponent />

      <FooterComponent />
    </React.Fragment>
  );
}

export default HotWaterSupplyCalcScreen;
