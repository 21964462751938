import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Table,
  Box,
  Container,
  Typography,
} from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import MainClass from '../../main/MainClass';

import commonStyles from '../../commonUtils/commonStyles';
import { roundToDecimals } from '../../commonUtils/commonUtils';

import { API_ENDPOINTS_LIST, ROUTES_LIST } from '../../constants';
import { GlobalContext } from '../..';
import useServerRequest from '../../hooks/serverRequest';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';

import CrossIcon from './assets/KPTable/cross.svg';
import ArrowUpIcon from './assets/KPTable/arrow-up.svg';
import ArrowDownIcon from './assets/KPTable/arrow-down.svg';
import TableActions from './components/KPTableActions/TableActions';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const initialStateObject = {
  data: [],
  maxDiscount: 0,
  totalPrice: 0,
};

const CommercialOfferScreen = () => {
  const [state, setState] = useState(initialStateObject);
  const [cartType, setCartType] = useState('');

  const location = useLocation();
  const history = useHistory();

  const { setGlobalLoading, setGlobalState } = useContext(GlobalContext);

  const { getRequest: getCartData } = useServerRequest(setGlobalLoading);
  const { getRequest: delCartElement } = useServerRequest(setGlobalLoading);
  const { getRequest: updateElementCount } = useServerRequest(setGlobalLoading);
  const { getRequest: updateElementDiscount } = useServerRequest(setGlobalLoading);
  const { getRequest: submitOffer } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    if (location.state !== undefined) {
      setCartType(location.state.type);
    } else {
      setCartType('none');
    }
  }, [location]);

  useEffect(() => {
    if (cartType !== '') getActualCartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartType, getCartData]);

  const title = 'Наименование';

  const getActualCartData = () => {
    let requestStr;
    if (cartType !== 'none') {
      requestStr = API_ENDPOINTS_LIST.get_cart_data + '?cartType=tempCart';
    } else {
      requestStr = API_ENDPOINTS_LIST.get_cart_data;
    }
    getCartData(requestStr, '', 'GET').then((res) => {
      if (res && res.status === 200) {
        const maxDiscount = MainClass.shoppingCartGlobalData.userDiscount;
        let count = 0;
        let cartData = res.result.orders.map((obj) => {
          let item = {
            id: count,
            storeId: obj.storeId,
            name: obj.nameOfObject,
            pto: obj.pto,
            paymentNumber: obj.number,
            price: roundToDecimals(obj.price, 2) ? roundToDecimals(obj.price, 2) : 0,
            priceTotal: roundToDecimals(obj.totalPrice, 2) ? roundToDecimals(obj.totalPrice, 2) : 0,
            discount: obj.isDiscount,
            amount: obj.count,
          };
          count++;
          return item;
        });
        if (cartType !== 'temporary') MainClass.shoppingCartGlobalData.cartItemsNum = Number(res.result.itemsCount);
        setState((prevState) => ({
          ...prevState,
          data: cartData,
          maxDiscount: maxDiscount,
          totalPrice: res.result.totalPrice,
        }));
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  function deleteRow(e) {
    const storeId = state.data[this.index].storeId;
    let requestStr;
    if (cartType !== 'none') {
      requestStr = API_ENDPOINTS_LIST.del_cart_data + '/' + storeId + '?cartType=tempCart';
    } else {
      requestStr = API_ENDPOINTS_LIST.del_cart_data + '/' + storeId;
    }

    delCartElement(requestStr, '', 'DELETE').then((res) => {
      if (res && res.status === 200) {
        MainClass.shoppingCartGlobalData.cartItemsNum = Number(res.result.itemsCount);
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `"Объект удален из корзины"`,
          toastStyle: 'success',
        }));
        getActualCartData();
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Ошибка при удалении объекта из корзины`,
          toastStyle: 'error',
        }));
      }
    });
  }

  function updateRowCount(rowIndex, count) {
    const storeId = state.data[rowIndex].storeId;
    let requestStr;
    if (cartType !== 'none') {
      requestStr = API_ENDPOINTS_LIST.update_cart_data + '/' + storeId + '/changeCount/' + count + '?cartType=tempCart';
    } else {
      requestStr = API_ENDPOINTS_LIST.update_cart_data + '/' + storeId + '/changeCount/' + count;
    }

    updateElementCount(requestStr, '', 'POST').then((res) => {
      if (res && res.status === 200) {
        getActualCartData();
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `"Объект в корзине обновлен"`,
          toastStyle: 'success',
        }));
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Ошибка при обновлении объекта в корзине`,
          toastStyle: 'error',
        }));
      }
    });
  }

  function updateDiscount(rowIndex, discount) {
    const storeId = state.data[rowIndex].storeId;
    let requestStr;
    if (cartType !== 'none') {
      requestStr = API_ENDPOINTS_LIST.update_cart_data + '/' + storeId + '/discount/' + discount + '?cartType=tempCart';
    } else {
      requestStr = API_ENDPOINTS_LIST.update_cart_data + '/' + storeId + '/discount/' + discount;
    }

    updateElementDiscount(requestStr, '', 'POST').then((res) => {
      if (res && res.status === 200) {
        getActualCartData();
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `"Объект в корзине обновлен"`,
          toastStyle: 'success',
        }));
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Ошибка при обновлении объекта в корзине`,
          toastStyle: 'error',
        }));
      }
    });
  }

  const discountNumber = (discount) => {
    if (discount) {
      return <>{String(state.maxDiscount)}</>;
    } else {
      return <>{String(0)}</>;
    }
  };

  const submitNewOffer = (tableActionsState) => {
    /*
    {
      "destination": "Нижегородская обл., г. Бор, ул. Луначарского, дом № 128, оф. 23",
      "isPickup": true
    }
    */
    let requestStr;
    if (cartType !== 'none') {
      requestStr = API_ENDPOINTS_LIST.set_offer + '?cartType=tempCart';
    } else {
      requestStr = API_ENDPOINTS_LIST.set_offer;
    }

    submitOffer(requestStr, JSON.stringify(tableActionsState), 'POST').then((res) => {
      if (res && res.status === 200) {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Коммерческое предложение добавлено.`,
          toastStyle: 'success',
        }));
        //getActualCartData();
        setTimeout(() => {
          history.push(ROUTES_LIST.commercialOfferHistory);
        }, 2000);
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Ошибка при добавлении коммерческого предложения`,
          toastStyle: 'error',
        }));
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="root-container">
        <HeaderComponent
          screenNumber={8}
          leftButtonType="ArrowBack"
          hideCart={cartType !== 'none' ? 'hide' : undefined}
        />
        <Container>
          <TableContainer component={Paper} className="tables" style={getStyles().table}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular24,
                ...getStyles().titleText,
              }}
            >
              {title}
            </Typography>
            <Table aria-label="simple table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell style={{ ...getStyles().tableCellHead }}>N</TableCell>
                  <TableCell style={{ ...getStyles().tableCellHead }}>Название объекта</TableCell>
                  <TableCell style={{ ...getStyles().tableCellHead }}>ПТО</TableCell>
                  <TableCell style={{ ...getStyles().tableCellHead }}>Номер расчета</TableCell>
                  <TableCell style={{ ...getStyles().tableCellHead }}>Цена с НДС, руб.</TableCell>
                  <TableCell style={{ ...getStyles().tableCellHead }}>Скидка, %</TableCell>
                  <TableCell style={{ ...getStyles().tableCellHead }}>Количество</TableCell>
                  <TableCell style={{ ...getStyles().tableCellHead }}>Сумма с НДС, руб.</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {state.data.map(({ id, name, pto, paymentNumber, price, priceTotal, discount, amount }, index) => {
                  return (
                    <TableRow key={id}>
                      <TableCell className="first-table-body-column" style={{ ...getStyles().tableCellBody }}>
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ ...getStyles().tableCellBody }}>{name}</TableCell>
                      <TableCell style={{ ...getStyles().tableCellBody }}>{pto}</TableCell>
                      <TableCell style={{ ...getStyles().tableCellBody }}>{paymentNumber}</TableCell>
                      <TableCell style={{ ...getStyles().tableCellBody }}>{price}</TableCell>
                      <TableCell style={{ ...getStyles().tableCellBody }}>
                        <Box style={{ ...getStyles().actionsTableCell }}>
                          {discountNumber(discount)}
                          <Box style={{ ...getStyles().arrowsContainer }}>
                            <img
                              style={{ ...getStyles().arrowUp }}
                              src={ArrowUpIcon}
                              onClick={() => {
                                if (state.maxDiscount !== 0 && !discount) {
                                  updateDiscount(id, true);
                                }
                              }}
                              alt=""
                            />
                            <img
                              style={{ ...getStyles().arrowDown }}
                              src={ArrowDownIcon}
                              onClick={() => {
                                if (state.maxDiscount !== 0 && discount) {
                                  updateDiscount(id, false);
                                }
                              }}
                              alt=""
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell style={{ ...getStyles().tableCellBody }}>
                        <Box style={{ ...getStyles().actionsTableCell }}>
                          {amount}
                          <Box style={{ ...getStyles().arrowsContainer }}>
                            <img
                              style={{ ...getStyles().arrowUp }}
                              src={ArrowUpIcon}
                              onClick={() => {
                                updateRowCount(id, amount + 1);
                              }}
                              alt=""
                            />
                            <img
                              style={{ ...getStyles().arrowDown }}
                              src={ArrowDownIcon}
                              onClick={() => {
                                if (amount - 1) {
                                  updateRowCount(id, amount - 1);
                                }
                              }}
                              alt=""
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell style={{ ...getStyles().tableCellBody }}>{priceTotal}</TableCell>
                      <TableCell>
                        <img src={CrossIcon} data-id={id} onClick={deleteRow.bind({ index })} alt="cross-icon" />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TableActions
              onSubmit={(tableActionsState) => {
                submitNewOffer(tableActionsState);
              }}
              totalPrice={roundToDecimals(state.totalPrice, 2) ? roundToDecimals(state.totalPrice, 2) : 0}
            />
          </TableContainer>
        </Container>
        <FooterComponent />
      </Box>
    </ThemeProvider>
  );
};

const getStyles = () => {
  const styles = {
    table: {
      marginTop: '175px',
      marginBottom: '56px',
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
    },
    tableContainer: {
      overflowX: 'hidden',
    },
    titleText: {
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    tableCellHead: {
      color: '#787878',
      ...commonStyles.text.robotoRegular16,
      fontWeight: 400,
    },
    tableCellBody: {
      ...commonStyles.text.robotoRegular18,
      fontWeight: 400,
      color: '#202020',
    },
    actionsTableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    arrowsContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 28,
    },
    arrowUp: {
      paddingBottom: 4,
      cursor: 'pointer',
    },
    arrowDown: {
      paddingTop: 4,
      cursor: 'pointer',
    },
  };
  return styles;
};

export default CommercialOfferScreen;
