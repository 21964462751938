import React, { useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Box } from "@material-ui/core";
import commonStyles from "../../../../commonUtils/commonStyles";

export default function PasswordInput({
  value,
  onChange,
  error,
  helperText,
  errorText,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <OutlinedInput
        id="standard-adornment-password"
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        error={error}
      />
      {error && (
        <Box component="span" style={getStyles().errorMsg}>
          {helperText || errorText}
        </Box>
      )}
    </FormControl>
  );
}

const getStyles = () => {
  const styles = {
    passwordInputContainer: {
      position: "relative",
      marginBottom: "30px",
    },
    passwordInput: {
      font: "small-caption",
      fontSize: "16px",
    },
    errorMsg: {
      color: commonStyles.color.authError,
      position: "absolute",
      bottom: "-20px",
      fontSize: "0.75rem",
      margin: "-12px 14px 5px",
    },
  };
  return styles;
};
