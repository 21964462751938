import React, { useState, useEffect, useContext, memo, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Box,
} from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import commonStyles from '../../commonUtils/commonStyles';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';

import { API_ENDPOINTS_LIST } from '../../constants';
import { GlobalContext } from '../..';
import useServerRequest from '../../hooks/serverRequest';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const Catalog = () => {
  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: getCatalog } = useServerRequest(setGlobalLoading);
  const title = 'Список документов';
  const sectionArr = ['Раздел', 'Название документа', 'Файл'];
  const [data, setData] = useState([]);

  const {
    sectionBlockContainer,
    sectionImage,
    tableContainer,
    tableBodyText,
    titleText,
    table,
    headerText,
    link,
  } = getStyles();

  useEffect(() => {
    getCatalog(API_ENDPOINTS_LIST.get_docs + '/Brochures', '', 'GET').then((res) => {
      if (res && res.status === 200) {
        setData(res.result);
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  }, []);

  const mockArr = useMemo(
    () =>
      data &&
      data.map(({ id, preview, section, name, document }) => (
        <TableRow key={id}>
          <TableCell
            style={{
              ...commonStyles.text.robotoRegular18,
              ...tableBodyText,
            }}
            align="left"
          >
            <Box style={sectionBlockContainer}>
              <img style={sectionImage} src={preview} alt="img" />

              {section}
            </Box>
          </TableCell>
          <TableCell
            style={{
              ...commonStyles.text.robotoRegular18,
              ...tableBodyText,
            }}
            align="left"
          >
            {name}
          </TableCell>
          <TableCell
            style={{
              ...commonStyles.text.robotoRegular18,
              ...tableBodyText,
            }}
            align="right"
          >
            <a className="file-link" style={link} href={document} download>
              Скачать
            </a>
          </TableCell>
        </TableRow>
      )),
    [data]
  );

  return (
    <ThemeProvider theme={theme}>
      <Box className="root-container">
        <HeaderComponent screenNumber={6} />
        <Container className="aaa" style={tableContainer}>
          <TableContainer style={table} component={Paper}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular24,
                ...titleText,
              }}
            >
              {title}
            </Typography>

            <Table aria-label="table">
              <TableHead>
                {data.length !== 0 ? (
                  <TableRow>
                    <TableCell
                      style={{
                        ...commonStyles.text.robotoRegular16,
                        ...headerText,
                        ...{ paddingLeft: '90px' },
                      }}
                      align="left"
                    >
                      {sectionArr[0]}
                    </TableCell>
                    <TableCell
                      style={{
                        ...commonStyles.text.robotoRegular16,
                        ...headerText,
                      }}
                      align="left"
                    >
                      {sectionArr[1]}
                    </TableCell>
                    <TableCell
                      style={{
                        ...commonStyles.text.robotoRegular16,
                        ...headerText,
                        ...{ paddingRight: '48px' },
                      }}
                      align="right"
                    >
                      {sectionArr[2]}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableHead>
              <TableBody>{mockArr}</TableBody>
            </Table>
          </TableContainer>
        </Container>
        <FooterComponent />
      </Box>
    </ThemeProvider>
  );
};

const getStyles = () => {
  const styles = {
    table: {
      marginTop: '175px',
      marginBottom: '56px',
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
    },
    tableContainer: {
      overflowX: 'hidden',
    },
    titleText: {
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    headerText: {
      textTransform: 'none',
      color: '#787878',
      fontWeight: 'normal',
      //border: '1px solid',
      //borderColor: 'green',
    },
    tableBody: {
      verticalAlign: 'top',
    },
    tableBodyText: {
      color: '#202020',
      fontWeight: 'normal',
      verticalAlign: 'top',
    },
    link: {
      color: '#0C63AD',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    sectionBlockContainer: {
      display: 'inline-flex',
      alignItems: 'flex-start',
    },
    sectionText: {},
    sectionImage: {
      width: 60,
      height: 85,
      marginRight: 12,
      filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))',
    },
    fileLink: {
      cursor: 'pointer',
    },
  };
  return styles;
};

export default memo(Catalog);
