import Value from '../Value.js';
import { default as data_conditions} from '../configs/conditions/conditions.js';

export default class TemperatureValidation {
  constructor(coldSide, hotSide) {
    ////////////////////////////
    this.hotSide = { condition: null, props: {} };
    this.coldSide = { condition: null, props: {} };
    ////////////////////////////
    this.conditions = {};
    for (let c_i of data_conditions) this.conditions[c_i.name] = c_i;
    ////////////////////////////
    this.setCondition('hotSide', hotSide.conditionName);
    this.setConditionValues('hotSide', {
      T_input: hotSide.T_input || new Value('град. Цельсия'),
      T_output: hotSide.T_output || new Value('град. Цельсия'),
    });
    ////////////////////////////
    this.setCondition('coldSide', coldSide.conditionName);
    this.setConditionValues('coldSide', {
      T_input: coldSide.T_input || new Value('град. Цельсия'),
      T_output: coldSide.T_output || new Value('град. Цельсия'),
    });
    ////////////////////////////
  }
  _checkTemperatureRagne(side) {
    const range = [
      this[side].condition.density[0].t,
      this[side].condition.density[this[side].condition.density.length - 1].t,
    ];
    if (this[side].props.T_input.toCI() > range[1] || this[side].props.T_input.toCI() < range[0])
      return { error: true, range: range };
    if (this[side].props.T_output.toCI() > range[1] || this[side].props.T_output.toCI() < range[0])
      return { error: true, range: range };
    return { error: false };
  }
  setConditionValues(side, props) {
    for (let key in props) {
      this[side].props[key] = props[key];
    }
    return this;
  }
  setCondition(side, name, concentration = null) {
    const conditionName = concentration ? `${name}-${concentration}%` : name;
    this[side].condition = this.conditions[conditionName];
    if (!this[side].condition) throw `we dont support ${name} condition`;
    if (this.isUseTemperatureBalance) this._onChangeCondition(side);
    return this;
  }
  _checkTemperatureValues() {
    let errorMessage = '';
    let errorCode = [];
    if (this['hotSide'].props.T_input.toCI() < this['hotSide'].props.T_output.toCI()) {
      errorMessage += '\n По горячей стороне температуры не корректы';
      errorCode.push(2);
    }
    if (this['coldSide'].props.T_input.toCI() > this['coldSide'].props.T_output.toCI()) {
      errorMessage += '\n По холодной стороне температуры не корректы';
      errorCode.push(3);
    }
    if (this['hotSide'].props.T_input.toCI() < this['coldSide'].props.T_output.toCI()) {
      errorMessage += '\n Температура входа горячей стороны не может быть меньше температуры выхода холодной стороны';
      errorCode.push(4);
    }
    if (this['hotSide'].props.T_output.toCI() < this['coldSide'].props.T_input.toCI()) {
      errorMessage += '\n Температура выхода горячей стороны не может быть меньше температуры входа холодной стороны';
      errorCode.push(5);
    }
    if (this._checkTemperatureRagne('hotSide').error) {
      errorMessage += `\n Температура выхода/входа горячей стороны должна быть внутри диапазона (${
        this._checkTemperatureRagne('hotSide').range
      })`;
      errorCode.push(6);
    }
    if (this._checkTemperatureRagne('coldSide').error) {
      errorMessage += `\n Температура выхода/входа холодной стороны должна быть внутри диапазона (${
        this._checkTemperatureRagne('coldSide').range
      })`;
      errorCode.push(7);
    }
    if (!errorMessage)
      return {
        success: true,
      };
    else
      return {
        success: false,
        error: { message: errorMessage, code: errorCode },
      };
  }
  _checkHaveAllParametrs() {
    let result = true;
    for (let side of ['hotSide', 'coldSide']) {
      result = result && this[side].condition;
      for (let v_i in this[side].props) {
        result = result && this[side].props[v_i].value;
      }
    }
    return result;
  }
  checkBalance() {
    if (!this._checkHaveAllParametrs())
      return {
        success: false,
        error: { message: 'Не все значения температур заполнены', code: 0 },
      };
    return this._checkTemperatureValues();
  }
}
