import React, { useState } from 'react';

import LoginForm from './LogingForm';
import ResetPasswordForm from './ResetPasswordForm';
import ResetSuccessPage from './ResetSuccessPage';


const initialLoginValues = {
  email: '',
  password: '',
  step: 1,
  loginError: false,
  emailForReset: '',
};

export default function LoginContainer() {
  const [loginValues, setLoginValues] = useState(initialLoginValues);

  const handlePasswordReset = (e) => {
    e.preventDefault();
    setLoginValues((prevState) => ({
      ...prevState,
      step: 2,
    }));
  };

  const handleLoginValuesChange = (prop) => (event) => {
    setLoginValues({ ...loginValues, [prop]: event.target.value });
  };

  if (loginValues.step === 1) {
    return (
      <LoginForm
        handleLoginValuesChange={handleLoginValuesChange}
        loginValues={loginValues}
        handlePasswordReset={handlePasswordReset}
        setLoginValues={setLoginValues}
      />
    );
  }

  if (loginValues.step === 2) {
    return (
      <ResetPasswordForm
        emailForReset={loginValues.emailForReset}
        setLoginValues={setLoginValues}
        handleLoginValuesChange={handleLoginValuesChange}
      />
    );
  }

  if (loginValues.step === 3) {
    return (
      <ResetSuccessPage
        emailForReset={loginValues.emailForReset}
        setLoginValues={setLoginValues}
      />
    );
  }
}
