import React, { useState, useEffect, createContext } from 'react';
import ReactDOM from 'react-dom';
import { AliveScope } from 'react-activation';
import { useIdleTimer } from 'react-idle-timer';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'fontsource-roboto';
import jwt_decode from 'jwt-decode';
import HttpsRedirect from 'react-https-redirect';

//common styles
import commonStyles from './commonUtils/commonStyles';

import MainClass from './main/MainClass';

import { BASE_URL, APP_MODE } from './constants';

import CalculateRPTOScreen from './screens/CalculateRPTO/CalculateRPTOScreen';
import CalculateTechnologyScreen from './screens/CalculateTechnologyScreen/CalculateTechnologyScreen';
import AuthScreen from './screens/AuthScreen/AuthScreen';
import PersonalInfoScreen from './screens/PersonalInfoScreen';

import PrivateRoute from './screens/Components/PrivateRoute';
import { isValidToken, resetToken } from './authUtils/userToken';
import ArchiveScreen from './screens/ArchiveScreen/ArchiveScreen';
import { ROUTES_LIST, GLOBAL_STATE } from './constants';
import LoaderComponent from './screens/Components/LoaderComponent/LoaderComponent';
import ToastComponent from './screens/Components/ToastComponent/ToastComponent';
import CalculateRPTOPoverochniyScreen from './screens/CalculateRPTOPoverochniy/CalculateRPTOPoverochniyScreen';
import ModelsScreen from './screens/ModelsScreen/ModelsScreen';
import AdminPanel from './screens/AdminPanel/AdminPanel';
import DocumentsScreen from './screens/DocumentsScreen/DocumentsScreen';
import CatalogsScreen from './screens/CatalogsScreen/CatalogsScreen';
import CommercialOfferScreen from './screens/CommercialOfferScreen/CommercialOfferScreen';
import CommercialOfferHistoryScreen from './screens/CommercialOfferHistoryScreen/CommercialOfferHistoryScreen';
import InvoicesPaymentsHistoryScreen from './screens/InvoicesPaymentsHistoryScreen/InvoicesPaymentsHistoryScreen';
import HotWaterSupplyScreen from './screens/HotWaterSupplyScreen/HotWaterSupplyScreen';
import HotWaterSupplyCalcScreen from './screens/HotWaterSupplyCalcScreen/HotWaterSupplyCalcScreen';
import ITPCalculationScreen from './screens/ITPCalculationScreen/ITPCalculationScreen';
import MaintenanceScreen from './screens/MaintenanceScreen/MaintenanceScreen';

export const GlobalContext = createContext(null);

const App = () => {
  require('dotenv').config();
  MainClass.init();
  document.body.style.background = commonStyles.color.background;

  const existingTokens = JSON.parse(localStorage.getItem('tokens'));

  let checkedToken = '';

  if (existingTokens !== null) {
    checkedToken = isValidToken(existingTokens.tokenTimeStamp) ? existingTokens : '';
  }
  const decoded = existingTokens && jwt_decode(existingTokens.token);
  const calculator_user_admin = decoded?.role?.admin
    ? 'admin'
    : decoded?.role?.calculator
    ? 'calculator'
    : decoded?.role?.user
    ? 'user'
    : '';
  //console.log('index user role: ' + JSON.stringify(calculator_user_admin));
  MainClass.userRole = calculator_user_admin;

  const [authTokens, setAuthTokens] = useState(checkedToken);
  const [consultant, setConsultant] = useState(null);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [globalState, setGlobalState] = useState(GLOBAL_STATE);
  const [userRole, setUserRole] = useState(decoded?.role?.admin ? 'admin' : 'user');

  const setTokens = (data) => {
    if (data) {
      localStorage.setItem('tokens', JSON.stringify(data));
    } else {
      localStorage.removeItem('tokens');
    }
    setAuthTokens(data);
  };

  useEffect(() => {
    if (checkedToken === '') {
      resetToken(setGlobalState);
    }
  }, [checkedToken]);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setGlobalState((prevState) => ({
      ...prevState,
      toastOpen: false,
    }));
  };

  //Handle Idle Time

  const handleOnIdle = (event) => {
    //console.log('user is idle', event);
    //console.log('last active', getLastActiveTime());
    resetToken(setGlobalState);
  };

  const handleOnAction = (e) => {
    //console.log('user did something', e);
  };

  useIdleTimer({
    timeout: 900000, // 15 minutes for timeout
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 1000,
  });

  //

  const userRoutes = (
    <Switch>
      {!checkedToken && (
        <Route exact path="/">
          <AuthScreen />
        </Route>
      )}
      <PrivateRoute exact path="/">
        <CalculateRPTOScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.rptoCalculation}>
        <CalculateRPTOScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.rptoCalculationPoverochniy}>
        <CalculateRPTOPoverochniyScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.technologyCalculation}>
        <CalculateTechnologyScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.calculationArchive}>
        <ArchiveScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.modelsCatalog}>
        <ModelsScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.documents}>
        <DocumentsScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.catalogs}>
        <CatalogsScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.personalInfo}>
        <PersonalInfoScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.commercialOffer}>
        <CommercialOfferScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.commercialOfferHistory}>
        <CommercialOfferHistoryScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.invoicesPaymentsHistory}>
        <InvoicesPaymentsHistoryScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.hotWaterSupply}>
        <HotWaterSupplyScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.hotWaterSupplyCalc}>
        <HotWaterSupplyCalcScreen />
      </PrivateRoute>

      <PrivateRoute exact path={ROUTES_LIST.itpCalculation}>
        <ITPCalculationScreen />
      </PrivateRoute>

      <Redirect path="*" to="/" />
    </Switch>
  );

  const adminRoutes = (
    <Switch>
      {!checkedToken && (
        <Route exact path="/">
          <AuthScreen />
        </Route>
      )}
      <PrivateRoute exact path="/">
        <AdminPanel />
      </PrivateRoute>
      <PrivateRoute exact path={ROUTES_LIST.adminPanel}>
        <AdminPanel />
      </PrivateRoute>
      <PrivateRoute exact path={ROUTES_LIST.personalInfo}>
        <PersonalInfoScreen />
      </PrivateRoute>
      <Redirect path="*" to="/" />
    </Switch>
  );

  const normalMode = <Router>{userRole === 'admin' ? adminRoutes : userRoutes}</Router>;
  const maintenanceMode = <MaintenanceScreen />;

  return (
    <GlobalContext.Provider
      value={{
        authTokens,
        setAuthTokens: setTokens,
        consultant,
        setConsultant,
        globalLoading,
        setGlobalLoading,
        globalState,
        setGlobalState,
        userRole,
        setUserRole,
      }}
    >
      <LoaderComponent loading={globalLoading} />
      <ToastComponent
        open={globalState.toastOpen}
        close={handleToastClose}
        duration={globalState.toastAutohideDuration}
        styles={globalState.toastStyle}
        message={globalState.toastMessage}
      />
      {APP_MODE === 'normal' ? normalMode : maintenanceMode}
      {/*<Router>{userRole === 'admin' ? adminRoutes : userRoutes}</Router>*/}
    </GlobalContext.Provider>
  );
};

ReactDOM.render(
  <AliveScope>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </AliveScope>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
