import React from 'react';
import { Checkbox, Radio, Typography } from '@material-ui/core';
import commonStyles from '../../../../commonUtils/commonStyles';
import PopoverComponent from '../Popover/PopoverComponent';
import { trimStringToLength } from '../../../../commonUtils/commonUtils';
import { Link } from 'react-router-dom';
import moment from 'moment';

export const ItemSelect = (props) => {
  const { id, selectedRow } = props;
  const isSelected = selectedRow?.id === id;
  return (
    <Radio
      checked={isSelected}
      // onChange={handleChange}
      value="a"
      color="primary"
      name="radio-button-demo"
      inputProps={{ 'aria-label': 'A' }}
      style={getStyles().radio}
    />
  );
};

export const ItemSelectMultiple = (props) => {
  const { id, selectedRow } = props;
  const isSelected = !!selectedRow.find((item) => item?.id === id);
  return (
    <Checkbox
      checked={isSelected}
      // onChange={handleChange}
      color="primary"
      style={getStyles().radio}
    />
  );
};

export const ItemCount = (props) => {
  const { id, index, selectedRow } = props;
  const isSelected = selectedRow === id;
  const count = index;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{count}</Typography> : count;
};

export const ItemObjectName = (props) => {
  const { id, nameOfObject, selectedRow } = props;
  const isSelected = selectedRow === id;
  const stringLength = nameOfObject.length;
  if (stringLength <= 40) {
    return isSelected ? <Typography style={getStyles().selectedStyle}>{nameOfObject}</Typography> : nameOfObject;
  }

  const slicedString = `${trimStringToLength(nameOfObject, 40)}...`;
  const content = isSelected ? <Typography style={getStyles().selectedStyle}>{slicedString}</Typography> : slicedString;
  return (
    <PopoverComponent text={nameOfObject} placement="top" size="medium">
      {content}
    </PopoverComponent>
  );
};

export const ItemCalculationNumber = (props) => {
  const { id, number, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{number}</Typography> : number;
};

export const ItemDate = (props) => {
  const { id, date, selectedRow } = props;
  const isSelected = selectedRow === id;
  const formatedDate = moment(date, 'YYYY-MM-DDThh:mm:ssZ').format('DD/MM/YYYY');

  return isSelected ? <Typography style={getStyles().selectedStyle}>{formatedDate}</Typography> : formatedDate;
};

export const ItemDestination = (props) => {
  const { id, destination, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{destination}</Typography> : destination;
};

export const ItemComments = (props) => {
  const { id, commentaries, selectedRow } = props;
  const isSelected = selectedRow === id;
  const slicedString = `${trimStringToLength(commentaries, 30)}...`;
  const stringLength = commentaries.length;
  if (stringLength <= 30) {
    return isSelected ? <Typography style={getStyles().selectedStyle}>{commentaries}</Typography> : commentaries;
  }
  const content = isSelected ? <Typography style={getStyles().selectedStyle}>{slicedString}</Typography> : slicedString;
  return (
    <PopoverComponent text={commentaries} placement="top" size="medium">
      {content}
    </PopoverComponent>
  );
};

//Items for USERS_TABLE
export const ItemUserId = (props) => {
  const { id, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{id}</Typography> : id;
};

export const ItemClientId = (props) => {
  const { id, clientId, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{clientId}</Typography> : clientId;
};

export const ItemName = (props) => {
  const { id, firstName, middleName, lastName, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? (
    <Typography style={getStyles().selectedStyle}>{firstName + ' ' + middleName + ' ' + lastName}</Typography>
  ) : (
    firstName + ' ' + middleName + ' ' + lastName
  );
};

export const ItemSurname = (props) => {
  const { id, middleName, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{middleName}</Typography> : middleName;
};

export const ItemFamily = (props) => {
  const { id, lastName, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{lastName}</Typography> : lastName;
};

export const ItemPosition = (props) => {
  const { id, position, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{position}</Typography> : position;
};

export const ItemInn = (props) => {
  const { id, inn, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{inn}</Typography> : inn;
};

export const ItemOrganization = (props) => {
  const { id, companyName, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{companyName}</Typography> : companyName;
};

export const ItemCountry = (props) => {
  const { id, country, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{country}</Typography> : country;
};

export const ItemTelephoneNumber = (props) => {
  const { id, phone, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{phone}</Typography> : phone;
};

export const ItemPto = (props) => {
  const {
    id,
    tableData: { pto },
    selectedRow,
  } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{pto}</Typography> : pto;
};

export const ItemEmail = (props) => {
  const { id, email, selectedRow } = props;
  const isSelected = selectedRow === id;

  return isSelected ? <Typography style={getStyles().selectedStyle}>{email}</Typography> : email;
};

export const ItemStatus = (props) => {
  const { id, accepted, selectedRow } = props;
  const isSelected = selectedRow === id;
  if (accepted) {
    const result = 'Подтверждено';
    return isSelected ? <Typography style={getStyles().selectedStyle}>{result}</Typography> : result;
  } else {
    const result = 'Отклонено';
    return isSelected ? <Typography style={getStyles().selectedStyle}>{result}</Typography> : result;
  }
};

export const ItemDu = ({ du }) => du;
export const ItemTypo = ({ typo }) => typo;
export const ItemPres = ({ pres }) => pres;
export const ItemPlates = ({ plates }) => plates;
export const ItemNum = ({ number }) => number;
export const ItemLink = ({ link }) => {
  return (
    <Link
      to={{
        pathname: link,
      }}
      target="_blank"
      download
      style={getStyles().link}
    >
      Скачать
    </Link>
  );
};

const getStyles = () => {
  const styles = {
    radio: {
      minHeight: '48px',
      padding: '0 9px',
    },
    selectedStyle: {
      margin: '-3px 0',
      fontWeight: 500,
      fontSize: '18px',
      color: commonStyles.color.blue,
    },
    link: {
      fontWeight: 550,
      fontSize: '18px',
      color: commonStyles.color.blue,
      textDecoration: 'none',
    },
  };
  return styles;
};
