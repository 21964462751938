import React, { useState } from 'react';

import AuthTextInputComponent from '../../../Components/Elements/AuthTextInput';
import AuthSelectComponent from '../../../Components/Elements/AuthSelect';
import AuthPasswordInputComponent from '../../../Components/Elements/AuthPasswordInput';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { styled } from '@material-ui/core/styles';

import { isCyrillic } from '../../../../authUtils/isCyrillic';
import { validateEmail, validatePassword } from '../../../../authUtils/validators';
import { USER_TYPES_LIST, COUNTRY_NAMES_LIST, ERROR_TYPES_LIST } from '../../../../constants';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

import Politics from '../../../../assets/politics.pdf';
import Rules from '../../../../assets/rules.pdf';

const initialErrors = {
  lastName: '',
  firstName: '',
  middleName: '',
  city: '',
  email: '',
};

function RegisterIndividualsForm({ individualValues, handleIndividualChange, handleSubmit }) {
  const [isAgree, setIsAgree] = useState(false);
  const [errorsText, setErrorsText] = useState(initialErrors);

  const handleFormSubmit = async () => {
    const inputsToValidate = ['lastName', 'firstName', 'middleName', 'city', 'email', 'password'];
    const newErrorTexts = {};

    inputsToValidate.forEach((field) => {
      if (field === 'password') {
        const isCorrect = validatePassword(individualValues[field]);
        newErrorTexts[field] = isCorrect ? '' : ERROR_TYPES_LIST.invalidPassword;
      }

      if (field === 'email') {
        const isCorrect = validateEmail(individualValues[field]);
        newErrorTexts[field] = isCorrect ? '' : ERROR_TYPES_LIST.invalidEmail;
      } else if (
        individualValues[field] !== '' &&
        (field === 'lastName' || field === 'city' || field === 'middleName' || field === 'firstName')
      ) {
        const errorText = isCyrillic(individualValues[field]) ? '' : ERROR_TYPES_LIST.nonCyrillic;
        newErrorTexts[field] = errorText;
      }
    });

    setErrorsText(newErrorTexts);
    //console.log(newErrorTexts);

    const formHasNoErrors = Object.values(newErrorTexts).every((error) => error === '');

    if (formHasNoErrors) {
      // check if form is not empty
      // check if there are no validation errors
      let userData = { type: USER_TYPES_LIST.individual };

      Object.keys(individualValues).forEach((key) => {
        if (individualValues[key].length > 0) {
          userData[key] = individualValues[key];
        }
      });

      // check if phone number was not added
      if (userData.phone.length < 4) {
        const { phone, ...rest } = userData;
        userData = Object.assign({}, rest);
      }

      handleSubmit(userData);
    }
  };

  return (
    <StyledGrid container>
      <Grid container>
        <Grid item xs={6} style={getStyles().gridItemLeft}>
          <AuthTextInputComponent
            id="lastName"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Фамилия"
            variant="outlined"
            value={individualValues.lastName}
            onChange={handleIndividualChange('lastName')}
            error={!!errorsText.lastName}
            helperText={errorsText.lastName}
          />

          <AuthTextInputComponent
            id="firstName"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Имя"
            variant="outlined"
            value={individualValues.firstName}
            onChange={handleIndividualChange('firstName')}
            error={!!errorsText.firstName}
            helperText={errorsText.firstName}
          />
          <AuthTextInputComponent
            id="middleName"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Отчество"
            variant="outlined"
            value={individualValues.middleName}
            onChange={handleIndividualChange('middleName')}
            error={!!errorsText.middleName}
            helperText={errorsText.middleName}
          />
          <AuthPasswordInputComponent
            id="password"
            style={{ marginBottom: '30px' }}
            label="Пароль"
            value={individualValues.password}
            onChange={handleIndividualChange('password')}
            error={!!errorsText.password}
            helperText={errorsText.password}
            hasError={!!errorsText.password}
          />
        </Grid>
        <Grid item xs={6} style={getStyles().gridItemRight}>
          <AuthTextInputComponent
            id="city"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Город проживания"
            variant="outlined"
            value={individualValues.city}
            onChange={handleIndividualChange('city')}
            error={!!errorsText.city}
            helperText={errorsText.city}
          />
          <AuthSelectComponent
            value={individualValues.country}
            onChange={handleIndividualChange('country')}
            id="select-country"
            variant="outlined"
            label="Страна мобильного оператора"
            name="country"
            style={{ marginBottom: '30px' }}
            options={COUNTRY_NAMES_LIST}
          />
          <AuthTextInputComponent
            id="phone"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Номер телефона"
            variant="outlined"
            value={individualValues.phone}
            onChange={handleIndividualChange('phone')}
          />
          <AuthTextInputComponent
            id="email"
            style={{ marginBottom: '30px' }}
            fullWidth
            type="email"
            label="Электронная почта"
            variant="outlined"
            value={individualValues.email}
            onChange={handleIndividualChange('email')}
            error={!!errorsText.email}
            helperText={errorsText.email}
          />
        </Grid>
      </Grid>
      <Grid container style={getStyles().rulesContainer}>
        <Grid item xs={1}>
          <Checkbox
            checked={isAgree}
            color="primary"
            onChange={() => setIsAgree(!isAgree)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            style={getStyles().checkBox}
          />
        </Grid>
        <Grid item xs={11}>
          <Box style={getStyles().rulesText}>
            Я принимаю условия{' '}
            <Link href={Politics} underline="always" target="_blank">
              Пользовательского соглашения
            </Link>{' '}
            и даю свое согласие “ЭТРА” на обработку моей персональной информации на условиях, определенных{' '}
            <Link href={Rules} underline="always" target="_blank">
              Политикой конфиденциальности
            </Link>
            .
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid item>
          <ButtonComponent variant="contained" color="primary" disabled={!isAgree} onClick={handleFormSubmit}>
            Подтвердить
          </ButtonComponent>
        </Grid>
      </Grid>
    </StyledGrid>
  );
}

const StyledGrid = styled(Grid)({
  padding: '0 40px 30px',
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    bottom: '-22px',
    fontSize: '0.85rem',
  },
});

const getStyles = () => {
  const styles = {
    gridItemLeft: {
      paddingRight: '15px',
    },
    gridItemRight: {
      paddingLeft: '15px',
    },
    rulesContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: '1rem',
      marginTop: 20,
    },
    checkBox: {
      // padding: '0 20px 0 0',
      padding: '0',
    },
  };
  return styles;
};

export default RegisterIndividualsForm;
