import React from 'react';
import { Select, InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import commonStyles from '../../../../commonUtils/commonStyles';

const KPDropdownComponent = ({ type, onOpen, onClose, disabled, children }) => {
  return (
    <Select
      style={type === 'terminal' ? getStyles().terminalDropdown : getStyles().cityDropdown}
      value={0}
      input={<InputBase disabled={disabled} />}
      onOpen={onOpen}
      onClose={onClose}
    >
      {children}
    </Select>
  );
};

const getStyles = () => {
  const styles = {
    cityDropdown: {
      maxWidth: 284,
      marginBottom: 15,
      marginLeft: 30,
      border: '1px solid #a3a3a3',
      padding: '6px 0 7px 10px',
      color: '#787878',
      ...commonStyles.text.robotoRegular16,
      fontWeight: 400,
      borderRadius: 4,
      background: '#fff',
    },
    terminalDropdown: {
      marginLeft: 30,
      border: '1px solid #a3a3a3',
      padding: '6px 0 7px 10px',
      color: '#787878',
      ...commonStyles.text.robotoRegular16,
      fontWeight: 400,
      borderRadius: 4,
    },
  };
  return styles;
};

export default withStyles({
  '@global': {
    '.MuiSelect-select': {
      backgroundColor: '#fff !important',
    },
  },
})(KPDropdownComponent);
