import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';

import commonStyles from '../../../commonUtils/commonStyles';
import TextInputComponent from '../../Components/Elements/TextInput/TextInputComponent';

const title = 'Габаритные размеры (необязательно, при наличии)';

const labels = {
  environmentTemperature: 'Температура/влажность среды эксплуатации',
  minimumInstallationOpening: 'Минимальный монтажный проем',
  roomForInstallation: 'Размеры помещения для установки БИТП',
};

const Dimensions = ({ data, setValueHandler, stylesContext }) => {
  const { titleText, box, labelPosition, formContainer } = stylesContext;
  const { bundle } = getStyles();

  return (
    <Box style={{ ...box, ...bundle }}>
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {title}
      </Typography>
      <Grid container style={{ ...formContainer }} spacing={3}>
        <Grid item xs={12} md={8} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.environmentTemperature}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInputComponent
            value_type="°С"
            value={data.environmentTemperature}
            onChange={(e) => setValueHandler(e, 'environmentTemperature')}
          />
        </Grid>

        <Grid item xs={12} md={4} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.minimumInstallationOpening}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextInputComponent
            value_type="Ширина (м)"
            value={data.minimumInstallationOpeningWidth}
            onChange={(e) => setValueHandler(e, 'minimumInstallationOpeningWidth')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInputComponent
            value_type="Высота (м)"
            value={data.minimumInstallationOpeningHeight}
            onChange={(e) => setValueHandler(e, 'minimumInstallationOpeningHeight')}
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.roomForInstallation}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextInputComponent
            value_type="Длина (м)"
            value={data.roomForInstallationLength}
            onChange={(e) => setValueHandler(e, 'roomForInstallationLength')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInputComponent
            value_type="Ширина (м)"
            value={data.roomForInstallationWidth}
            onChange={(e) => setValueHandler(e, 'roomForInstallationWidth')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInputComponent
            value_type="Высота (м)"
            value={data.roomForInstallationHeight}
            onChange={(e) => setValueHandler(e, 'roomForInstallationHeight')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const getStyles = () => {
  return {};
};

export default Dimensions;
