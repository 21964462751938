import React from 'react';
import { Box, Typography, Grid, IconButton } from '@material-ui/core';
import {
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  ArrowBack as ArrowBackIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Print as PrintIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import commonStyles from '../../../commonUtils/commonStyles';
import { Link } from 'react-router-dom';

const GoBackButton = (props) => {
  const { close } = props;

  return (
    <IconButton onClick={close}>
      <ArrowBackIcon style={getStyles().arrowColor} />
    </IconButton>
  );
};

const CustomPrevButton = (props) => {
  const { page, handlePrevClick } = props;
  const showCursor = page === 1;

  return (
    <IconButton style={getStyles({ showCursor }).arrows} onClick={handlePrevClick}>
      <NavigateBeforeIcon style={getStyles().arrowColor} />
    </IconButton>
  );
};

const CustomNextButton = (props) => {
  const { page, pages, handleNextClick } = props;
  const showCursor = page === pages;

  return (
    <IconButton style={getStyles({ showCursor }).arrows} onClick={handleNextClick}>
      <NavigateNextIcon style={getStyles().arrowColor} />
    </IconButton>
  );
};

const CustomPages = (props) => {
  const { page, pages } = props;
  return (
    <Typography style={getStyles().pages}>
      Страница {page}/{pages}
    </Typography>
  );
};

const CustomZoom = (props) => {
  const { scale, handleZoomIn, handleZoomOut } = props;

  return (
    <Box style={getStyles().flexBox}>
      <IconButton onClick={handleZoomIn}>
        <ZoomInIcon style={getStyles().arrowColor} />
      </IconButton>
      <Typography>{`${scale * 100}%`}</Typography>
      <IconButton onClick={handleZoomOut}>
        <ZoomOutIcon style={getStyles().arrowColor} />
      </IconButton>
    </Box>
  );
};

const OperationsButtons = (props) => {
  const {
    links: { downloadlink, printLink },
    rptoSaved,
    rptoCheck,
  } = props;

  const printHandler = (event) => {
    event.preventDefault();
    const wnd = window.open(printLink, 'PRINT', 'height=400,width=600');
    setTimeout(() => wnd.print(), 300);
  };

  return (
    <Box style={getStyles().flexBox}>
      <IconButton onClick={(e) => (rptoSaved ? printHandler(e) : rptoCheck())}>
        <PrintIcon style={getStyles().arrowColor} />
      </IconButton>
      <IconButton
        onClick={(e) => {
          if (rptoSaved) return false;
          e.preventDefault();
          rptoCheck();
        }}
      >
        <Link to={{ pathname: downloadlink }} download target="_blank">
          <SaveIcon style={getStyles().arrowColor} />
        </Link>
      </IconButton>
    </Box>
  );
};

const CustomNavigation = ({ props, handleClose, links, rptoSaved, rptoCheck }) => {
  const { page, pages, handlePrevClick, handleNextClick } = props;

  return (
    <Grid container style={getStyles().fixedBar} justify="space-between">
      <Grid item xs={4}>
        <GoBackButton close={handleClose} />
      </Grid>
      <Grid item xs={4}>
        <Box style={getStyles().flexBox}>
          <CustomPrevButton page={page} pages={pages} handlePrevClick={handlePrevClick} />
          <CustomPages page={page} pages={pages} />
          <CustomNextButton page={page} pages={pages} handleNextClick={handleNextClick} />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Grid container justify="space-between">
          <CustomZoom {...props} />
          <Box style={getStyles().marginLeft}>
            <OperationsButtons links={links} rptoSaved={rptoSaved} rptoCheck={rptoCheck} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const getStyles = (props) => {
  const styles = {
    fixedBar: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      background: commonStyles.color.white,
      boxShadow:
        '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
      padding: '0 10px',
      zIndex: 2,
    },
    pages: {
      display: 'inline-block',
      marginTop: 0,
      color: commonStyles.color.blue,
      fontSize: '18px',
      fontWeight: 500,
    },
    arrows: {
      cursor: props?.showCursor ? 'inherit' : 'pointer',
      display: 'inline-block',
      margin: '0 12px',
      pointerEvents: props?.showCursor ? 'none' : 'inherit',
    },
    arrowColor: {
      color: commonStyles.color.blue,
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    marginLeft: {
      marginLeft: '209px',
    },
  };
  return styles;
};

export default CustomNavigation;
