import React, { useState, useContext, useEffect } from 'react';
import { ThemeProvider, createMuiTheme, Grid } from '@material-ui/core';
import commonStyles from '../../commonUtils/commonStyles';
import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import TopBarComponent from './Components/TopBar/TopBarComponent';
import UserTableComponent from './Components/UsersTable/UserTableComponent';
import { GlobalContext } from '../../';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../constants';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function AdminPanel() {
  const [archive, setArchive] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [clientIdArray, setClientIdArray] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const { setGlobalLoading, setGlobalState } = useContext(GlobalContext);
  const { getRequest: getArchive } = useServerRequest(setGlobalLoading);
  const { getRequest: getConsultants } = useServerRequest(setGlobalLoading);
  const { getRequest: setUserConsultant } = useServerRequest(setGlobalLoading);
  const { getRequest: changeStatus } = useServerRequest(setGlobalLoading);
  const { getRequest: setDiscount } = useServerRequest(setGlobalLoading);
  const { getRequest: setClientId } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    getArchive(API_ENDPOINTS_LIST.get_users, null, 'GET').then((res) => {
      if (res && res.status === 200) {
        //console.log('res: ' + JSON.stringify(res));
        setArchive(res.result);
        const clientIdArray = res.result.map((obj) => {
          return obj.id;
        });
        setClientIdArray(clientIdArray);
        //console.log('All ID: ' + JSON.stringify(clientIdArray));
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
    getConsultants(API_ENDPOINTS_LIST.get_consultants_list, null, 'GET').then((res) => {
      if (res && res.status === 200) {
        //console.log('res consul: ' + JSON.stringify(res));
        setConsultants(res.list);
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  }, [getArchive, setGlobalState]);

  const approveUserHandler = () => {
    const { id } = selectedRow;
    const request = {
      id: id,
      accepted: true,
    };

    changeStatus(API_ENDPOINTS_LIST.set_user_status, JSON.stringify(request), 'POST').then((res) => {
      if (res && res.status === 200) {
        setSelectedRow({});
        setArchive((prev) =>
          prev.map((item) => {
            return item.id === id ? { ...item, accepted: true } : item;
          })
        );
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const rejectUserHandler = () => {
    // TODO: send request to rejecting user
    const { id } = selectedRow;
    const request = {
      id: id,
      accepted: false,
    };

    changeStatus(API_ENDPOINTS_LIST.set_user_status, JSON.stringify(request), 'POST').then((res) => {
      if (res && res.status === 200) {
        setSelectedRow({});
        setArchive((prev) =>
          prev.map((item) => {
            return item.id === id ? { ...item, accepted: false } : item;
          })
        );
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const setUserDiscountHandler = (user) => {
    const request = {
      id: user.id,
      discount: Number(user.discount),
    };

    setDiscount(API_ENDPOINTS_LIST.set_user_discount, JSON.stringify(request), 'POST').then((res) => {
      if (res && res.status === 200) {
        setSelectedRow({});
        getArchive(API_ENDPOINTS_LIST.get_users, null, 'GET').then((res) => {
          if (res && res.status === 200) {
            setArchive(res.result);
          } else {
            setGlobalState((prevState) => ({
              ...prevState,
              toastOpen: true,
              toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
              toastStyle: 'error',
            }));
          }
        });
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const setUserIKHandler = (userId, consultantId) => {
    const request = {
      userId: Number(userId),
      consultantId: Number(consultantId),
    };
    //console.log('setUserIKHandler: ' + JSON.stringify(request));
    setUserConsultant(API_ENDPOINTS_LIST.set_user_consultant, JSON.stringify(request), 'POST').then((res) => {
      if (res && res.status === 200) {
        setSelectedRow({});
        getArchive(API_ENDPOINTS_LIST.get_users, null, 'GET').then((res) => {
          if (res && res.status === 200) {
            setArchive(res.result);
          } else {
            setGlobalState((prevState) => ({
              ...prevState,
              toastOpen: true,
              toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
              toastStyle: 'error',
            }));
          }
        });
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const setUserClientIdHandler = (userId, clientId) => {
    const request = {
      userId: Number(userId),
      clientId: Number(clientId),
    };
    //console.log('setUserClientIdHandler: ' + JSON.stringify(request));
    setClientId(API_ENDPOINTS_LIST.set_user_clientId, JSON.stringify(request), 'POST').then((res) => {
      if (res && res.status === 200) {
        setSelectedRow({});
        getArchive(API_ENDPOINTS_LIST.get_users, null, 'GET').then((res) => {
          if (res && res.status === 200) {
            setArchive(res.result);
          } else {
            setGlobalState((prevState) => ({
              ...prevState,
              toastOpen: true,
              toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
              toastStyle: 'error',
            }));
          }
        });
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={7} />
      <Grid container style={getStyles().topGrid}>
        <Grid container>
          <Grid item xs={11} md={10} lg={10} style={getStyles().infoPanel}>
            <TopBarComponent
              selectedRow={selectedRow}
              approveUser={approveUserHandler}
              rejectUser={rejectUserHandler}
              consultantsList={consultants}
              setUserDiscount={(user) => setUserDiscountHandler(user)}
              setUserIK={(userId, consultantId) => setUserIKHandler(userId, consultantId)}
              clientIdList={clientIdArray}
              setClientID={(userId, clientId) => setUserClientIdHandler(userId, clientId)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11} md={10} lg={11} style={getStyles().infoPanel}>
            <UserTableComponent rows={archive} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    topGrid: {
      marginTop: '225px',
      marginBottom: '56px',
    },
    infoPanel: {
      margin: '0 auto 20px',
    },
  };
  return styles;
};
