import React, { useState, useEffect, useContext } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import commonStyles from '../../../../commonUtils/commonStyles';
import { USER_TYPES_LIST, API_ENDPOINTS_LIST } from '../../../../constants';
import { GlobalContext } from '../../../..';
import useServerRequest from '../../../../hooks/serverRequest';

function createData(name, info) {
  return { name, info };
}

function UserInfo() {
  const [user, setUser] = useState(null);
  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: getUserInfo } = useServerRequest(setGlobalLoading);

  let rows = [];

  if (user !== null) {
    const { city, email, lastName, firstName, middleName, phone, type } = user;
    if (type === USER_TYPES_LIST.individual) {
      rows = [
        createData('ФИО', `${lastName} ${firstName} ${middleName}`),
        createData('Город проживания', city),
        createData('Номер телефона', phone),
        createData('Электронная почта', email),
      ];
    }
    if (type === USER_TYPES_LIST.legalEntities) {
      const { position, companyName, inn, individualLocation } = user;
      rows = [
        createData('ФИО сотрудника', `${lastName} ${firstName} ${middleName}`),
        createData('Должность сотрудника', position),
        createData('Наименование организации', companyName),
        createData('Номер телефона', phone),
        createData('ИНН', inn),
        createData('Электронная почта', email),
        createData(
          'Юридический адрес',
          `${individualLocation.country || ''}, ${individualLocation.city || ''}, ${individualLocation.street || ''}, ${
            individualLocation.house || ''
          }, кв./оф. ${individualLocation.apartment || ''}`
        ),
        createData(
          'Физический адрес',
          `${individualLocation.country || ''}, ${individualLocation.city || ''}, ${individualLocation.street || ''}, ${
            individualLocation.house || ''
          }, кв./оф. ${individualLocation.apartment || ''}`
        ),
      ];
    }
  }

  useEffect(() => {
    getUserInfo(API_ENDPOINTS_LIST.get_user, '', 'GET', true).then((res) => {
      if (res && !res.code) {
        setUser(res.result);
      } else {
        setGlobalState((prev) => ({
          ...prev,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
    // eslint-disable-next-line
  }, [getUserInfo]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="user info table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Typography style={getStyles().header} variant="h6">
                Информация о пользователе
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" style={getStyles().leftCell}>
                {row.name}
              </TableCell>
              <TableCell align="left" style={getStyles().boldCellText}>
                {row.info}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const getStyles = () => {
  const styles = {
    header: {
      color: commonStyles.color.blue,
      fontSize: '23px',
      fontWeight: 550,
      // textAlign: 'center',
    },
    boldCellText: {
      fontWeight: 'bold',
      fontSize: '18px',
    },
    leftCell: {
      paddingLeft: '50px',
      fontSize: '18px',
      // width: '40%'
    },
  };
  return styles;
};

export default UserInfo;
