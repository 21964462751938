import React, { useState } from 'react';
import { TableContainer, Paper, Container, Box, Typography, Radio } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import commonStyles from '../../commonUtils/commonStyles';
import { invoicesSearch } from '../../commonUtils/commonUtils';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import TableComponent from '../Components/Elements/Table/TableComponent';
import ShowCount from './Components/ShowCount';
import Filter from './Components/Filter';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

// todo Here should be backend
const mockDataAmount = 15;
const mockdataDifferent = [
  {
    countDate: '13.04.2020',
    countNumber: '000090195',
    name:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem corporis cumque impedit magnam nam perspiciatis quam sit unde voluptas. Assumenda eius exercitationem, facere hic illo libero molestiae sapiente sequi.',
    amount: '80 401,40 руб.',
    paymentDate: '20.12.2020',
    readinessDate: '20.12.2020',
    readinessStatus: 'Ожидайте',
    uploadDate: 'Ожидайте',
    id: 3567,
  },
  {
    countDate: '13.04.2020',
    countNumber: '000090195',
    name: 'Test 1',
    amount: '80 401,40 руб.',
    paymentDate: '20.12.2020',
    readinessDate: '20.12.2020',
    readinessStatus: '20.12.2020',
    uploadDate: 'Ожидайте',
    id: 3568,
  },
  {
    countDate: '13.04.2020',
    countNumber: '000090195',
    name: 'Test 21',
    amount: '80 401,40 руб.',
    paymentDate: '20.12.2020',
    readinessDate: '20.12.2020',
    readinessStatus: '20.12.2020',
    uploadDate: '20.12.2020',
    id: 345,
  },
];
const mockdata = {
  countDate: '13.04.2020',
  countNumber: '000090195',
  name:
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem corporis cumque impedit magnam nam perspiciatis quam sit unde voluptas. Assumenda eius exercitationem, facere hic illo libero molestiae sapiente sequi.',
  amount: '80 401,40 руб.',
  paymentDate: '20.12.2020',
  readinessDate: '20.12.2020',
  readinessStatus: '20.12.2020',
  uploadDate: '20.12.2020',
};
const mockDataArr = [...mockdataDifferent];
for (let i = 0; i < mockDataAmount; i++) {
  mockDataArr.push({ ...mockdata, id: i });
}

const PaymentsShipmentsHistory = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [selectedValue, setSelectedValue] = useState(mockDataArr[0].name);
  const [resultData, setResultData] = useState(mockDataArr);
  const title = 'История счетов, оплат и отгрузок';
  const getCellStyle = (selectedRow, rowId) => ({
    ...rowText,
    ...(selectedRow.some(({ id }) => id === rowId) && rowActive),
  });

  const sectionArr = [
    {
      id: 'head_1',
      label: '',
      componentRender: ({ id: rowId, selectedRow }) => (
        <Radio color="primary" style={radio} name="table-select" checked={selectedRow.some(({ id }) => id === rowId)} />
      ),
    },
    {
      id: 'head_2',
      label: 'Дата счета',
      componentRender: ({ countDate, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>{countDate}</Typography>
      ),
    },
    {
      id: 'head_3',
      label: 'Номер счета',
      componentRender: ({ countNumber, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>{countNumber}</Typography>
      ),
    },
    {
      id: 'head_4',
      label: 'Название объекта',
      componentRender: ({ name, selectedRow, id }) => (
        <Typography style={{ ...getCellStyle(selectedRow, id), ...rowTextName }}>{name}</Typography>
      ),
    },
    {
      id: 'head_5',
      label: 'Сумма счета, руб.',
      align: 'left',
      width: '150px',
      componentRender: ({ amount, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>{amount}</Typography>
      ),
    },
    {
      id: 'head_6',
      label: 'Дата оплаты',
      align: 'left',
      componentRender: ({ paymentDate, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>{paymentDate}</Typography>
      ),
    },
    {
      id: 'head_7',
      label: 'План готовности',
      componentRender: ({ readinessDate, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>{readinessDate}</Typography>
      ),
    },
    {
      id: 'head_8',
      label: 'Факт готовности',
      componentRender: ({ readinessStatus, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>{readinessStatus}</Typography>
      ),
    },
    {
      id: 'head_9',
      label: 'Дата отгрузки',
      componentRender: ({ uploadDate, selectedRow, id }) => (
        <Typography style={getCellStyle(selectedRow, id)}>{uploadDate}</Typography>
      ),
    },
  ];

  // todo some logic with show count
  const showCount = () => {
    //console.log('test');
  };

  const selectedValueHandler = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
    setResultData(invoicesSearch(value, mockDataArr, 'name'));
  };

  const { tableContainer, table, titleText, rowTextName, rowText, radio, rowActive } = getStyles();
  return (
    <ThemeProvider theme={theme}>
      <Box className="root-container">
        <HeaderComponent screenNumber={10} />
        <Container className="aaa" style={tableContainer}>
          <Filter data={mockDataArr} selectedValue={selectedValue} selectedValueHandler={selectedValueHandler} />
          <ShowCount showCount={showCount} activeItem={activeItem} />
          <TableContainer style={table} component={Paper} className="table-container">
            <Typography
              style={{
                ...commonStyles.text.robotoRegular24,
                ...titleText,
              }}
            >
              {title}
            </Typography>

            <TableComponent
              headCells={sectionArr}
              rows={resultData}
              canSelect={true}
              setSelectedRow={setActiveItem}
              selectedRow={activeItem ? [activeItem] : []}
            />
          </TableContainer>
        </Container>
        <FooterComponent />
      </Box>
    </ThemeProvider>
  );
};

const getStyles = () => {
  return {
    table: {
      marginTop: '10px',
      marginBottom: '56px',
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
    },
    titleText: {
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    rowTextName: {
      textTransform: 'none',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'left',
      maxWidth: '250px',
      whiteSpace: 'nowrap',
      ...commonStyles.text.robotoRegular18,
      fontWeight: 400,
      color: '#202020',
    },
    radio: {
      padding: 0,
    },
    rowText: {
      ...commonStyles.text.robotoRegular18,
      fontWeight: 400,
      color: '#202020',
    },
    rowActive: {
      color: '#0C63AD',
    },
    tableContainer: {
      overflowX: 'hidden',
    },
  };
};

export default PaymentsShipmentsHistory;
