import React from 'react';
//import { Container, Box, Typography, TextField, Grid } from '@material-ui/core';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import CalculateRPTOPoverochniyMainComponent from './CalculateRPTOPoverochniyMainComponent';

function CalculateRPTOPoverochniyScreen() {
  return (
    <React.Fragment>
      <HeaderComponent screenNumber={4} topTabBarEnabled={false} leftButtonType={'ArrowBack'} topTabValue={4} />

      <CalculateRPTOPoverochniyMainComponent />

      <FooterComponent />
    </React.Fragment>
  );
}

export default CalculateRPTOPoverochniyScreen;
