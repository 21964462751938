import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Table, TableHead, TableRow, TableCell, Radio, TableBody } from '@material-ui/core';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import commonStyles from '../../../../commonUtils/commonStyles';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.blue,
    },
  },
});

let radioButtonsEnabled = [];

const initialStateObject = {
  selectedListItem: null,
};

function RPTOPanel3Component(props) {
  const [state, setState] = useState(initialStateObject);

  const listButtonClicked = (item) => {
    //console.log('List button clicked: ' + id);
    setState((prevState) => ({
      ...prevState,
      selectedListItem: item.id,
    }));
    props.setFieldsData((prev) => ({ ...prev, tableData: item }));
  };

  useEffect(() => {
    if (props.raschetResultData === null) {
      setState((prevState) => ({
        ...prevState,
        selectedListItem: null,
      }));
    }
  }, [props.raschetResultData]);

  const data = props.raschetResultData;

  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column1,
            }}
          ></TableCell>
          <TableCell
            //padding={'15px'}
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column2,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              N
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column3,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              ПТО
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column4,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              Компоновка
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column5,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              S(м²)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column6,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              W порт гор/хол (м/с)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column7,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              ДУ(мм)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column8,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              К треб (Вт/(м²К))
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column9,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              Запас (%)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column10,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              Потери гор/хол (м вод. ст.)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column11,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              Стоимость (₽)
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const TableItem = () => {
    if (data) {
      //data.forEach((item) => {
      radioButtonsEnabled[data.id] = false;
      //});
      if (state.selectedListItem !== null) radioButtonsEnabled[state.selectedListItem] = true;
    }
    let selectedTextStyle = {
      ...commonStyles.text.robotoRegular18Light,
      ...getStyles().tableItemText,
      ...{ color: commonStyles.color.blue },
    };
    let nonSelectedTextStyle = {
      ...commonStyles.text.robotoRegular18Light,
      ...getStyles().tableItemText,
    };
    if (props.raschetResultData !== null) {
      return (
        <Table
          stickyHeader
          //cellHeight={40}
          cols={1}
        >
          <TableHeader />
          <TableBody>
            <TableRow
              //button
              key={data.id}
              onClick={() => {
                listButtonClicked(data);
              }}
            >
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column1,
                }}
              >
                <MuiThemeProvider theme={theme}>
                  <Radio checked={radioButtonsEnabled[data.id]} />
                </MuiThemeProvider>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column2,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.N}
                </Typography>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column3,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.pto}
                </Typography>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column4,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.layout}
                </Typography>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column5,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.S}
                </Typography>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column6,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.connection_liquid_velocity}
                </Typography>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column7,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.D}
                </Typography>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column8,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.K}
                </Typography>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column9,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.dimensioning_factor}
                </Typography>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column10,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.pressure_drop}
                </Typography>
              </TableCell>
              <TableCell
                align={'center'}
                style={{
                  ...getStyles().tableRowItem,
                  ...getStyles().tableItemsWidth.column11,
                }}
              >
                <Typography style={radioButtonsEnabled[data.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                  {data.cost}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } else {
      return (
        <Table
          stickyHeader
          //cellHeight={40}
          cols={1}
        >
          <TableHeader />
        </Table>
      );
    }
  };

  return (
    <Paper style={props.enabled ? getStyles().paper : getStyles().paperDisabled} elevation={3}>
      <Grid container style={getStyles().mainCell}>
        <Grid container style={getStyles().tableName}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...getStyles().headerText,
            }}
          >
            Решение
          </Typography>
        </Grid>

        <Grid container style={getStyles().middleItemCell}>
          <TableItem />
        </Grid>
        <Grid
          container
          style={
            data === null ? { ...getStyles().bottomItemCell, ...{ marginTop: '45px' } } : getStyles().bottomItemCell
          }
        >
          <Grid container style={getStyles().bottomItemRow}>
            <Grid>
              <MuiThemeProvider theme={theme}>
                <ButtonComponent
                  width="255px"
                  variant="contained"
                  color="primary"
                  disabled={state.selectedListItem === null ? true : false}
                  onClick={props.showCalculation}
                >
                  <Typography style={getStyles().buttonTextStyle}>Показать расчет</Typography>
                </ButtonComponent>
              </MuiThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const getStyles = () => {
  const styles = {
    paper: {
      background: '#FFFFFF',
      //height: '215px',
      width: '1346px',
      //width: '900px',
    },
    paperDisabled: {
      background: '#FFFFFF',
      height: '215px',
      width: '1346px',
      pointerEvents: 'none',
      opacity: '0.4',
      //width: '900px',
    },
    mainCell: {
      width: '100%',
      height: '100%',
      //border: '1px solid',
      //borderColor: 'red',
      flexDirection: 'column',
    },
    tableName: {
      width: '100%',
      height: '49px',
      //border: '1px solid',
      //borderColor: 'cyan',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableHeaderItem: {
      height: '47px',
      padding: 0,
      borderBottom: '1px solid',
      //border: '1px solid',
      borderColor: commonStyles.color.greyLight,
      background: commonStyles.color.white,
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableHeaderText: {
      textTransform: 'none',
      color: commonStyles.color.helperText,
    },
    middleItemCell: {
      width: '100%',
      //height: '37%',
      overflow: 'auto',
      //border: '1px solid',
      //borderColor: 'red',
    },
    //table: { maxHeight: '280px' },
    tableRowItem: {
      minHeight: '49px',
      padding: 0,
      //border: '1px solid',
      //borderColor: commonStyles.color.black,
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableItemsWidth: {
      column1: {
        width: '43px',
      },
      column2: { width: '30px' },
      column3: { width: '140px' },
      column4: { width: '158px' },
      column5: { width: '68px' },
      column6: { width: '178px' },
      column7: { width: '83px' },
      column8: { width: '188px' },
      column9: { width: '88px' },
      column10: { width: '233px' },
      column11: { width: '109px' },
    },
    tableItemText: {
      textTransform: 'none',
      color: '#020202',
    },
    bottomItemCell: {
      width: '100%',
      height: '71px',
      //border: '1px solid',
      //borderColor: 'orange',
      flexDirection: 'row',
    },
    bottomItemRow: {
      width: '100%',
      alignItems: 'center',
      //justifyContent: 'space-between',
      //flexDirection: 'row',
      //border: '1px solid',
      //borderColor: 'green',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    buttonTextStyle: {
      ...commonStyles.text.robotoRegular18,
      ...{ textTransform: 'none' },
    },
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
  };
  return styles;
};

export default RPTOPanel3Component;
