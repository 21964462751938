import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Slide from '@material-ui/core/Slide';

import Tabs from './Tabs';
import logo from '../../../assets/Logo.png';
import commonStyles from '../../../commonUtils/commonStyles';

function AuthForm() {
  const [checked, setChecked] = useState(false);
  const [registerSubmitted, setRegisterSubmitted] = useState(false);

  return (
    <Grid container>
      {!checked ? (
        <Slide direction="right" in={!checked} mountOnEnter unmountOnExit>
          <Paper elevation={3} style={getStyles().paperLogin}>
            <Grid container>
              <Grid item style={getStyles().header}>
                <CardMedia component="img" image={logo} style={getStyles().logoImage} />
              </Grid>
            </Grid>
            <Tabs
              checked={checked}
              setChecked={() => setChecked(!checked)}
              setRegisterSubmitted={setRegisterSubmitted}
            />
          </Paper>
        </Slide>
      ) : null}

      {checked ? (
        <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
          <Paper elevation={3} style={registerSubmitted ? getStyles().paperRegisterSmall : getStyles().paperRegister}>
            <Grid container>
              <Grid item style={getStyles().header}>
                <CardMedia component="img" image={logo} style={getStyles().logoImage} />
              </Grid>
            </Grid>
            <Tabs
              checked={checked}
              setChecked={() => setChecked(!checked)}
              setRegisterSubmitted={setRegisterSubmitted}
            />
          </Paper>
        </Slide>
      ) : null}
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    logoImage: {
      width: 'auto',
    },
    paperLogin: {
      margin: 'auto',
      maxWidth: 500,
      minWidth: 500,
      zIndex: 1,
      position: 'relative',
    },
    paperRegister: {
      width: 790,
      margin: 'auto',
      zIndex: 1,
      position: 'relative',
    },
    paperRegisterSmall: {
      maxWidth: 500,
      minWidth: 500,
      margin: 'auto',
      zIndex: 1,
      position: 'relative',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      padding: '30px 45px',
    },
    tabs: {
      background: commonStyles.color.blue,
      width: '100%',
    },
  };
  return styles;
};

export default AuthForm;
