import React from 'react';
import { Box, Typography, Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import TextInputComponent from '../../Components/Elements/TextInput/TextInputComponent';

import commonStyles from '../../../commonUtils/commonStyles';
import CustomCheckbox from '../../Components/Elements/CustomCheckbox';

const title = 'Система ГВС';
const labels = {
  connectionTypeGVS: 'Тип подключения',
  designMixedCircuit: 'Конструктивное исполнение двухступенчатой смешанной схемы',
  hourlyConsumption: 'Максимальный часовой расход воды ГВС',
  ptoReservationGVS: 'Резервирование ПТО',
  temperatureGraphGVS: 'Температурный график системы ГВС',
  hydraulicResistance: 'Гидравлическое сопротивление циркуляционного контура системы ГВС',
  designPressureGVS: 'Расчетное давление в системе ГВС',
  staticPressureGVS: 'Статический напор в ГВС',
  minColdWaterPressure: 'Мин. давление холодной воды (В1)',
  waterConsumptionGVS: 'Расход воды на циркуляцию ГВС, % от макс. расхода',
  circulationPumpGVS: 'Циркуляционный насос для ГВС',
  other: 'иное (уточнить)',
};

const HotWaterSupplySystem = ({ data, setValueHandler, setBoolHandler, stylesContext, formGridParameters }) => {
  const { md, xs } = formGridParameters;
  const { titleText, formBox, box, formContainer, labelPosition, radioGroup, inputRightMargin } = stylesContext;

  return (
    <Box style={{ ...formBox, ...box }}>
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {title}
      </Typography>

      <Grid container style={{ ...formContainer }} spacing={3}>
        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.connectionTypeGVS}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup value={data.connectionTypeGVS} onChange={(e) => setValueHandler(e, 'connectionTypeGVS')}>
            <Box display="flex">
              <FormControlLabel
                value="one step"
                control={<Radio color="primary" />}
                label="одноступенчатая параллельная"
              />
              <FormControlLabel
                value="two step"
                control={<Radio color="primary" />}
                label="двухступенчатая смешанная"
              />
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.designMixedCircuit}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup value={data.designMixedCircuit} onChange={(e) => setValueHandler(e, 'designMixedCircuit')}>
            <Box display="flex">
              <FormControlLabel
                value="one step"
                control={<Radio disabled={data.connectionTypeGVS !== 'two step'} color="primary" />}
                label="моноблок"
              />
              <FormControlLabel
                value="two step"
                control={<Radio disabled={data.connectionTypeGVS !== 'two step'} color="primary" />}
                label="2 раздельных теплообменника"
              />
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.hourlyConsumption}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form}>
          <Box width="50%">
            <TextInputComponent
              value_type="м³/ч"
              value={data.hourlyConsumption}
              onChange={(e) => setValueHandler(e, 'hourlyConsumption')}
            />
          </Box>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.ptoReservationGVS}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup
            style={{ ...radioGroup }}
            value={data.ptoReservationGVS}
            onChange={(e) => setValueHandler(e, 'ptoReservationGVS')}
          >
            <Box display="flex">
              <FormControlLabel value="no" control={<Radio color="primary" />} label="нет" />
              <FormControlLabel
                value="2 by 100% power"
                control={<Radio color="primary" />}
                label="2 шт. по 100% мощности "
              />
              <FormControlLabel
                value="2 by 50% power"
                control={<Radio color="primary" />}
                label="2 шт. по 50% мощности "
              />
            </Box>
            <Box display="flex">
              <Box>
                <FormControlLabel value="other" control={<Radio color="primary" />} label={labels.other} />
              </Box>
              <Box flexGrow={1}>
                <TextInputComponent
                  value_type=""
                  disabled={data.ptoReservationGVS !== 'other'}
                  value={data.ptoReservationOtherGVS}
                  onChange={(e) => setValueHandler(e, 'ptoReservationOtherGVS')}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.temperatureGraphGVS}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <Box width="50%" style={{ ...inputRightMargin }}>
            <TextInputComponent
              value_type="°С, Вход (B1)"
              value={data.temperatureGraphGVSInput}
              onChange={(e) => setValueHandler(e, 'temperatureGraphGVSInput')}
            />
          </Box>
          <Box width="50%">
            <TextInputComponent
              value_type="°С, Выход (Т3)"
              value={data.temperatureGraphGVSOutput}
              onChange={(e) => setValueHandler(e, 'temperatureGraphGVSOutput')}
            />
          </Box>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.hydraulicResistance}
          </Typography>
        </Grid>

        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <TextInputComponent
            value_type="кг\см²"
            value={data.hydraulicResistance}
            onChange={(e) => setValueHandler(e, 'hydraulicResistance')}
          />
        </Grid>

        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.designPressureGVS}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="кг\см²"
            value={data.designPressureGVS}
            onChange={(e) => setValueHandler(e, 'designPressureGVS')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.minColdWaterPressure}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="кг\см²"
            value={data.minColdWaterPressure}
            onChange={(e) => setValueHandler(e, 'minColdWaterPressure')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.staticPressureGVS}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="м"
            value={data.staticPressureGVS}
            onChange={(e) => setValueHandler(e, 'staticPressureGVS')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.waterConsumptionGVS}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="%"
            value={data.waterConsumptionGVS}
            onChange={(e) => setValueHandler(e, 'waterConsumptionGVS')}
          />
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.circulationPumpGVS}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form}>
          <Box display="flex">
            <CustomCheckbox
              checked={data.circulationPumpCOReservationGVS}
              label="резервирование"
              onChange={(e) => setBoolHandler(e, 'circulationPumpCOReservationGVS')}
            />
            <CustomCheckbox
              checked={data.circulationPumpCODoubleGVS}
              label="сдвоенный"
              onChange={(e) => setBoolHandler(e, 'circulationPumpCODoubleGVS')}
            />
            <CustomCheckbox
              checked={data.circulationPumpCOReservationFrequencyRegulationGVS}
              label="частотное регулирование"
              onChange={(e) => setBoolHandler(e, 'circulationPumpCOReservationFrequencyRegulationGVS')}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HotWaterSupplySystem;
