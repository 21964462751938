import React from 'react';

import { Grid } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import UserInfo from './Components/UserInfo';
import PasswordChange from './Components/PasswordChange';
import LoginChange from './Components/LoginChange';

import commonStyles from '../../commonUtils/commonStyles';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function PersonalInfoScreen() {
  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={1} />

      <Grid container style={getStyles().topGrid}>
        <Grid container>
          <Grid item xs={11} md={10} lg={9} style={getStyles().infoPanel}>
            <UserInfo />
          </Grid>
        </Grid>

        <Grid item xs={11} md={10} lg={9} style={getStyles().infoPanel}>
          <Grid container>
            <Grid item xs={6}>
              <PasswordChange />
            </Grid>
            <Grid item xs={6}>
              <LoginChange />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FooterComponent />
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    topGrid: {
      marginTop: '175px',
      marginBottom: '56px',
    },
    infoPanel: {
      margin: '0 auto 20px',
    },
  };
  return styles;
};
