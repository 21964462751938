export const isValidToken = (date) => {
  const hour = 60 * 60000; //60 minutes for token timeout /* ms */
  const hourAgo = Date.now() - hour;

  return date > hourAgo;
};

export const resetToken = (setGlobalState) => {
  localStorage.removeItem('tokens');
  if (window.location.pathname !== '/') {
    window.location.href = '/';
    setGlobalState((prevState) => ({
      ...prevState,
      toastOpen: true,
      toastMessage: 'Время сессии истекло, пожалуйста зайдите снова!',
      toastStyle: 'error',
    }));
  }
};
