import React from 'react';
import { Box, Typography, Grid, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import commonStyles from '../../../commonUtils/commonStyles';
import CustomCheckbox from '../../Components/Elements/CustomCheckbox';

const titles = {
  makeUpPump: 'Узел подпитки',
  inputNode: 'Узел ввода',
  heatMetering: 'Узел учёта тепловой энергии',
  powerSupply: 'Электропитание',
};

const labels = {
  makeUpPump: 'Подпиточный насос',
  solenoidValve: 'Соленоидный клапан подпитки',
  expansionTank: 'Расширительный бак',
  sump: 'Грязевик',
  filter: 'Фильтр',
  pressureRegulator: 'Регулятор перепада давления',
  general: 'Общий на ИТП',
  separatelyEachSystem: 'Отдельно на каждую систему',
  countingColdWaterConsumption: 'Учёт расхода ХВС',
};

const Bundles = ({ data, setBoolHandler, setValueHandler, stylesContext }) => {
  const { titleText, box } = stylesContext;
  const { bundle, bundleTitle } = getStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <Box style={{ ...box, ...bundle }} display="flex" justifyContent="flex-end" flexDirection="column">
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...titleText,
              ...bundleTitle,
            }}
          >
            {titles.makeUpPump}
          </Typography>

          <Box flexGrow={1} display="flex" justifyContent="flex-end" flexDirection="column">
            <CustomCheckbox
              checked={data.makeUpPump}
              label={labels.makeUpPump}
              onChange={(e) => setBoolHandler(e, 'makeUpPump')}
            />
            <CustomCheckbox
              checked={data.solenoidValve}
              label={labels.solenoidValve}
              onChange={(e) => setBoolHandler(e, 'solenoidValve')}
            />
            <CustomCheckbox
              checked={data.expansionTank}
              label={labels.expansionTank}
              onChange={(e) => setBoolHandler(e, 'expansionTank')}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={3}>
        <Box style={{ ...box, ...bundle }} display="flex" justifyContent="flex-end" flexDirection="column">
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...titleText,
              ...bundleTitle,
            }}
          >
            {titles.inputNode}
          </Typography>
          <Box flexGrow={1} display="flex" justifyContent="flex-end" flexDirection="column">
            <CustomCheckbox checked={data.sump} label={labels.sump} onChange={(e) => setBoolHandler(e, 'sump')} />
            <CustomCheckbox checked={data.filter} label={labels.filter} onChange={(e) => setBoolHandler(e, 'filter')} />
            <CustomCheckbox
              checked={data.pressureRegulator}
              label={labels.pressureRegulator}
              onChange={(e) => setBoolHandler(e, 'pressureRegulator')}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={3}>
        <Box style={{ ...box, ...bundle }} display="flex" justifyContent="flex-end" flexDirection="column">
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...titleText,
              ...bundleTitle,
            }}
          >
            {titles.heatMetering}
          </Typography>
          <Box flexGrow={1} display="flex" justifyContent="flex-end" flexDirection="column">
            <CustomCheckbox
              checked={data.general}
              label={labels.general}
              onChange={(e) => setBoolHandler(e, 'general')}
            />
            <CustomCheckbox
              checked={data.separatelyEachSystem}
              label={labels.separatelyEachSystem}
              onChange={(e) => setBoolHandler(e, 'separatelyEachSystem')}
            />
            <CustomCheckbox
              checked={data.countingColdWaterConsumption}
              label={labels.countingColdWaterConsumption}
              onChange={(e) => setBoolHandler(e, 'countingColdWaterConsumption')}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={3}>
        <Box style={{ ...box, ...bundle }} display="flex" justifyContent="center" flexDirection="column">
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...titleText,
            }}
          >
            {titles.powerSupply}
          </Typography>
          <Box flexGrow={1} display="flex" justifyContent="center" flexDirection="column">
            <RadioGroup value={data.powerSupply} onChange={(e) => setValueHandler(e, 'powerSupply')}>
              <FormControlLabel value="1х230В" control={<Radio color="primary" />} label="1х230В" />
              <FormControlLabel value="3х380В" control={<Radio color="primary" />} label="3х380В" />
            </RadioGroup>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const getStyles = () => {
  return {
    bundle: {
      padding: '0 20px 20px',
      minHeight: '250px',
    },
    bundleTitle: {
      margin: 'auto',
    },
  };
};

export default Bundles;
