import MainClass from '../main/MainClass';

const Balance = require('./CalcBalance/calculation_library/balance_calculation/dgvsBalance').default;
const DGVS = require('./CalcBalance/calculation_library/dgvs_calculation/dgvs').default;
const Value = require('./CalcBalance/calculation_library/Value').default;

let balanceNormal;
let balancePoverochniy;
const dgvs = new DGVS();

/*
power: 0.647,
power_type_select: 3,
zapas: 10,
hot_teplonositel_select: 0,
hot_koncentration_select: 0,
hot_mass_rashod: 21.599,
hot_temperature_vhod: 70,
hot_temperature_vyhod: 40,
hot_poteri_davlenia: 3,
cold_teplonositel_select: 0,
cold_koncentration_select: 0,
cold_mass_rashod: 10.803,
mass_rashod_type_select: 1,
cold_temperature_vhod: 5,
cold_temperature_vyhod: 65,
cold_poteri_davlenia: 3,
poteri_davlenia_type_select: 1,
automatic_data_calc_switch: true,
*/

export const powerTypes = ['кВт', 'МВт', 'ккал/ч', 'Гкал/ч'];
export const hot_teplonositelTypes = ['Насыщенный пар'];
export const cold_teplonositelTypes = ['Вода', 'Этиленгликоль', 'Пропиленгликоль'];

export const koncentrationPercentage = [
  null, //'Вода'
  ['30%', '35%', '40%', '45%', '50%', '55%', '60%', '65%'], //'Этиленгликоль'
  ['30%', '35%', '40%', '45%', '50%'], //'Пропиленгликоль'
];

export const mass_rashodType = ['кг/с', 'т/ч'];

export const poteri_davleniaType = ['кПа', 'м.вод.ст', 'бар'];

// !!! Normal

export function initBalanceCalculator(stateObj) {
  if (MainClass.getCurrentBalanceType() === 'Normal') {
    balanceNormal = new Balance(
      new Value(Number(stateObj.power), powerTypes[stateObj.power_type_select]), // МОЩНОСТЬs
      // ХОЛОДНАЯ СТОРОНА
      {
        conditionName: cold_teplonositelTypes[stateObj.cold_teplonositel_select], // СРЕДА
        Q_m: new Value(Number(stateObj.cold_mass_rashod), mass_rashodType[stateObj.mass_rashod_type_select]), // МАССОВЫЙ РАСХОД
        T_input: new Value(Number(stateObj.cold_temperature_vhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НА ВХОДЕ
        T_output: new Value(Number(stateObj.cold_temperature_vyhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НЕ ВЫХОДЕ
      },
      // ГОРЯЧАЯ СТОРОНА
      {
        conditionName: hot_teplonositelTypes[stateObj.hot_teplonositel_select], // СРЕДА
        Q_m: new Value(Number(stateObj.hot_mass_rashod), mass_rashodType[stateObj.mass_rashod_type_select]), // МАССОВЫЙ РАСХОД
        T_input: new Value(Number(stateObj.hot_temperature_vhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НА ВХОДЕ
        T_output: new Value(Number(stateObj.hot_temperature_vyhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НЕ ВЫХОДЕ
        Q_m2Stage: new Value(
          stateObj.rashod_greyushego_teplonositel,
          mass_rashodType[stateObj.rashod_greyushego_teplonositel_type_select]
        ), // Расход греющего теплоносителя на 2 ступень
      }
    );
  } else {
    let cold_teplonsitel = cold_teplonositelTypes[stateObj.cold_teplonositel_select];
    let hot_teplonsitel = hot_teplonositelTypes[stateObj.hot_teplonositel_select];
    if (cold_teplonsitel !== 'Вода' || cold_teplonsitel !== 'Насыщенный пар') {
      cold_teplonsitel =
        cold_teplonsitel +
        '-' +
        koncentrationPercentage[stateObj.cold_teplonositel_select][stateObj.cold_koncentration_select];
    }
    if (hot_teplonsitel !== 'Вода' || cold_teplonsitel !== 'Насыщенный пар') {
      hot_teplonsitel =
        hot_teplonsitel +
        '-' +
        koncentrationPercentage[stateObj.hot_teplonositel_select][stateObj.hot_koncentration_select];
    }
    //console.log('initBalance hot_teplonsitel: ' + hot_teplonsitel + ' cold_teplonsitel ' + cold_teplonsitel);
    balancePoverochniy = new Balance(
      new Value(Number(stateObj.power), powerTypes[stateObj.power_type_select]), // МОЩНОСТЬs
      // ХОЛОДНАЯ СТОРОНА
      {
        //conditionName: cold_teplonositelTypes[stateObj.cold_teplonositel_select], // СРЕДА
        conditionName: cold_teplonsitel, // СРЕДА
        Q_m: new Value(Number(stateObj.cold_mass_rashod), mass_rashodType[stateObj.mass_rashod_type_select]), // МАССОВЫЙ РАСХОД
        T_input: new Value(Number(stateObj.cold_temperature_vhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НА ВХОДЕ
        T_output: new Value(Number(stateObj.cold_temperature_vyhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НЕ ВЫХОДЕ
      },
      // ГОРЯЧАЯ СТОРОНА
      {
        //conditionName: hot_teplonositelTypes[stateObj.hot_teplonositel_select], // СРЕДА
        conditionName: hot_teplonsitel, // СРЕДА
        Q_m: new Value(Number(stateObj.hot_mass_rashod), mass_rashodType[stateObj.mass_rashod_type_select]), // МАССОВЫЙ РАСХОД
        T_input: new Value(Number(stateObj.hot_temperature_vhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НА ВХОДЕ
        T_output: new Value(Number(stateObj.hot_temperature_vyhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НЕ ВЫХОДЕ
      }
    );
    balancePoverochniy.isUseTemperatureBalance = false;
  }

  //console.log(balance.checkBalance()); // result -> {seccess: true/false, error: errorMassage}
}

export function updateTeplovoyBalance(name, value, metric, additionalParams = null) {
  //console.log('updateTeplovoyBalance type: ' + MainClass.getCurrentBalanceType());
  let balance = MainClass.getCurrentBalanceType() === 'Normal' ? balanceNormal : balancePoverochniy;
  /*
  console.log(
    'updateTeplovoyBalance name: ' +
      name +
      ' value: ' +
      value +
      ' metric: ' +
      metric +
      ' additionalParams: ' +
      JSON.stringify(additionalParams)
  );
  */

  if (name === 'power') {
    balance.setN(new Value(Number(value), metric));
  }
  if (name === 'power_type_select') {
    balance.setN(new Value(Number(value), metric));
  }
  if (name === 'hot_mass_rashod') {
    balance.setConditionValues('hotSide', { Q_m: new Value(Number(value), metric) });
  }
  if (name === 'cold_mass_rashod') {
    balance.setConditionValues('coldSide', { Q_m: new Value(Number(value), metric) });
  }
  if (name === 'mass_rashod_type_select') {
    balance.setConditionValues('hotSide', { Q_m: new Value(Number(additionalParams.hot_mass_rashod), metric) });
    balance.setConditionValues('coldSide', { Q_m: new Value(Number(additionalParams.cold_mass_rashod), metric) });
  }
  if (name === 'hot_temperature_vhod') {
    balance.setConditionValues('hotSide', {
      T_input: new Value(Number(value), 'град. Цельсия'),
    });
  }
  if (name === 'hot_temperature_vyhod') {
    balance.setConditionValues('hotSide', {
      T_output: new Value(Number(value), 'град. Цельсия'),
    });
  }
  if (name === 'cold_temperature_vhod') {
    balance.setConditionValues('coldSide', {
      T_input: new Value(Number(value), 'град. Цельсия'),
    });
  }
  if (name === 'cold_temperature_vyhod') {
    balance.setConditionValues('coldSide', {
      T_output: new Value(Number(value), 'град. Цельсия'),
    });
  }
  if (name === 'hot_teplonositel_select') {
    //для воды нет процентовки, для остальных теплоносителей - есть
    if (Number(value) === 0) {
      balance.setCondition('hotSide', 'Насыщенный пар');
    } else {
      balance.setCondition(
        'hotSide',
        hot_teplonositelTypes[Number(value)],
        parseInt(koncentrationPercentage[Number(value)][0])
      );

      //balance.setCondition('hotSide', 'Этиленгликоль', 30);
    }
  }
  if (name === 'cold_teplonositel_select') {
    //для воды нет процентовки, для остальных теплоносителей - есть
    if (Number(value) === 0) {
      balance.setCondition('coldSide', 'Вода');
    } else {
      //console.log('commonUtils hot_teplonositel_select: ' + perc);
      balance.setCondition(
        'coldSide',
        cold_teplonositelTypes[Number(value)],
        parseInt(koncentrationPercentage[Number(value)][0])
      );
    }
  }
  if (name === 'hot_koncentration_select') {
    balance.setCondition(
      'hotSide',
      hot_teplonositelTypes[Number(additionalParams.hot_teplonositelValue)],
      parseInt(koncentrationPercentage[Number(additionalParams.hot_teplonositelValue)][value])
    );
  }
  if (name === 'cold_koncentration_select') {
    balance.setCondition(
      'coldSide',
      cold_teplonositelTypes[Number(additionalParams.cold_teplonositelValue)],
      parseInt(koncentrationPercentage[Number(additionalParams.cold_teplonositelValue)][value])
    );
  }
  if (name === 'rashod_greyushego_teplonositel_type_select') {
    balance.setConditionValues('hotSide', { Q_m2Stage: new Value(Number(value), metric) });
    balance.setConditionValues('coldSide', { Q_m: new Value(balance.coldSide.props.Q_m) });
  }
  if (name === 'automatic_data_calc_switch') {
    balance.isUseTemperatureBalance = value;
  }

  //console.log('Test: ' + balance.hotSide.props.T_output.value);
  // console.log('balance recalculated Q_m2Stage: ' + JSON.stringify(balance.hotSide.props.Q_m2Stage.value));
  //console.log('updateTeplovoyBalance: ' + JSON.stringify(balance));
  //console.log('balance res: ' + JSON.stringify(balance.hotSide.props.Q_m2Stage));
  const updatedBalanceItems = {
    power: isNaN(balance.N.value) ? 0 : balance.N.value,
    hot_mass_rashod: MainClass.mainDataObj.TechnologyDGVSPanel1StateObject.hot_mass_rashod, //isNaN(balance.hotSide.props.Q_m.value) ? 0 : balance.hotSide.props.Q_m.value,
    cold_mass_rashod: isNaN(balance.coldSide.props.Q_m.value) ? 0 : balance.coldSide.props.Q_m.value,
    hot_temperature_vhod: isNaN(balance.hotSide.props.T_input.value) ? 0 : balance.hotSide.props.T_input.value,
    hot_temperature_vyhod: isNaN(balance.hotSide.props.T_output.value) ? 0 : balance.hotSide.props.T_output.value,
    cold_temperature_vhod: isNaN(balance.coldSide.props.T_input.value) ? 0 : balance.coldSide.props.T_input.value,
    cold_temperature_vyhod: isNaN(balance.coldSide.props.T_output.value) ? 0 : balance.coldSide.props.T_output.value,
    rashod_greyushego_teplonositel: isNaN(balance.hotSide.props.Q_m2Stage.value)
      ? 0
      : balance.hotSide.props.Q_m2Stage.value,
  };
  //console.log('updatedBalanceItems: ' + JSON.stringify(updatedBalanceItems));
  return updatedBalanceItems;
}

export function raschetTeplovoyBalance(stateObj, additionalData) {
  let balance = MainClass.getCurrentBalanceType() === 'Normal' ? balanceNormal : balancePoverochniy;
  let checkResult = balance.checkBalance();
  // error codes: 0, 1
  if (!checkResult.success) {
    return checkResult;
  }

  let dataAllStages = balance.getDataToServer();
  // YOUR CODE GOES HERE

  // TODO: you need to patch dataAllStages object: add "heatingData" and "workloadRatioN1Stage";
  // See example on the line 11
  // I will use fake data right now
  // remove and do it properly
  //console.log('raschetTeplovoyBalance. additionalData:');
  //console.log(JSON.stringify(additionalData));
  dataAllStages.workloadRatioN1Stage = stateObj.firstState_load_percentage;
  if (additionalData.heatingSystem === 'waterReturnFlow') {
    //console.log(additionalData)
    //Расход обратной отопительной воды
    dataAllStages.heatingData = {
      Q_m: {
        value: additionalData.panel2returnFlowHeatLoad,
        dimension: 'т/ч',
      },
    };
  }
  if (additionalData.heatingSystem === 'heatLoadSchedule') {
    // Тепловая нагрузка и отопительный график
    dataAllStages.heatingData = {
      NT: {
        N: {
          value: additionalData.panel2NT.N.scheduleHeatLoad,
          dimension: additionalData.panel2NT.N.scheduleHeatLoadType,
        },
        T_input: {
          value: additionalData.panel2NT.T_input.heatingScheduleFrom,
          dimension: additionalData.panel2NT.T_input.dimension,
        },
        T_output: {
          value: additionalData.panel2NT.T_output.heatingScheduleTo,
          dimension: additionalData.panel2NT.T_output.dimension,
        },
      },
    };
  }

  // calculate
  dgvs.calculateStagesData(dataAllStages);

  // const { heatBalanceData1Stage, heatBalanceData2Stage } = dgvs.getDataAboutStages()

  // check like the balance.checkBalance
  checkResult = dgvs.check();

  // error codes: 2, 3
  if (!checkResult.success) {
    return checkResult;
  }

  // we fine, send data to server for solutions list
  const dataToServer = dgvs.getDataToServer();

  // patch user restrictions, this is fine
  // TODO: patch dataToServer: add "additionalData" and it will be fine for sending to the server
  dataToServer.user_restrictions = {
    coldSide: {
      total_pressure_drop: {
        value: stateObj.cold_poteri_davlenia,
        dimension: poteri_davleniaType[stateObj.poteri_davlenia_type_select],
      },
    },
    hotSide: {
      total_pressure_drop: {
        value: stateObj.hot_poteri_davlenia,
        dimension: poteri_davleniaType[stateObj.poteri_davlenia_type_select],
      },
    },
    dimensioning_factor: stateObj.zapas,
  };

  return {
    success: true,
    data: dataToServer,
  };
}

export function getBalanceItemsData() {
  let balance = MainClass.getCurrentBalanceType() === 'Normal' ? balanceNormal : balancePoverochniy;
  const updatedBalanceItems = {
    power: isNaN(balance.N.value) ? 0 : balance.N.value,
    hot_mass_rashod: MainClass.mainDataObj.TechnologyDGVSPanel1StateObject.hot_mass_rashod, //isNaN(balance.hotSide.props.Q_m.value) ? 0 : balance.hotSide.props.Q_m.value,
    cold_mass_rashod: isNaN(balance.coldSide.props.Q_m.value) ? 0 : balance.coldSide.props.Q_m.value,
    hot_temperature_vhod: isNaN(balance.hotSide.props.T_input.value) ? 0 : balance.hotSide.props.T_input.value,
    hot_temperature_vyhod: isNaN(balance.hotSide.props.T_output.value) ? 0 : balance.hotSide.props.T_output.value,
    cold_temperature_vhod: isNaN(balance.coldSide.props.T_input.value) ? 0 : balance.coldSide.props.T_input.value,
    cold_temperature_vyhod: isNaN(balance.coldSide.props.T_output.value) ? 0 : balance.coldSide.props.T_output.value,
    rashod_greyushego_teplonositel: isNaN(balance.hotSide.props.Q_m2Stage.value)
      ? 0
      : balance.hotSide.props.Q_m2Stage.value,
  };
  //console.log('updatedBalanceItems: ' + JSON.stringify(balance.hotSide.Q_m2Stage));
  return updatedBalanceItems;
}
