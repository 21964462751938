import React from 'react';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Box from '@material-ui/core/Box';

import commonStyles from '../../../../commonUtils/commonStyles';

function AuthPasswordInputComponent(props) {
  /* props:
      label
      value
      onChange
      hasError
      helperText
      error
  */
  const { label, value, onChange, hasError, helperText = '', error, style } = props;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const errorText = 'Неверный логин или пароль';

  return (
    <FormControl variant="outlined" fullWidth error={error} style={style || getStyles().passwordInputContainer}>
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        style={getStyles().passwordInput}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={70}
      />
      {hasError && (
        <Box component="span" style={getStyles().errorMsg}>
          {helperText ? helperText : errorText}
        </Box>
      )}
    </FormControl>
  );
}

const getStyles = () => {
  const styles = {
    passwordInputContainer: {
      position: 'relative',
      marginBottom: '30px',
    },
    passwordInput: {
      font: 'small-caption',
      fontSize: '16px',
    },
    errorMsg: {
      color: commonStyles.color.authError,
      position: 'absolute',
      top: '100%',
    },
  };
  return styles;
};

export default AuthPasswordInputComponent;
