import React from 'react';

import { FormControl, Select, OutlinedInput, InputLabel } from '@material-ui/core';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import commonStyles from '../../../../commonUtils/commonStyles';
import PropTypes from 'prop-types';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.blue,
    },
  },
});

function CustomSelectComponent({ id, values, title, onChange, selected, padding, maxWidth }) {
  const selectValues = values.map((item, index) => (
    <option value={item.value} key={index}>
      {item.label}
    </option>
  ));

  return (
    <FormControl
      variant="outlined"
      style={{
        width: '100%',
        maxWidth: maxWidth,
      }}
    >
      {title && (
        <InputLabel shrink id={`${id}-label`} style={{ background: '#fff' }}>
          {title}
        </InputLabel>
      )}
      <MuiThemeProvider theme={theme}>
        <Select
          native
          id={id}
          value={selected}
          input={
            <OutlinedInput
              inputProps={{
                style: { padding: padding || '13px' },
              }}
            />
          }
          onChange={onChange}
        >
          {selectValues}
        </Select>
      </MuiThemeProvider>
    </FormControl>
  );
}

CustomSelectComponent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  padding: PropTypes.string,
  maxWidth: PropTypes.string,
};
CustomSelectComponent.defaultProps = {
  title: '',
  padding: '',
  maxWidth: '',
};

export default CustomSelectComponent;
