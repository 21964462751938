import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';

import MainClass from '../../main/MainClass';
//import * as Utils from '../../commonUtils/commonUtils';
import * as UtilsOVIC from '../../commonUtils/utilsOVIC';

import RPTOPanel1Component from './Components/RPTOPanel1/RPTOPanel1Component';
import RPTOPanel2Component from './Components/RPTOPanel2/RPTOPanel2Component';
import RPTOPanel3Component from './Components/RPTOPanel3/RPTOPanel3Component';

import { API_ENDPOINTS_LIST, BASE_URL } from '../../constants';
import useServerRequest from '../../hooks/serverRequest';
import { GlobalContext } from '../..';
import { useMultipleModal } from '../../hooks/modalHooks';
import PDFViewComponent from '../Components/PDFViewComponent/PDFViewComponent';
import AdditionInfoModalComponent from '../Components/AdditionInfoModalComponent/AdditionInfoModalComponent';
import { useHistory } from 'react-router-dom';

const initialStateObject = {
  komponovkaData: '',
  panel3Enabled: true,
};

const initialPanelTextFieldErrorState = {
  zapas: false,
  hot_mass_rashod: false,
  hot_temperature_vhod: false,
  cold_mass_rashod: false,
  cold_temperature_vhod: false,
  komponovka_type_field1: false,
  komponovka_type_field2: false,
};

function CalculateRPTOPoverochniyMainComponent() {
  const [state, setState] = useState(initialStateObject);
  const [panelTextFieldErrorState, setPanelTextFieldErrorState] = useState(initialPanelTextFieldErrorState);
  const [fieldsData, setFieldsData] = useState({});
  const [pdfToken, setPdfToken] = useState('');
  const [uniqueObjects, setUniqueObjects] = useState([]);

  const [modalOpen, closeModal, setModal] = useMultipleModal(false);
  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: raschetPoverochniy } = useServerRequest(setGlobalLoading);
  const { getRequest: getPto } = useServerRequest(setGlobalLoading);
  const { getRequest: setStore } = useServerRequest(setGlobalLoading);
  const { getRequest: getPDFToken } = useServerRequest(setGlobalLoading);
  const { getRequest: getUniqueObjectNames } = useServerRequest(setGlobalLoading);
  const history = useHistory();

  const downloadlink = `${BASE_URL}${API_ENDPOINTS_LIST.pdf_download}/${pdfToken}/`;
  const printLink = `${BASE_URL}${API_ENDPOINTS_LIST.pdf_view}/${pdfToken}/`;
  const hasSelected = !!MainClass.mainDataObj.selectedReshenieData;

  useEffect(() => {
    if (!hasSelected && MainClass.userRole === 'user') {
      history.goBack();
    } else {
      MainClass.setCurrentBalanceType('Poverochniy');
      let RPTOPanel1StateObject = JSON.parse(JSON.stringify(MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject));
      RPTOPanel1StateObject.power = '';
      RPTOPanel1StateObject.hot_temperature_vyhod = '';
      RPTOPanel1StateObject.hot_poteri_davlenia = '';
      RPTOPanel1StateObject.cold_temperature_vyhod = '';
      RPTOPanel1StateObject.cold_poteri_davlenia = '';

      MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject = JSON.parse(JSON.stringify(RPTOPanel1StateObject));

      MainClass.setPoverochniyRaschetResultData(null);

      setState((prevState) => ({
        ...prevState,
        RPTOPanel1StateObject: RPTOPanel1StateObject,
        panel3Enabled: true,
      }));

      getPto(API_ENDPOINTS_LIST.get_pto, '', 'GET').then((res) => {
        if (res && res.status === 200) {
          const komponovkaDataObj =
            MainClass.userRole === 'user'
              ? MainClass.getKomponovkaFromData(res.result)
              : MainClass.getKomponovkaFromDataRaschetchik(res.result);
          setState((prevState) => ({
            ...prevState,
            komponovkaData: komponovkaDataObj,
          }));
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Список ПТО получен!',
            toastStyle: 'success',
          }));
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
            toastStyle: 'error',
          }));
        }
      });
      window.scrollTo(0, 0);
    }
  }, []);

  const raschetButtonPressed = (resObj) => {
    MainClass.setPoverochniyRaschetResultData(null);
    setState((prevState) => ({
      ...prevState,
      panel3Enabled: true,
    }));

    let Panel1StateObject = JSON.parse(JSON.stringify(MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject));
    delete Panel1StateObject.power;
    delete Panel1StateObject.hot_temperature_vyhod;
    delete Panel1StateObject.hot_poteri_davlenia;
    delete Panel1StateObject.cold_temperature_vyhod;
    delete Panel1StateObject.cold_poteri_davlenia;
    let errorFields = [];
    for (var key in Panel1StateObject) {
      if (Panel1StateObject[key] === '') {
        errorFields.push(key);
      }
    }
    if (Number(Panel1StateObject.hot_temperature_vhod) < Number(Panel1StateObject.cold_temperature_vhod)) {
      errorFields.push('hot_temperature_vhod');
      errorFields.push('cold_temperature_vhod');
    }
    let panelTextFieldErrorState = {
      zapas: false,
      hot_mass_rashod: false,
      hot_temperature_vhod: false,
      cold_mass_rashod: false,
      cold_temperature_vhod: false,
      komponovka_type_field1: false,
      komponovka_type_field2: false,
    };
    if (errorFields.length !== 0) {
      errorFields.forEach((key) => {
        panelTextFieldErrorState[key] = true;
      });
      setPanelTextFieldErrorState(panelTextFieldErrorState);
      setGlobalState((prevState) => ({
        ...prevState,
        toastOpen: true,
        toastMessage: 'Найдены ошибки!',
        toastStyle: 'error',
      }));
    } else {
      setPanelTextFieldErrorState(panelTextFieldErrorState);
      let RPTOPoverochniyPanel2StateObject = JSON.parse(
        JSON.stringify(MainClass.mainDataObj.RPTOPoverochniyPanel2StateObject)
      );
      const layouts = RPTOPoverochniyPanel2StateObject.komponovka_type_variants;
      const layoutObj = {};
      if (layouts.length === 1) {
        layoutObj[layouts[0]] = RPTOPoverochniyPanel2StateObject.komponovka_type_field1;
      }
      if (layouts.length === 2) {
        layoutObj[layouts[0]] = RPTOPoverochniyPanel2StateObject.komponovka_type_field1;
        layoutObj[layouts[1]] = RPTOPoverochniyPanel2StateObject.komponovka_type_field2;
      }
      let RPTOPoverochniyPanel1StateObject = JSON.parse(
        JSON.stringify(MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject)
      );
      let conditionNameCold =
        UtilsOVIC.cold_teplonositelTypes[Number(RPTOPoverochniyPanel1StateObject.cold_teplonositel_select)];
      if (Number(RPTOPoverochniyPanel1StateObject.cold_teplonositel_select) !== 0) {
        conditionNameCold +=
          '-' +
          UtilsOVIC.koncentrationPercentage[RPTOPoverochniyPanel1StateObject.cold_teplonositel_select][
            RPTOPoverochniyPanel1StateObject.cold_koncentration_select
          ];
      }
      let conditionNameHot = UtilsOVIC.hot_teplonositelTypes[RPTOPoverochniyPanel1StateObject.hot_teplonositel_select];
      if (Number(RPTOPoverochniyPanel1StateObject.hot_teplonositel_select) !== 0) {
        conditionNameHot +=
          '-' +
          UtilsOVIC.koncentrationPercentage[RPTOPoverochniyPanel1StateObject.hot_teplonositel_select][
            RPTOPoverochniyPanel1StateObject.hot_koncentration_select
          ];
      }
      let requestObj = {
        pto: RPTOPoverochniyPanel2StateObject.filtrTypes[RPTOPoverochniyPanel2StateObject.filtr_select],
        additional_params: {
          N: {
            dimension: UtilsOVIC.powerTypes[RPTOPoverochniyPanel1StateObject.power_type_select],
          },
          coldSide: {
            total_pressure_drop: {
              dimension: UtilsOVIC.poteri_davleniaType[RPTOPoverochniyPanel1StateObject.poteri_davlenia_type_select],
            },
          },
          hotSide: {
            total_pressure_drop: {
              dimension: UtilsOVIC.poteri_davleniaType[RPTOPoverochniyPanel1StateObject.poteri_davlenia_type_select],
            },
          },
        },
        layout: layoutObj,
        coldSide: {
          props: {
            Q_m: {
              value: RPTOPoverochniyPanel1StateObject.cold_mass_rashod,
              dimension: UtilsOVIC.mass_rashodType[RPTOPoverochniyPanel1StateObject.mass_rashod_type_select],
            },
            T_input: {
              value: RPTOPoverochniyPanel1StateObject.cold_temperature_vhod,
              dimension: 'град. Цельсия',
            },
          },
          conditionName: conditionNameCold,
        },
        hotSide: {
          props: {
            Q_m: {
              value: RPTOPoverochniyPanel1StateObject.hot_mass_rashod,
              dimension: UtilsOVIC.mass_rashodType[RPTOPoverochniyPanel1StateObject.mass_rashod_type_select],
            },
            T_input: {
              value: RPTOPoverochniyPanel1StateObject.hot_temperature_vhod,
              dimension: 'град. Цельсия',
            },
          },
          conditionName: conditionNameHot,
        },
        user_restrictions: {
          dimensioning_factor: RPTOPoverochniyPanel1StateObject.zapas,
        },
      };
      const resObject = {
        ...requestObj,
        additionalData: fieldsData?.temperature ? fieldsData : resObj,
      };
      raschetPoverochniy(API_ENDPOINTS_LIST.raschet_poverochniy, JSON.stringify(resObject), 'POST').then((res) => {
        if (res && res.status === 200) {
          MainClass.setPoverochniyRaschetResultData(res.result.table_data);
          updateUI(res.result);
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Расчет успешно выполнен!',
            toastStyle: 'success',
          }));
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Ошибка! ' + res.code + ' ' + res.message,
            toastStyle: 'error',
          }));
        }
      });
    }
  };

  const updateUI = (resultObj) => {
    let RPTOPoverochniyPanel1StateObject = JSON.parse(
      JSON.stringify(MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject)
    );
    RPTOPoverochniyPanel1StateObject.power = resultObj.UI_data.N.value;
    RPTOPoverochniyPanel1StateObject.hot_temperature_vyhod = resultObj.UI_data.hotSide.T_output.value;
    RPTOPoverochniyPanel1StateObject.hot_poteri_davlenia = resultObj.UI_data.hotSide.total_pressure_drop.value;
    RPTOPoverochniyPanel1StateObject.cold_temperature_vyhod = resultObj.UI_data.coldSide.T_output.value;
    RPTOPoverochniyPanel1StateObject.cold_poteri_davlenia = resultObj.UI_data.coldSide.total_pressure_drop.value;
    MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject = RPTOPoverochniyPanel1StateObject;
  };

  const disablePanel3 = () => {
    if (state.panel3Enabled) {
      setState((prevState) => ({
        ...prevState,
        panel3Enabled: false,
      }));
    }
  };

  const saveCalculationHandler = (data) => {
    let RPTOPoverochniyPanel2StateObject = JSON.parse(
      JSON.stringify(MainClass.mainDataObj.RPTOPoverochniyPanel2StateObject)
    );
    const layouts = RPTOPoverochniyPanel2StateObject.komponovka_type_variants;
    const layoutObj = {};
    if (layouts.length === 1) {
      layoutObj[layouts[0]] = RPTOPoverochniyPanel2StateObject.komponovka_type_field1;
    }
    if (layouts.length === 2) {
      layoutObj[layouts[0]] = RPTOPoverochniyPanel2StateObject.komponovka_type_field1;
      layoutObj[layouts[1]] = RPTOPoverochniyPanel2StateObject.komponovka_type_field2;
    }
    let RPTOPoverochniyPanel1StateObject = JSON.parse(
      JSON.stringify(MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject)
    );
    let conditionNameCold = UtilsOVIC.cold_teplonositelTypes[RPTOPoverochniyPanel1StateObject.cold_teplonositel_select];
    if (RPTOPoverochniyPanel1StateObject.cold_teplonositel_select !== 0) {
      conditionNameCold +=
        '-' +
        UtilsOVIC.koncentrationPercentage[RPTOPoverochniyPanel1StateObject.cold_teplonositel_select][
          RPTOPoverochniyPanel1StateObject.cold_koncentration_select
        ];
    }
    let conditionNameHot = UtilsOVIC.hot_teplonositelTypes[RPTOPoverochniyPanel1StateObject.hot_teplonositel_select];
    if (RPTOPoverochniyPanel1StateObject.hot_teplonositel_select !== 0) {
      conditionNameHot +=
        '-' +
        UtilsOVIC.koncentrationPercentage[RPTOPoverochniyPanel1StateObject.hot_teplonositel_select][
          RPTOPoverochniyPanel1StateObject.hot_koncentration_select
        ];
    }
    let requestObj = {
      N: {
        value: RPTOPoverochniyPanel1StateObject.power,
        dimension: UtilsOVIC.powerTypes[RPTOPoverochniyPanel1StateObject.power_type_select],
      },
      coldSide: {
        props: {
          Q_m: {
            value: RPTOPoverochniyPanel1StateObject.cold_mass_rashod,
            dimension: UtilsOVIC.mass_rashodType[RPTOPoverochniyPanel1StateObject.mass_rashod_type_select],
          },
          T_input: {
            value: RPTOPoverochniyPanel1StateObject.cold_temperature_vhod,
            dimension: 'град. Цельсия',
          },
          T_output: {
            value: RPTOPoverochniyPanel1StateObject.cold_temperature_vyhod,
            dimension: 'град. Цельсия',
          },
        },
        conditionName: conditionNameCold,
      },
      hotSide: {
        props: {
          Q_m: {
            value: RPTOPoverochniyPanel1StateObject.hot_mass_rashod,
            dimension: UtilsOVIC.mass_rashodType[RPTOPoverochniyPanel1StateObject.mass_rashod_type_select],
          },
          T_input: {
            value: RPTOPoverochniyPanel1StateObject.hot_temperature_vhod,
            dimension: 'град. Цельсия',
          },
          T_output: {
            value: RPTOPoverochniyPanel1StateObject.hot_temperature_vyhod,
            dimension: 'град. Цельсия',
          },
        },
        conditionName: conditionNameHot,
      },
      user_restrictions: {
        dimensioning_factor: RPTOPoverochniyPanel1StateObject.zapas,
      },
    };
    const resultObj = {
      ...data,
      id: fieldsData.id,
      heatBalanceData: requestObj,
      additionalData: fieldsData.additionalData,
      tableData: fieldsData.tableData,
      isCheck: true,
    };

    delete resultObj.heatBalanceData.user_restrictions;
    delete resultObj.additionalData.id;

    resultObj.isCheck = true;

    setStore(API_ENDPOINTS_LIST.set_store, JSON.stringify(resultObj), 'POST').then((res) => {
      if (res && res.status === 200) {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Расчет успешно сохраннен!',
          toastStyle: 'success',
        }));
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const showCalculationHandler = () => {
    let RPTOPoverochniyPanel1StateObject = JSON.parse(
      JSON.stringify(MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject)
    );
    let conditionNameCold = UtilsOVIC.cold_teplonositelTypes[RPTOPoverochniyPanel1StateObject.cold_teplonositel_select];
    if (RPTOPoverochniyPanel1StateObject.cold_teplonositel_select !== 0) {
      conditionNameCold +=
        '-' +
        UtilsOVIC.koncentrationPercentage[RPTOPoverochniyPanel1StateObject.cold_teplonositel_select][
          RPTOPoverochniyPanel1StateObject.cold_koncentration_select
        ];
    }
    let conditionNameHot = UtilsOVIC.hot_teplonositelTypes[RPTOPoverochniyPanel1StateObject.hot_teplonositel_select];
    if (RPTOPoverochniyPanel1StateObject.hot_teplonositel_select !== 0) {
      conditionNameHot +=
        '-' +
        UtilsOVIC.koncentrationPercentage[RPTOPoverochniyPanel1StateObject.hot_teplonositel_select][
          RPTOPoverochniyPanel1StateObject.hot_koncentration_select
        ];
    }
    let requestObj = {
      N: {
        value: RPTOPoverochniyPanel1StateObject.power,
        dimension: UtilsOVIC.powerTypes[RPTOPoverochniyPanel1StateObject.power_type_select],
      },
      coldSide: {
        props: {
          Q_m: {
            value: RPTOPoverochniyPanel1StateObject.cold_mass_rashod,
            dimension: UtilsOVIC.mass_rashodType[RPTOPoverochniyPanel1StateObject.mass_rashod_type_select],
          },
          T_input: {
            value: RPTOPoverochniyPanel1StateObject.cold_temperature_vhod,
            dimension: 'град. Цельсия',
          },
          T_output: {
            value: RPTOPoverochniyPanel1StateObject.cold_temperature_vyhod,
            dimension: 'град. Цельсия',
          },
        },
        conditionName: conditionNameCold,
      },
      hotSide: {
        props: {
          Q_m: {
            value: RPTOPoverochniyPanel1StateObject.hot_mass_rashod,
            dimension: UtilsOVIC.mass_rashodType[RPTOPoverochniyPanel1StateObject.mass_rashod_type_select],
          },
          T_input: {
            value: RPTOPoverochniyPanel1StateObject.hot_temperature_vhod,
            dimension: 'град. Цельсия',
          },
          T_output: {
            value: RPTOPoverochniyPanel1StateObject.hot_temperature_vyhod,
            dimension: 'град. Цельсия',
          },
        },
        conditionName: conditionNameHot,
      },
      user_restrictions: {
        dimensioning_factor: RPTOPoverochniyPanel1StateObject.zapas,
      },
    };

    const resObj = {
      ...fieldsData,
      heatBalanceData: requestObj,
    };
    //console.log('showCalculationHandler resObj: ' + JSON.stringify(resObj));
    getPDFToken(API_ENDPOINTS_LIST.pdf_get_token, JSON.stringify(resObj), 'POST').then((res) => {
      if (res && res.status === 200) {
        setPdfToken(res.result.token);
        setModal('pdfViewModal');
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const getUniqueObject = () => {
    getUniqueObjectNames(API_ENDPOINTS_LIST.get_store_object_names_unique, '', 'GET').then((res) => {
      if (res && res.status === 200) {
        setUniqueObjects(res.result);
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  return (
    <Grid container style={getStyles().container}>
      <PDFViewComponent
        open={modalOpen === 'pdfViewModal'}
        handleClose={closeModal}
        links={{
          downloadlink,
          printLink,
        }}
      />
      <AdditionInfoModalComponent
        open={Boolean(modalOpen === 'additionInfoModal')}
        handleClose={closeModal}
        handleSubmit={saveCalculationHandler}
        uniqueObjects={uniqueObjects}
      />
      <Grid container style={MainClass.userRole === 'user' ? getStyles().topGrid : getStyles().topGridCalculator}>
        <Grid container style={getStyles().panelTopRowGrid}>
          <Grid style={{ paddingBottom: '20px', paddingRight: '10px' }}>
            <RPTOPanel1Component
              dataChangeEventHandler={() => {
                disablePanel3();
              }}
              initialStateObject={MainClass.mainDataObj.RPTOPoverochniyPanel1StateObject}
              textFieldErrorObj={panelTextFieldErrorState}
            />
          </Grid>
          <Grid style={{ paddingBottom: '20px', paddingLeft: '10px' }}>
            <RPTOPanel2Component
              dataChangeEventHandler={() => {
                disablePanel3();
              }}
              komponovkaData={state.komponovkaData}
              raschetButtonPressed={raschetButtonPressed}
              setErrorField={(fieldName1, val1, fieldName2, val2) => {
                if (fieldName2 !== null) {
                  setPanelTextFieldErrorState((prevState) => ({
                    ...prevState,
                    [fieldName1]: val1,
                    [fieldName2]: val2,
                  }));
                } else {
                  setPanelTextFieldErrorState((prevState) => ({
                    ...prevState,
                    [fieldName1]: val1,
                  }));
                }
              }}
              textFieldErrorObj={panelTextFieldErrorState}
              setFieldsData={setFieldsData}
            />
          </Grid>
        </Grid>
        <Grid container style={getStyles().panelBottomRowGrid}>
          <RPTOPanel3Component
            enabled={state.panel3Enabled}
            raschetResultData={MainClass.getPoverochniyRaschetResultData()}
            saveCalculation={() => {
              getUniqueObject();
              setModal('additionInfoModal');
            }}
            showCalculation={showCalculationHandler}
            setFieldsData={setFieldsData}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    container: {
      //border: '1px solid',
      //borderColor: 'red',
      justifyContent: 'center',
    },
    topGrid: {
      //width: '1346px',
      //border: '1px solid',
      marginTop: '170px',
      marginBottom: '56px',
    },
    topGridCalculator: {
      marginTop: '218px',
      marginBottom: '56px',
    },
    panelTopRowGrid: {
      //border: '1px solid',
      //borderColor: 'red',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      display: 'flex',
    },
    panelBottomRowGrid: {
      //border: '1px solid',
      //borderColor: 'blue',
      //marginTop: '20px',
      justifyContent: 'center',
      alignItems: 'flex-start',
      display: 'flex',
    },
  };
  return styles;
};

export default CalculateRPTOPoverochniyMainComponent;
