import React, { useState, useContext } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import commonStyles from '../../../../commonUtils/commonStyles';
import { API_ENDPOINTS_LIST, ERROR_TYPES_LIST } from '../../../../constants';
import { validatePassword } from '../../../../authUtils/validators';
import PasswordInput from '../PasswordInput';
import { GlobalContext } from '../../../..';
import useServerRequest from '../../../../hooks/serverRequest';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

const initialStateObject = {
  error: false,
  passwordError: '',
  passwordConfirmError: '',
};

export default function PasswordChange() {
  const [state, setState] = useState(initialStateObject);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: changePassword } = useServerRequest(setGlobalLoading);

  const comparePasswords = () => {
    return password === passwordConfirm;
  };

  const handleSubmit = () => {
    const isCorrect = validatePassword(password);
    if (!isCorrect)
      setState((prev) => ({
        ...prev,
        passwordError: ERROR_TYPES_LIST.invalidPassword,
      }));
    const isCorrect2 = validatePassword(password);
    if (!isCorrect)
      setState((prev) => ({
        ...prev,
        passwordConfirmError: ERROR_TYPES_LIST.invalidPassword,
      }));

    if (isCorrect && isCorrect2) {
      if (comparePasswords()) {
        changePassword(API_ENDPOINTS_LIST.change_password, JSON.stringify({ newPassword: password }), 'PUT', true).then(
          (res) => {
            if (res && !res.code) {
              setGlobalState((prevState) => ({
                ...prevState,
                toastOpen: true,
                toastMessage: 'Пароль успешно сменен!',
                toastStyle: 'success',
              }));
            } else {
              setGlobalState((prevState) => ({
                ...prevState,
                toastOpen: true,
                toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
                toastStyle: 'error',
              }));
            }
          }
        );
        setState(initialStateObject);
      } else {
        setState((prevState) => ({
          ...prevState,
          passwordError: 'Пароли должны совпадать!',
          passwordConfirmError: 'Пароли должны совпадать!',
        }));
      }
    }
  };

  return (
    <Paper style={getStyles().paper}>
      <Typography style={getStyles().header} variant="h6">
        Смена пароля
      </Typography>
      <Grid container style={getStyles().lableContainer}>
        <Grid item xs={5}>
          Новый пароль
        </Grid>
        <Grid item xs={7}>
          <PasswordInput
            id="password"
            type="text"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            color="primary"
            error={!!state.passwordError}
            helperText={state.passwordError}
            style={getStyles().input}
          />
        </Grid>
      </Grid>
      <Grid container style={getStyles().lableContainer}>
        <Grid item xs={5}>
          Подтверждения пароля
        </Grid>
        <Grid item xs={7}>
          <PasswordInput
            id="password-confirm"
            type="text"
            variant="outlined"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            error={!!state.passwordConfirmError}
            helperText={state.passwordConfirmError}
            style={getStyles().input}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={5} />
        <Grid item xs={7}>
          <Typography style={getStyles().textHelper} color="textSecondary">
            - 8 и более символов <br />
            - хотя бы одна цифра <br />- хотя бы один специальный символ
          </Typography>
          <ButtonComponent fullWidth variant="contained" color="primary" onClick={handleSubmit}>
            Подтвердить смену пароля
          </ButtonComponent>
        </Grid>
      </Grid>
    </Paper>
  );
}

const getStyles = () => {
  const styles = {
    header: {
      color: commonStyles.color.blue,
      fontSize: '23px',
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: '33px',
    },
    paper: {
      padding: '15px 20px 30px',
      marginRight: '10px',
    },
    row: {
      alignItems: 'center',
    },
    lableContainer: {
      alignItems: 'center',
      marginBottom: '15px',
    },
    input: {
      marginBottom: 0,
      label: {
        display: 'none',
      },
      legend: {
        display: 'none',
      },
    },
    textHelper: {
      fontSize: '.9rem',
      marginBottom: '26px',
    },
    errorMessage: {
      color: '#f44336',
      fontSize: '0.75rem',
      margin: '-12px 14px 5px',
    },
  };
  return styles;
};
