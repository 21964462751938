import React from 'react';
import {
  ItemObjectName,
  ItemCalculationNumber,
  ItemDate,
  ItemDestination,
  ItemComments,
  ItemSelect,
  ItemSelectMultiple,
  ItemDu,
  ItemTypo,
  ItemPres,
  ItemNum,
  ItemCount,
  ItemLink,
  ItemPlates,
  ItemUserId,
  ItemClientId,
  ItemName,
  //ItemSurname,
  //ItemFamily,
  //ItemCity,
  //ItemCountry,
  ItemTelephoneNumber,
  ItemEmail,
  ItemStatus,
  ItemPosition,
  ItemInn,
  ItemOrganization,
  ItemPto,
} from '../screens/Components/Elements/Table/TableItems';

export const ARCHIVE_TABLE = [
  {
    id: 'select',
    numeric: false,
    align: 'left',
    label: '',
    width: '40',
    padding: 'none',
    componentRender: (props) => <ItemSelectMultiple {...props} />,
  },
  {
    id: 'count',
    numeric: false,
    align: 'left',
    label: 'N',
    // width: "255",
    componentRender: (props) => <ItemCount {...props} />,
  },
  {
    id: 'nameOfObject',
    numeric: false,
    align: 'left',
    label: 'Название объекта',
    // width: "255",
    isSort: true,
    componentRender: (props) => <ItemObjectName {...props} />,
  },
  {
    id: 'number',
    numeric: false,
    align: 'left',
    label: 'Номер расчета',
    // width: "255",
    componentRender: (props) => <ItemCalculationNumber {...props} />,
  },
  {
    id: 'date',
    numeric: false,
    align: 'left',
    label: 'Дата расчета',
    // width: "255",
    componentRender: (props) => <ItemDate {...props} />,
  },
  {
    id: 'pto',
    numeric: false,
    align: 'left',
    label: 'ПТО',
    // width: "255",
    componentRender: (props) => <ItemPto {...props} />,
  },
  {
    id: 'destination',
    numeric: false,
    align: 'left',
    label: 'Назначение ПТО',
    // width: "255",
    componentRender: (props) => <ItemDestination {...props} />,
  },
  {
    id: 'commentaries',
    numeric: false,
    align: 'left',
    label: 'Комментарии',
    // width: "255",
    componentRender: (props) => <ItemComments {...props} />,
  },
];

export const ENTITY_USERS_TABLE = [
  {
    id: 'select',
    numeric: false,
    align: 'left',
    label: '',
    width: '40',
    padding: 'none',
    componentRender: (props) => <ItemSelect {...props} />,
  },
  {
    id: 'userId',
    numeric: true,
    align: 'left',
    label: 'ID',
    // width: "255",
    componentRender: (props) => <ItemUserId {...props} />,
  },
  {
    id: 'clientId',
    numeric: true,
    align: 'left',
    label: 'ClientID',
    // width: "255",
    componentRender: (props) => <ItemClientId {...props} />,
  },
  {
    id: 'firstName',
    numeric: false,
    align: 'left',
    label: 'ФИО',
    width: '255',
    componentRender: (props) => <ItemName {...props} />,
  },
  /*
  {
    id: 'middleName',
    numeric: false,
    align: 'left',
    label: 'Отчество',
    // width: "255",
    componentRender: (props) => <ItemSurname {...props} />,
  },
  */
  {
    id: 'position',
    numeric: false,
    align: 'left',
    label: 'Должность',
    width: '25',
    componentRender: (props) => <ItemPosition {...props} />,
  },
  {
    id: 'inn',
    numeric: false,
    align: 'left',
    label: 'ИНН',
    // width: "255",
    componentRender: (props) => <ItemInn {...props} />,
  },
  {
    id: 'companyName',
    numeric: false,
    align: 'left',
    label: 'Организация',
    // width: "255",
    componentRender: (props) => <ItemOrganization {...props} />,
  },
  {
    id: 'phone',
    numeric: false,
    align: 'left',
    label: 'Телефонный номер',
    // width: "255",
    componentRender: (props) => <ItemTelephoneNumber {...props} />,
  },
  {
    id: 'email',
    numeric: false,
    align: 'left',
    label: 'Почта',
    // width: "255",
    componentRender: (props) => <ItemEmail {...props} />,
  },
  {
    id: 'accepted',
    numeric: false,
    align: 'left',
    label: 'Статус',
    componentRender: (props) => <ItemStatus {...props} />,
  },
];

export const CATALOG_AUTOCAD_TABLE = [
  {
    id: 'du',
    numeric: false,
    align: 'left',
    label: 'ДУ, мм',
    // width: "40",
    componentRender: (props) => <ItemDu {...props} />,
  },
  {
    id: 'typo',
    numeric: false,
    align: 'left',
    label: 'Типоразмер',
    // width: "40",
    componentRender: (props) => <ItemTypo {...props} />,
  },
  {
    id: 'pres',
    numeric: false,
    align: 'left',
    label: 'Давление, МПа',
    // width: "40",
    componentRender: (props) => <ItemPres {...props} />,
  },
  {
    id: 'number',
    numeric: false,
    align: 'left',
    label: 'Номер рамы',
    // width: "40",
    componentRender: (props) => <ItemNum {...props} />,
  },
  {
    id: 'count',
    numeric: false,
    align: 'left',
    label: 'Количество пластин, диапазон',
    // width: "40",
    componentRender: (props) => <ItemPlates {...props} />,
  },
  {
    id: 'link',
    numeric: false,
    align: 'left',
    label: 'Ссылка на файл',
    // width: "40",
    componentRender: (props) => <ItemLink {...props} />,
  },
];
export const CATALOG_AUTODESC_TABLE = [
  {
    id: 'typo',
    numeric: false,
    align: 'left',
    label: 'Типоразмер',
    // width: "40",
    componentRender: (props) => <ItemTypo {...props} />,
  },
  {
    id: 'link',
    numeric: false,
    align: 'left',
    label: 'Ссылка на файл',
    width: '200',
    componentRender: (props) => <ItemLink {...props} />,
  },
];
