import MainClass from '../main/MainClass';

const Balance = require('./CalcBalance/calculation_library/balance_calculation/steamBalance').default;
const Value = require('./CalcBalance/calculation_library/Value').default;

let balanceNormal;
let balancePoverochniy;

/*
power: 0.647,
power_type_select: 3,
zapas: 10,
hot_teplonositel_select: 0,
hot_koncentration_select: 0,
hot_mass_rashod: 21.599,
hot_temperature_vhod: 70,
hot_temperature_vyhod: 40,
hot_poteri_davlenia: 3,
cold_teplonositel_select: 0,
cold_koncentration_select: 0,
cold_mass_rashod: 10.803,
mass_rashod_type_select: 1,
cold_temperature_vhod: 5,
cold_temperature_vyhod: 65,
cold_poteri_davlenia: 3,
poteri_davlenia_type_select: 1,
automatic_data_calc_switch: true,
*/

export const powerTypes = ['кВт', 'МВт', 'ккал/ч', 'Гкал/ч'];
export const hot_teplonositelTypes = ['Насыщенный пар'];
export const cold_teplonositelTypes = ['Вода', 'Этиленгликоль', 'Пропиленгликоль'];

export const koncentrationPercentage = [
  null, //'Вода'
  ['30%', '35%', '40%', '45%', '50%', '55%', '60%', '65%'], //'Этиленгликоль'
  ['30%', '35%', '40%', '45%', '50%'], //'Пропиленгликоль'
];

export const mass_rashodType = ['кг/с', 'т/ч'];

export const poteri_davleniaType = ['кПа', 'м.вод.ст', 'бар'];

// !!! Normal

export function initBalanceCalculator(stateObj) {
  if (MainClass.getCurrentBalanceType() === 'Normal') {
    balanceNormal = new Balance(
      new Value(Number(stateObj.power), powerTypes[stateObj.power_type_select]), // МОЩНОСТЬs
      // ХОЛОДНАЯ СТОРОНА
      {
        conditionName: cold_teplonositelTypes[stateObj.cold_teplonositel_select], // СРЕДА
        Q_m: new Value(Number(stateObj.cold_mass_rashod), mass_rashodType[stateObj.mass_rashod_type_select]), // МАССОВЫЙ РАСХОД
        T_input: new Value(Number(stateObj.cold_temperature_vhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НА ВХОДЕ
        T_output: new Value(Number(stateObj.cold_temperature_vyhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НЕ ВЫХОДЕ
      },
      // ГОРЯЧАЯ СТОРОНА
      {
        conditionName: hot_teplonositelTypes[stateObj.hot_teplonositel_select], // СРЕДА
        Q_m: new Value(Number(stateObj.hot_mass_rashod), mass_rashodType[stateObj.mass_rashod_type_select]), // МАССОВЫЙ РАСХОД
        T_input: new Value(Number(stateObj.hot_temperature_vhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НА ВХОДЕ
        T_output: new Value(Number(stateObj.hot_temperature_vyhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НЕ ВЫХОДЕ
      }
    );
  } else {
    let cold_teplonsitel = cold_teplonositelTypes[stateObj.cold_teplonositel_select];
    let hot_teplonsitel = hot_teplonositelTypes[stateObj.hot_teplonositel_select];
    if (cold_teplonsitel !== 'Вода' || cold_teplonsitel !== 'Насыщенный пар') {
      cold_teplonsitel =
        cold_teplonsitel +
        '-' +
        koncentrationPercentage[stateObj.cold_teplonositel_select][stateObj.cold_koncentration_select];
    }
    if (hot_teplonsitel !== 'Вода' || cold_teplonsitel !== 'Насыщенный пар') {
      hot_teplonsitel =
        hot_teplonsitel +
        '-' +
        koncentrationPercentage[stateObj.hot_teplonositel_select][stateObj.hot_koncentration_select];
    }
    //console.log('initBalance hot_teplonsitel: ' + hot_teplonsitel + ' cold_teplonsitel ' + cold_teplonsitel);
    balancePoverochniy = new Balance(
      new Value(Number(stateObj.power), powerTypes[stateObj.power_type_select]), // МОЩНОСТЬs
      // ХОЛОДНАЯ СТОРОНА
      {
        //conditionName: cold_teplonositelTypes[stateObj.cold_teplonositel_select], // СРЕДА
        conditionName: cold_teplonsitel, // СРЕДА
        Q_m: new Value(Number(stateObj.cold_mass_rashod), mass_rashodType[stateObj.mass_rashod_type_select]), // МАССОВЫЙ РАСХОД
        T_input: new Value(Number(stateObj.cold_temperature_vhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НА ВХОДЕ
        T_output: new Value(Number(stateObj.cold_temperature_vyhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НЕ ВЫХОДЕ
      },
      // ГОРЯЧАЯ СТОРОНА
      {
        //conditionName: hot_teplonositelTypes[stateObj.hot_teplonositel_select], // СРЕДА
        conditionName: hot_teplonsitel, // СРЕДА
        Q_m: new Value(Number(stateObj.hot_mass_rashod), mass_rashodType[stateObj.mass_rashod_type_select]), // МАССОВЫЙ РАСХОД
        T_input: new Value(Number(stateObj.hot_temperature_vhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НА ВХОДЕ
        T_output: new Value(Number(stateObj.hot_temperature_vyhod), 'град. Цельсия'), // ТЕМПЕРАТУРА НЕ ВЫХОДЕ
      }
    );
    balancePoverochniy.isUseTemperatureBalance = false;
  }

  //console.log(balance.checkBalance()); // result -> {seccess: true/false, error: errorMassage}
}

export function updateTeplovoyBalance(name, value, metric, additionalParams = null) {
  //console.log('updateTeplovoyBalance type: ' + MainClass.getCurrentBalanceType());
  let balance = MainClass.getCurrentBalanceType() === 'Normal' ? balanceNormal : balancePoverochniy;
  /*
  console.log(
    'updateTeplovoyBalance name: ' +
      name +
      ' value: ' +
      value +
      ' metric: ' +
      metric +
      ' additionalParams: ' +
      JSON.stringify(additionalParams)
  );
  */
  if (name === 'power') {
    balance.setN(new Value(Number(value), metric));
  }
  if (name === 'power_type_select') {
    balance.setN(new Value(Number(value), metric));
  }
  if (name === 'hot_mass_rashod') {
    balance.setConditionValues('hotSide', { Q_m: new Value(Number(value), metric) });
  }
  if (name === 'cold_mass_rashod') {
    balance.setConditionValues('coldSide', { Q_m: new Value(Number(value), metric) });
  }
  if (name === 'mass_rashod_type_select') {
    balance.setConditionValues('hotSide', { Q_m: new Value(Number(additionalParams.hot_mass_rashod), metric) });
    balance.setConditionValues('coldSide', { Q_m: new Value(Number(additionalParams.cold_mass_rashod), metric) });
  }
  if (name === 'hot_temperature_vhod') {
    balance.setConditionValues('hotSide', {
      T_input: new Value(Number(value), 'град. Цельсия'),
    });
  }
  if (name === 'hot_temperature_vyhod') {
    balance.setConditionValues('hotSide', {
      T_output: new Value(Number(value), 'град. Цельсия'),
    });
  }
  if (name === 'cold_temperature_vhod') {
    balance.setConditionValues('coldSide', {
      T_input: new Value(Number(value), 'град. Цельсия'),
    });
  }
  if (name === 'cold_temperature_vyhod') {
    balance.setConditionValues('coldSide', {
      T_output: new Value(Number(value), 'град. Цельсия'),
    });
  }
  if (name === 'hot_teplonositel_select') {
    //для воды нет процентовки, для остальных теплоносителей - есть
    if (Number(value) === 0) {
      balance.setCondition('hotSide', 'Насыщенный пар');
    } else {
      balance.setCondition(
        'hotSide',
        hot_teplonositelTypes[Number(value)],
        parseInt(koncentrationPercentage[Number(value)][0])
      );

      //balance.setCondition('hotSide', 'Этиленгликоль', 30);
    }
  }
  if (name === 'cold_teplonositel_select') {
    //для воды нет процентовки, для остальных теплоносителей - есть
    if (Number(value) === 0) {
      balance.setCondition('coldSide', 'Вода');
    } else {
      //console.log('commonUtils hot_teplonositel_select: ' + perc);
      balance.setCondition(
        'coldSide',
        cold_teplonositelTypes[Number(value)],
        parseInt(koncentrationPercentage[Number(value)][0])
      );
    }
  }
  if (name === 'hot_koncentration_select') {
    balance.setCondition(
      'hotSide',
      hot_teplonositelTypes[Number(additionalParams.hot_teplonositelValue)],
      parseInt(koncentrationPercentage[Number(additionalParams.hot_teplonositelValue)][value])
    );
  }
  if (name === 'cold_koncentration_select') {
    balance.setCondition(
      'coldSide',
      cold_teplonositelTypes[Number(additionalParams.cold_teplonositelValue)],
      parseInt(koncentrationPercentage[Number(additionalParams.cold_teplonositelValue)][value])
    );
  }
  if (name === 'automatic_data_calc_switch') {
    balance.isUseTemperatureBalance = value;
  }

  //console.log('Test: ' + balance.hotSide.props.T_output.value);
  const updatedBalanceItems = {
    power: isNaN(balance.N.value) ? 0 : balance.N.value,
    hot_mass_rashod: MainClass.mainDataObj.TechnologyPanel1StateObject.hot_mass_rashod, //isNaN(balance.hotSide.props.Q_m.value) ? 0 : balance.hotSide.props.Q_m.value,
    cold_mass_rashod: isNaN(balance.coldSide.props.Q_m.value) ? 0 : balance.coldSide.props.Q_m.value,
    hot_temperature_vhod: isNaN(balance.hotSide.props.T_input.value) ? 0 : balance.hotSide.props.T_input.value,
    hot_temperature_vyhod: isNaN(balance.hotSide.props.T_output.value) ? 0 : balance.hotSide.props.T_output.value,
    cold_temperature_vhod: isNaN(balance.coldSide.props.T_input.value) ? 0 : balance.coldSide.props.T_input.value,
    cold_temperature_vyhod: isNaN(balance.coldSide.props.T_output.value) ? 0 : balance.coldSide.props.T_output.value,
  };
  //console.log('updatedBalanceItems: ' + JSON.stringify(updatedBalanceItems));
  return updatedBalanceItems;
}

export function raschetTeplovoyBalance(stateObj) {
  let balance = MainClass.getCurrentBalanceType() === 'Normal' ? balanceNormal : balancePoverochniy;
  //console.log('raschetTeplovoyBalance. stateObj: ' + JSON.stringify(stateObj));
  const balanceRes = balance.checkBalance();

  if (balanceRes.success) {
    let objForServer = balance.getDataToServer();
    //objForServer.coldSide.conditionName = objForServer.coldSide.conditionName.toLowerCase();
    //objForServer.hotSide.conditionName = objForServer.hotSide.conditionName.toLowerCase();
    objForServer.user_restrictions = {
      coldSide: {
        total_pressure_drop: {
          value: stateObj.cold_poteri_davlenia,
          dimension: poteri_davleniaType[stateObj.poteri_davlenia_type_select],
        },
      },
      hotSide: {
        total_pressure_drop: {
          value: stateObj.hot_poteri_davlenia,
          dimension: poteri_davleniaType[stateObj.poteri_davlenia_type_select],
        },
      },
      dimensioning_factor: stateObj.zapas,
    };
    return {
      success: balanceRes.success,
      data: objForServer,
    };
  } else {
    return balanceRes;
  }

  /*
  let balanceResSuccess = balanceRes.success
    ? 'расчет успешно выполнен'
    : `ошибка при расчете. \nПричина ошибки: ${balanceRes.error.message}`;
  
  alert(
    'Данные расчета:' +
      '\nМощность: ' +
      balance.N.value +
      '\nГреющая сторона. температура на входе: ' +
      balance.hotSide.props.T_input.value +
      '\nГреющая сторона. температура на выходе: ' +
      balance.hotSide.props.T_output.value +
      '\nНагреваемая сторона. температура на входе: ' +
      balance.coldSide.props.T_input.value +
      '\nНагреваемая сторона. температура на выходе: ' +
      balance.coldSide.props.T_output.value +
      '\nГреющая сторона. массовый расход: ' +
      balance.hotSide.props.Q_m.value +
      '\nНагреваемая сторона. массовый расход: ' +
      balance.coldSide.props.Q_m.value +
      '\nРезультат расчета: ' +
      balanceResSuccess
  );
  */
  //console.log(balance.getDataToServer(), { depth: null });
}

export function getBalanceItemsData() {
  let balance = MainClass.getCurrentBalanceType() === 'Normal' ? balanceNormal : balancePoverochniy;
  const updatedBalanceItems = {
    power: isNaN(balance.N.value) ? 0 : balance.N.value,
    hot_mass_rashod: MainClass.mainDataObj.TechnologyPanel1StateObject.hot_mass_rashod, //isNaN(balance.hotSide.props.Q_m.value) ? 0 : balance.hotSide.props.Q_m.value,
    cold_mass_rashod: isNaN(balance.coldSide.props.Q_m.value) ? 0 : balance.coldSide.props.Q_m.value,
    hot_temperature_vhod: isNaN(balance.hotSide.props.T_input.value) ? 0 : balance.hotSide.props.T_input.value,
    hot_temperature_vyhod: isNaN(balance.hotSide.props.T_output.value) ? 0 : balance.hotSide.props.T_output.value,
    cold_temperature_vhod: isNaN(balance.coldSide.props.T_input.value) ? 0 : balance.coldSide.props.T_input.value,
    cold_temperature_vyhod: isNaN(balance.coldSide.props.T_output.value) ? 0 : balance.coldSide.props.T_output.value,
  };
  //console.log('updatedBalanceItems: ' + JSON.stringify(updatedBalanceItems));
  return updatedBalanceItems;
}
