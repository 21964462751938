import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function AuthSelectComponent(props) {
  /* props:
      value
      onChange
      variant
      label
      id
      name
      style
      options
  */
  const { value, onChange, variant, id, label, name, style, options } = props;

  return (
    <FormControl variant={variant} style={{ width: '100%' }}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        native
        value={value}
        onChange={onChange}
        label={label}
        style={style}
        inputProps={{
          name: name,
          id: id,
        }}
      >
        {Object.keys(options).map((option, idx) => (
          <option key={idx} value={option}>
            {options[option]}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
