import React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import commonStyles from '../../../commonUtils/commonStyles';
import CustomSelectComponent from '../../Components/Elements/CustomSelect/CustomSelectComponent';

const Filter = ({ data, selectedValue, selectedValueHandler }) => {
  const titleText = 'Название объекта';

  const selectData = [
    { value: '', label: 'Cбросить поиск' },
    ...[...new Set(data.map((item) => item.name))].map((item) => ({
      value: item,
      label: item.length > 50 ? `${item.substr(0, 50 - 1)}...` : item,
    })),
  ];

  const { card, title } = getStyles();

  return (
    <Card style={{ ...card }}>
      <Box display="flex" padding="10px">
        <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
          <Typography
            style={{
              ...title,
            }}
          >
            {titleText}
          </Typography>

          <CustomSelectComponent
            id={'select'}
            values={selectData}
            onChange={(e) => selectedValueHandler(e)}
            selected={selectedValue}
          />
        </Box>
      </Box>
    </Card>
  );
};

const getStyles = () => {
  return {
    card: {
      marginTop: '175px',
    },
    title: {
      minWidth: '160px',
      ...commonStyles.text.robotoRegular18,
      fontWeight: 400,
      color: '#202020',
    },
  };
};

export default Filter;
