import React from 'react';
import { Box, Typography, Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import TextInputComponent from '../../Components/Elements/TextInput/TextInputComponent';

import commonStyles from '../../../commonUtils/commonStyles';
import CustomCheckbox from '../../Components/Elements/CustomCheckbox';

const title = 'Система отопления (СО)';
const labels = {
  connectionTypeSO: 'Тип подключения',
  regulationTypeSO: 'Тип регулирования',
  heatingEnvironmentSO: 'Нагреваемая среда',
  ptoReservationSO: 'Резервирование ПТО',
  temperatureGraphSO: 'Температурный график СО',
  hydraulicResistanceSO: 'Гидравлическое сопротивление СО',
  waterVolumeSO: 'Объем воды в СО',
  designPressureSO: 'Расчетное давление в СО',
  staticPressureSO: 'Статический напор в СО',
  circulationPumpCO: 'Циркуляционный насос для СО',
  other: 'иное (уточнить)',
};

const HeatingSystem = ({ data, setValueHandler, setBoolHandler, stylesContext, formGridParameters }) => {
  const { titleText, formBox, box, formContainer, labelPosition, radioGroup, inputRightMargin } = stylesContext;
  const { md, xs } = formGridParameters;
  const { heatingEnvironmentForm } = getStyles();

  return (
    <Box style={{ ...formBox, ...box }}>
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {title}
      </Typography>

      <Grid container style={{ ...formContainer }} spacing={3}>
        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.connectionTypeSO}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup value={data.connectionTypeSO} onChange={(e) => setValueHandler(e, 'connectionTypeSO')}>
            <Box display="flex">
              <FormControlLabel
                value="dependent"
                control={<Radio color="primary" />}
                label="зависимая через насосы смешения"
              />
              <FormControlLabel value="independent" control={<Radio color="primary" />} label="независимая с ТО" />
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.regulationTypeSO}
          </Typography>
        </Grid>

        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup
            style={{ ...radioGroup }}
            value={data.regulationTypeSO}
            onChange={(e) => setValueHandler(e, 'regulationTypeSO')}
          >
            <FormControlLabel
              value="quality"
              control={<Radio color="primary" />}
              label="качественное по температурному графику с регулированием температуры Т2 по графику"
            />
            <Box display="flex">
              <Box>
                <FormControlLabel value="other" control={<Radio color="primary" />} label={labels.other} />
              </Box>
              <Box flexGrow={1}>
                <TextInputComponent
                  value_type=""
                  disabled={data.regulationTypeSO !== 'other'}
                  value={data.regulationTypeSOOther}
                  onChange={(e) => setValueHandler(e, 'regulationTypeSOOther')}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.heatingEnvironmentSO}
          </Typography>
        </Grid>

        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup value={data.heatingEnvironmentSO} onChange={(e) => setValueHandler(e, 'heatingEnvironmentSO')}>
            <Box display="flex">
              <FormControlLabel value="water" control={<Radio color="primary" />} label="вода" />
              <FormControlLabel value="ethylene glycol" control={<Radio color="primary" />} label="этиленгликоль" />
              <Box style={{ ...heatingEnvironmentForm, marginRight: '16px' }}>
                <TextInputComponent
                  value_type="%"
                  value={data.heatingEnvironmentEthyleneGlycolSO}
                  onChange={(e) => setValueHandler(e, 'heatingEnvironmentEthyleneGlycolSO')}
                />
              </Box>

              <FormControlLabel value="propylene glycol" control={<Radio color="primary" />} label="пропиленгликоль" />
              <Box style={{ ...heatingEnvironmentForm }}>
                <TextInputComponent
                  value_type="%"
                  style={{ ...heatingEnvironmentForm }}
                  value={data.heatingEnvironmentPropyleneGlycolSO}
                  onChange={(e) => setValueHandler(e, 'heatingEnvironmentPropyleneGlycolSO')}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.ptoReservationSO}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup
            style={{ ...radioGroup }}
            value={data.ptoReservationSO}
            onChange={(e) => setValueHandler(e, 'ptoReservationSO')}
          >
            <Box display="flex">
              <FormControlLabel value="no" control={<Radio color="primary" />} label="нет" />
              <FormControlLabel
                value="2 by 100% power"
                control={<Radio color="primary" />}
                label="2 шт. по 100% мощности "
              />
              <FormControlLabel
                value="2 by 50% power"
                control={<Radio color="primary" />}
                label="2 шт. по 50% мощности "
              />
            </Box>
            <Box display="flex">
              <Box>
                <FormControlLabel value="other" control={<Radio color="primary" />} label={labels.other} />
              </Box>
              <Box flexGrow={1}>
                <TextInputComponent
                  value_type=""
                  disabled={data.ptoReservationSO !== 'other'}
                  value={data.ptoReservationOtherSO}
                  onChange={(e) => setValueHandler(e, 'ptoReservationOtherSO')}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.temperatureGraphSO}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <Box display="flex" justifyContent="flex-start">
            <Box style={{ ...inputRightMargin }} width="50%">
              <TextInputComponent
                value_type="°С, Вход (Т21)"
                value={data.temperatureGraphSOInput}
                onChange={(e) => setValueHandler(e, 'temperatureGraphSOInput')}
              />
            </Box>
            <Box width="50%">
              <TextInputComponent
                value_type="°С, Выход (Т11)"
                value={data.temperatureGraphSOOutput}
                onChange={(e) => setValueHandler(e, 'temperatureGraphSOOutput')}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.hydraulicResistanceSO}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="кг\см²"
            value={data.hydraulicResistanceSO}
            onChange={(e) => setValueHandler(e, 'hydraulicResistanceSO')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.waterVolumeSO}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="л"
            value={data.waterVolumeSO}
            onChange={(e) => setValueHandler(e, 'waterVolumeSO')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.designPressureSO}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="кг\см²"
            value={data.designPressureSO}
            onChange={(e) => setValueHandler(e, 'designPressureSO')}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.staticPressureSO}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value_type="м"
            value={data.staticPressureSO}
            onChange={(e) => setValueHandler(e, 'staticPressureSO')}
          />
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.circulationPumpCO}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form}>
          <Box display="flex">
            <CustomCheckbox
              checked={data.circulationPumpCOReservationSO}
              label="резервирование"
              onChange={(e) => setBoolHandler(e, 'circulationPumpCOReservationSO')}
            />
            <CustomCheckbox
              checked={data.circulationPumpCODoubleSO}
              label="сдвоенный"
              onChange={(e) => setBoolHandler(e, 'circulationPumpCODoubleSO')}
            />
            <CustomCheckbox
              checked={data.circulationPumpCOReservationFrequencyRegulationSO}
              label="частотное регулирование"
              onChange={(e) => setBoolHandler(e, 'circulationPumpCOReservationFrequencyRegulationSO')}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const getStyles = () => {
  return {
    heatingEnvironmentForm: {
      maxWidth: '100px',
    },
  };
};

export default HeatingSystem;
