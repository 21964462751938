import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import logo from '../../assets/Logo.png';
import maintenance from '../../assets/maintenance.jpg';
import commonStyles from '../../commonUtils/commonStyles';

function MaintenanceScreen() {
  return (
    <Grid container style={getStyles().root}>
      <Grid container justify="center" alignItems="center" style={getStyles().grid}>
        <Grid container>
          <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            <Paper elevation={3} style={getStyles().paperMain}>
              <Grid container>
                <Grid item style={getStyles().header}>
                  <CardMedia component="img" image={logo} style={getStyles().logoImage} />
                  <CardMedia component="img" image={maintenance} style={getStyles().maintenanceImage} />
                  <Typography style={commonStyles.text.robotoRegular18}>
                    Чистим теплообменники, зайдите завтра
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Slide>
        </Grid>
      </Grid>
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    root: {
      background: commonStyles.color.background,
      width: '100%',
      height: '100vh',
    },
    grid: {
      //border: '1px solid',
      //borderColor: 'red',
      height: '100%',
    },
    logoImage: {
      width: 'auto',
    },
    maintenanceImage: {
      maxWidth: 500,
      marginTop: '30px',
      marginBottom: '30px',
    },
    paperMain: {
      margin: 'auto',
      maxWidth: 500,
      minWidth: 500,
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: '30px 45px',
    },
  };
  return styles;
};

export default MaintenanceScreen;
