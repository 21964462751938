import React, { useState, useContext, useEffect, useCallback } from 'react';
import { ThemeProvider, Grid, createMuiTheme } from '@material-ui/core';
import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import commonStyles from '../../commonUtils/commonStyles';
import CatalogTableComponent from './Components/CatalogTable/CatalogTableComponent';
import TopBarComponent from './Components/TopBar/TopBarComponent';

import { GlobalContext } from '../..';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../constants';
import MainClass from '../../main/MainClass';
import { getModelsFilters } from '../../commonUtils/commonUtils';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const defaultOptionsState = {
  platf: 'Autocad',
  typo: 'Не выбрано',
  pres: 'Не выбрано',
  plates: 'Не выбрано',
};

export default function ModelsScreen() {
  const [state, setState] = useState({
    ...defaultOptionsState,
  });
  const [modelsFilters, setModelsFilters] = useState({ typo: [], pres: [], plates: [] });

  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: getModels } = useServerRequest(setGlobalLoading);
  const defaultModelsArr = MainClass.getModelsArr();

  useEffect(() => {
    //console.log('platf update', state);
    //eslint-disable-next-line
  }, [state.platf]);

  const setStateHandler = (event, type) => {
    const val = event.target.value;
    if (type === 'platf') {
      setState({ ...defaultOptionsState, [type]: val, modelsObj: defaultModelsArr });
    } else {
      setState((prev) => ({ ...prev, [type]: val }));
    }
  };

  const sortModels = (obj) => {
    const sortArr = (arr) => {
      return arr.sort((a, b) => {
        if (b.typo > a.typo) return -1;
        if (b.typo < a.typo) return 1;
        return 0;
      });
    };

    const res = Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        return [key, sortArr(value)];
      })
    );

    return res;
  };

  useEffect(() => {
    getModels(API_ENDPOINTS_LIST.get_models, '', 'GET').then((res) => {
      if (res && res.status === 200) {
        MainClass.setModelsArr(res.result);
        setModelsFilters(getModelsFilters(res.result));

        setState((prev) => ({ ...prev, modelsObj: sortModels(res.result) }));
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  }, [getModels, setGlobalState]);

  const filterArr = useCallback(
    (arr) => {
      const currentArr = state.platf === 'Autocad' ? arr.autocad : arr.revit;
      return {
        ...arr,
        [state.platf === 'Autocad' ? 'autocad' : 'revit']: currentArr
          .filter((item) => {
            const itemTypo = state.typo === 'Не выбрано' ? true : item.typo.includes(state.typo);
            return itemTypo;
          })
          .filter((item) => {
            const itemPres = state.pres === 'Не выбрано' ? true : item.pres.includes(state.pres);
            const itemPlates = state.plates === 'Не выбрано' ? true : item.plates.includes(state.plates);
            return state.platf === 'Autocad' ? itemPres && itemPlates : true;
          }),
      };
    },
    [state.typo, state.pres, state.plates, state.platf]
  );

  useEffect(() => {
    if (defaultModelsArr) {
      setModelsFilters(getModelsFilters(filterArr(defaultModelsArr)));
    }
  }, [state.typo, state.pres, state.plates, state.platf, filterArr, defaultModelsArr]);

  const filterArrayHandler = () => {
    setState((prev) => ({ ...prev, modelsObj: filterArr(defaultModelsArr) }));
  };

  const clearFilterHandler = () => {
    setState((prev) => ({ ...prev, ...defaultOptionsState, modelsObj: defaultModelsArr }));
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={3} />
      <Grid container style={getStyles().topGrid}>
        <Grid container>
          <Grid item xs={11} md={10} lg={9} style={getStyles().infoPanel}>
            <TopBarComponent
              state={state}
              setState={setStateHandler}
              filterArray={filterArrayHandler}
              clearFilter={clearFilterHandler}
              modelsFilters={modelsFilters}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11} md={10} lg={9} style={getStyles().infoPanel}>
            <CatalogTableComponent rows={state.modelsObj || {}} selectedPlatform={state.platf} />
          </Grid>
        </Grid>
      </Grid>

      <FooterComponent />
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    topGrid: {
      marginTop: '175px',
      marginBottom: '56px',
    },
    infoPanel: {
      margin: '0 auto 20px',
    },
  };
  return styles;
};
