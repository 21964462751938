import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalContainer from '../Elements/Modal/ModalContainer';
import commonStyles from '../../../commonUtils/commonStyles';
import { Typography, Grid, Divider, Box } from '@material-ui/core';
import AuthTextInputComponent from '../Elements/AuthTextInput';
import { validateText, buildOptions } from '../../../commonUtils/commonUtils';
import CustomSelectComponent from '../Elements/CustomSelect/CustomSelectComponent';
import ButtonComponent from '../Elements/Button/ButtonComponent';
import { Autocomplete } from '@material-ui/lab';

import MainClass from '../../../main/MainClass';

const initialStateObject = {
  errorText: {
    nameOfObject: '',
    commentaries: '',
  },
  nameOfObject: '',
  commentaries: '',
  destination: 'ГВС двухступенчатая',
};

const destinationArr = [
  'Отопление',
  'Вентиляция',
  'Кондиционирование',
  'ГВС одноступенчатая',
  'ГВС двухступенчатая',
  'Насыщенный пар',
];

export default function AdditionInfoModalComponent({ open, handleClose, handleSubmit, uniqueObjects, saveButtonName }) {
  const [state, setState] = useState(initialStateObject);
  const submitHandler = () => {
    const isValidName = validateText(state.nameOfObject);
    // const isValidComment = validateText(state.commentaries);
    if (isValidName) {
      handleSubmit({
        nameOfObject: state.nameOfObject,
        destination: state.destination,
        commentaries: state.commentaries,
      });
      handleClose();
    } else {
      setState((prev) => ({
        ...prev,
        errorText: {
          nameOfObject: !isValidName ? 'Название обязательно к заполнению' : '',
          // commentaries: !isValidComment ? 'Комментарий обязателен к заполнению' : '',
        },
      }));
    }
  };

  const inputChangeHandler = (e, type) => {
    const data = e.target.value;
    setState((prev) => ({ ...prev, [type]: data }));
  };

  return (
    <>
      <ModalContainer open={open} handleClose={handleClose}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular24,
                ...getStyles().headerText,
              }}
            >
              Дополнительные данные
            </Typography>
          </Grid>
          <Divider variant="middle" />
          <Grid item xs={12}>
            <Autocomplete
              id="nameOfObject"
              options={buildOptions(uniqueObjects)}
              getOptionLabel={(option) => option.label}
              style={{ marginBottom: '30px' }}
              noOptionsText={'Нет совпадений'}
              onChange={(_, val) => inputChangeHandler({ target: { value: val?.value || '' } }, 'nameOfObject')}
              freeSolo
              renderInput={(params) => (
                <AuthTextInputComponent
                  {...params}
                  type="text"
                  label="Название объекта"
                  variant="outlined"
                  value={state.nameOfObject}
                  error={!!state.errorText.nameOfObject}
                  helperText={state.errorText.nameOfObject}
                  onChange={(e) => inputChangeHandler(e, 'nameOfObject')}
                />
              )}
            />
            <Box style={{ marginBottom: '30px' }}>
              <CustomSelectComponent
                id="destination"
                values={buildOptions(destinationArr)}
                onChange={(e) => inputChangeHandler(e, 'destination')}
                selected={state.destination}
                title="Назначение ПТО"
                padding="18.5px 14px"
              />
            </Box>
            {MainClass.userRole === 'user' && (
              <AuthTextInputComponent
                id="commentaries"
                style={{ marginBottom: '30px' }}
                type="text"
                label="Комментарии"
                variant="outlined"
                value={state.commentaries}
                multiline
                rows={2}
                onChange={(e) => inputChangeHandler(e, 'commentaries')}
                error={!!state.errorText.commentaries}
                helperText={state.errorText.commentaries}
              />
            )}
          </Grid>

          <Grid container spacing={2} justify="flex-end" style={getStyles().marginTop}>
            <Grid item>
              <ButtonComponent variant="outlined" color="primary" onClick={handleClose}>
                Отменить
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent variant="contained" color="primary" onClick={submitHandler}>
                {saveButtonName !== undefined ? saveButtonName : 'Сохранить'}
              </ButtonComponent>
            </Grid>
          </Grid>
        </Grid>
      </ModalContainer>
    </>
  );
}

const getStyles = () => {
  const styles = {
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
      textAlign: 'center',
    },
    marginTop: {
      marginTop: 20,
    },
  };
  return styles;
};

AdditionInfoModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  uniqueObjects: PropTypes.array.isRequired,
};
