import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import commonStyles from '../../../../commonUtils/commonStyles';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

function RegisterSuccess({ email, setTab }) {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const finalText = (
    <Typography variant="body2" gutterBottom style={getStyles().msgText}>
      Ваш почтовый адрес был успешно подтвержден. Наш менеджер свяжется с вами в течение трех рабочих дней.
    </Typography>
  );

  const confirmText = (
    <Typography variant="body2" gutterBottom style={getStyles().msgText}>
      На почтовый ящик{' '}
      <Box component="span" style={getStyles().email}>
        {email}
      </Box>{' '}
      придет письмо, содержащее ссылку для подтверждения адреса.
    </Typography>
  );

  return (
    <Grid container style={getStyles().root}>
      <Grid item xs={10} style={getStyles().textContainer}>
        {isConfirmed ? finalText : confirmText}
      </Grid>
      <Grid item style={getStyles().btnContainer}>
        <ButtonComponent
          variant="contained"
          color="primary"
          onClick={() => {
            setTab(0);
            setIsConfirmed(true);
          }}
        >
          Завершить
        </ButtonComponent>
      </Grid>
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    root: {
      padding: '0 40px 30px',
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    textContainer: {
      margin: '75px auto 180px',
    },
    email: {
      color: commonStyles.color.black,
      fontWeight: 'bold',
    },
    msgText: {
      color: commonStyles.color.helperText,
    },
  };
  return styles;
};

export default RegisterSuccess;
