import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LoginContainer from './LoginContainer';
import RegisterContainer from './RegisterContainer';
import { withStyles } from '@material-ui/core/styles';

import commonStyles from '../../../commonUtils/commonStyles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'span'} variant={'body2'}>
            {children}
          </Typography>
        </Box>
      )}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabsContainer({ setChecked, checked, setRegisterSubmitted }) {
  const [value, setValue] = React.useState(+checked);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setChecked();
    setRegisterSubmitted(false);
  };

  const CenteredTabs = withStyles({
    flexContainer: {
      justifyContent: 'space-evenly',
    },
  })(Tabs);

  return (
    <Grid container>
      <AppBar position="static">
        <CenteredTabs
          value={value}
          onChange={handleChange}
          aria-label="navigation tabs"
          style={getStyles().tabsWrapper}
          // centered
          TabIndicatorProps={{ style: { height: '4px' } }}
        >
          <Tab label="Вход" {...a11yProps(0)} style={getStyles().tab} />
          <Tab label="Заявка на регистрацию" {...a11yProps(1)} style={getStyles().tab} />
        </CenteredTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <LoginContainer />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RegisterContainer setRegisterSubmitted={setRegisterSubmitted} setTab={setValue} />
      </TabPanel>
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    tabsWrapper: {
      // '& .MuiTabs-flexContainer': {
      //   justifyContent: 'space-evenly',
      // },
      background: commonStyles.color.blue,
    },
    tab: {
      textTransform: 'none',
      fontSize: '1rem',
      textAlign: 'center',
    },
  };
  return styles;
};

export default TabsContainer;
