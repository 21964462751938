import React, { useEffect, useCallback, useState, useContext } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Radio,
  TableBody,
  Box,
} from '@material-ui/core';
//import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import makeStyles from '@material-ui/core/styles/makeStyles';
//import { GlobalContext } from '../../../../';

import MainClass from '../../../../main/MainClass';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import commonStyles from '../../../../commonUtils/commonStyles';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.blue,
    },
  },
});

let radioButtonsEnabled = [];

const initialStateObject = {
  selectedListItem: null,
  selectedItemData: null,
};

function RPTOPanel3Component(props) {
  const [state, setState] = useState({
    ...initialStateObject,
    data: props.raschetResultData,
    PtoMinToMax: 1,
    ptoIconsShow: false,
    PriceMinToMax: 1,
    priceIconsShow: false,
    handleRotatePto: {
      clicked: false,
      rotate: false,
    },
    handleRotatePrice: {
      clicked: false,
      rotate: false,
    },
  });

  //const { userRole } = useContext(GlobalContext);

  useEffect(() => {
    //console.log('props.raschetResultData mono: ' + JSON.stringify(props.raschetResultData));
    setState({
      ...state,
      data: props.raschetResultData !== null ? props.raschetResultData : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.raschetResultData]);

  const listButtonClicked = (item) => {
    const { id } = item;
    setState((prevState) => ({
      ...prevState,
      selectedListItem: id,
      selectedItemData: item,
    }));
    props.setFieldsData((prev) => ({ ...prev, tableData: item }));
  };

  const TableHeader = () => {
    const classes = useStyles(state);
    return (
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column1,
            }}
          />
          <TableCell
            //padding={'15px'}
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column2,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              N
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column3,
            }}
          >
            <Box
              style={{
                ...getStyles().actionCellGrid,
              }}
              className={state.handleRotatePto.clicked ? classes.wrapperClicked : classes.wrapper}
            >
              <Typography
                style={
                  state.handleRotatePto.clicked
                    ? {
                        ...commonStyles.text.robotoRegular16,
                        ...getStyles().tableHeaderTextClicked,
                      }
                    : {
                        ...commonStyles.text.robotoRegular16,
                        ...getStyles().tableHeaderText,
                      }
                }
              >
                ПТО
              </Typography>
            </Box>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column4,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              Ступени
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column5,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              S(м²)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column6,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              W порт гор/хол (м/с)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column7,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              ДУ(мм)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column8,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              К треб/расч (Вт/(м²К))
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column9,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              Запас (%)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column10,
            }}
          >
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16,
                ...getStyles().tableHeaderText,
              }}
            >
              Потери гор/хол (м вод. ст.)
            </Typography>
          </TableCell>
          <TableCell
            align={'center'}
            style={{
              ...getStyles().tableHeaderItem,
              ...getStyles().tableItemsWidth.column11,
            }}
          >
            <Box
              style={{
                ...getStyles().actionCellGrid,
              }}
              className={state.handleRotatePrice.clicked ? classes.wrapperClicked : classes.wrapper}
            >
              <Typography
                style={
                  state.handleRotatePrice.clicked
                    ? {
                        ...commonStyles.text.robotoRegular16,
                        ...getStyles().tableHeaderTextClicked,
                      }
                    : {
                        ...commonStyles.text.robotoRegular16,
                        ...getStyles().tableHeaderText,
                      }
                }
              >
                Стоимость(₽)
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const TableItem = () => {
    const { data } = state;
    if (data) {
      data.forEach((item) => {
        radioButtonsEnabled[item.id] = false;
      });
      if (state.selectedListItem !== null) radioButtonsEnabled[state.selectedListItem] = true;
    }
    let selectedTextStyle = {
      ...commonStyles.text.robotoRegular18Light,
      ...getStyles().tableItemText,
      ...{ color: commonStyles.color.blue },
    };
    let nonSelectedTextStyle = {
      ...commonStyles.text.robotoRegular18Light,
      ...getStyles().tableItemText,
    };
    if (data) {
      return (
        <Table stickyHeader cols={1}>
          <TableHeader />
          <TableBody>
            {data.map((item) => (
              <TableRow
                //button
                key={item.id}
                onClick={() => {
                  listButtonClicked(item);
                }}
              >
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column1,
                  }}
                >
                  <MuiThemeProvider theme={theme}>
                    <Radio checked={radioButtonsEnabled[item.id]} />
                  </MuiThemeProvider>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column2,
                  }}
                >
                  <Typography style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}>
                    {item.N}
                  </Typography>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column3,
                  }}
                >
                  {/*ПТО*/}
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.pto}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column4,
                  }}
                >
                  {/*Ступени*/}
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      Ступень 1
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      Ступень 2
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column5,
                  }}
                >
                  {/*S(м²)*/}
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage1.S}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage2.S}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column6,
                  }}
                >
                  {/*W порт гор/хол (м/с)*/}
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage1.connection_liquid_velocity}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage2.connection_liquid_velocity}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column7,
                  }}
                >
                  {/*ДУ(мм)*/}
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.D}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column8,
                  }}
                >
                  {/*К треб/расч (Вт/(м²К))*/}
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage1.K}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage2.K}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column9,
                  }}
                >
                  {/*Запас (%)*/}
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage1.dimensioning_factor}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage2.dimensioning_factor}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column10,
                  }}
                >
                  {/*Потери гор/хол (м вод. ст.)*/}
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage1.pressure_drop}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.stage2.pressure_drop}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell
                  align={'center'}
                  style={{
                    ...getStyles().tableRowItem,
                    ...getStyles().tableItemsWidth.column11,
                  }}
                >
                  {/*Стоимость(₽)*/}
                  <Grid item>
                    <Typography
                      style={radioButtonsEnabled[item.id] === false ? nonSelectedTextStyle : selectedTextStyle}
                    >
                      {item.cost}
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    } else {
      return (
        <Table
          stickyHeader
          //cellHeight={40}
          cols={1}
        >
          <TableHeader />
        </Table>
      );
    }
  };

  return (
    <Paper style={props.enabled ? getStyles().paper : getStyles().paperDisabled} elevation={3}>
      <Grid container style={getStyles().mainCell}>
        <Grid container style={getStyles().tableName}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...getStyles().headerText,
            }}
          >
            Решение
          </Typography>
        </Grid>

        <Grid container style={getStyles().middleItemCell}>
          <TableItem />
        </Grid>
        <Grid container style={getStyles().bottomItemCell}>
          <Grid container style={getStyles().bottomItemRow}>
            {/*<Grid style={{ marginRight: '18.5%' }}>
              <MuiThemeProvider theme={theme}>
                <ButtonComponent
                  width="255px"
                  variant="outlined"
                  color="primary"
                  disabled={state.selectedListItem === null}
                  onClick={() => props.poverochniyRaschetButtonPressed(state.selectedListItem)}
                >
                  <Typography style={getStyles().buttonTextStyle}>Поверочный расчет</Typography>
                </ButtonComponent>
              </MuiThemeProvider>
            </Grid>*/}
            <Grid style={getStyles().rightButtonsContainer}>
              {MainClass.userRole === 'user' && (
                <Grid style={getStyles().rightButton}>
                  <MuiThemeProvider theme={theme}>
                    <ButtonComponent
                      width="255px"
                      variant="outlined"
                      color="primary"
                      disabled={state.selectedListItem === null}
                      onClick={() => props.addToCart(state.selectedItemData)}
                    >
                      <Typography style={getStyles().buttonTextStyle}>Добавить в корзину</Typography>
                    </ButtonComponent>
                  </MuiThemeProvider>
                </Grid>
              )}
              {MainClass.userRole === 'user' && (
                <Grid style={getStyles().rightButton}>
                  <MuiThemeProvider theme={theme}>
                    <ButtonComponent
                      width="255px"
                      variant="outlined"
                      color="primary"
                      disabled={state.selectedListItem === null}
                      onClick={() => props.saveCalculation(state.selectedItemData)}
                    >
                      <Typography style={getStyles().buttonTextStyle}>Сохранить расчет</Typography>
                    </ButtonComponent>
                  </MuiThemeProvider>
                </Grid>
              )}
              <Grid>
                <MuiThemeProvider theme={theme}>
                  <ButtonComponent
                    width="255px"
                    variant="contained"
                    color="primary"
                    disabled={state.selectedListItem === null}
                    onClick={() => props.showCalculation(state.selectedItemData)}
                  >
                    <Typography style={getStyles().buttonTextStyle}>Показать расчет</Typography>
                  </ButtonComponent>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles(() => ({
  wrapper: {
    opacity: 1,
    '&:hover': {
      '& $icon': {
        opacity: 1,
        fill: '#787878',
      },
    },
  },

  wrapperClicked: {
    opacity: 1,
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    '&:active': {
      '& $iconClicked': {
        transform: 'rotate(180deg)',
        opacity: 1,
      },
      '& $rotatedIconUp': {
        transform: 'rotate(360deg)',
        opacity: 1,
      },
    },
  },

  icon: {
    opacity: 0,
    fontSize: '16px',
    fill: '#787878',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
  },

  iconClicked: {
    opacity: 1,
    fontSize: '16px',
    fill: '#000',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    transform: `rotate(360deg)`,
  },

  rotatedIconUp: {
    fontSize: '16px',
    opacity: 1,
    fill: '#000',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    transform: 'rotate(180deg)',
  },
}));

const getStyles = () => {
  const styles = {
    paper: {
      background: '#FFFFFF',
      height: '450px',
      width: '1346px',
      //width: '900px',
    },
    paperDisabled: {
      background: '#FFFFFF',
      height: '450px',
      width: '1346px',
      pointerEvents: 'none',
      opacity: '0.4',
      //width: '900px',
    },
    mainCell: {
      width: '100%',
      height: '100%',
      //border: '1px solid',
      //borderColor: 'red',
      flexDirection: 'column',
    },
    tableName: {
      width: '100%',
      height: '49px',
      //border: '1px solid',
      //borderColor: 'cyan',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableHeaderItem: {
      height: '47px',
      padding: 0,
      borderBottom: '1px solid',
      //border: '1px solid',
      borderColor: commonStyles.color.greyLight,
      background: commonStyles.color.white,
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableHeaderText: {
      textTransform: 'none',
      color: commonStyles.color.helperText,
    },
    tableHeaderTextClicked: {
      textTransform: 'none',
      color: '#000',
      transition: theme.transitions.create(['all'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.short,
      }),
    },
    middleItemCell: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      height: '73.3%',
      overflow: 'auto',
      //border: '1px solid',
      //borderColor: 'red',
    },
    //table: { maxHeight: '280px' },
    tableRowItem: {
      minHeight: '60px',
      padding: 10,
      //border: '1px solid',
      //borderColor: commonStyles.color.black,
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableItemsWidth: {
      column1: {
        width: '43px',
      },
      column2: { width: '30px' },
      column3: { width: '140px' },
      column4: { width: '158px' },
      column5: { width: '68px' },
      column6: { width: '178px' },
      column7: { width: '83px' },
      column8: { width: '188px' },
      column9: { width: '88px' },
      column10: { width: '233px' },
      column11: { width: '109px' },
    },
    tableItemText: {
      textTransform: 'none',
      color: '#020202',
    },
    bottomItemCell: {
      width: '100%',
      height: '71px',
      //border: '1px solid',
      //borderColor: 'orange',
      flexDirection: 'row',
    },
    bottomItemRow: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      //border: '1px solid',
      //borderColor: 'green',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    buttonTextStyle: {
      ...commonStyles.text.robotoRegular18,
      ...{ textTransform: 'none' },
    },
    rightButtonsContainer: {
      display: 'flex',
      marginLeft: 'auto',
    },
    rightButton: {
      marginRight: '15px',
    },
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    actionCell: {
      cursor: 'pointer',
    },
    actionCellGrid: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  return styles;
};

export default RPTOPanel3Component;
