import React, { memo, useRef, useEffect, useCallback } from 'react';
import ModalContainer from '../Elements/Modal/ModalContainer';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PDFViewer from 'pdf-viewer-reactjs';
import CustomNavigation from './Navigation';

function PDFViewComponent({ open, handleClose, links, rptoSaved, rptoCheck }) {
  const { downloadlink } = links;

  const eventHandler = useCallback(
    (e) => {
      e.preventDefault();
      rptoCheck();
    },
    [rptoCheck]
  );

  useEffect(() => {
    if (downloadlink !== '' && !rptoSaved) {
      window.addEventListener('contextmenu', eventHandler);
    }
    return () => {
      window.removeEventListener('contextmenu', eventHandler);
    };
  }, [downloadlink, rptoSaved, eventHandler]);

  const ShowPdfContent = memo(() => (
    <PDFViewer
      document={{
        url: downloadlink,
      }}
      scale={1}
      scaleStep={0.5}
      maxScale={3}
      minScale={0.5}
      navigation={(e) => CustomNavigation({ props: e, handleClose, links, rptoSaved, rptoCheck })}
      hideRotation={true}
      canvasCss="1"
    />
  ));

  return (
    <ModalContainer open={open} handleClose={handleClose} noPadding noBackground style={getStyles().root}>
      <Grid container>
        <Grid item style={getStyles().item}>
          <ShowPdfContent />
        </Grid>
      </Grid>
    </ModalContainer>
  );
}
const getStyles = () => {
  const styles = {
    root: {
      maxWidth: '1400px',
    },
    item: {
      margin: '0 auto',
    },
  };
  return styles;
};

PDFViewComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  links: PropTypes.object.isRequired,
  rptoSaved: PropTypes.bool,
  rptoCheck: PropTypes.func,
};
PDFViewComponent.defaultProps = {
  rptoSaved: true,
  rptoCheck: () => false,
};

export default memo(PDFViewComponent);
