import React, { useState } from 'react';

import AuthTextInputComponent from '../../../Components/Elements/AuthTextInput';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';

import { isCyrillic } from '../../../../authUtils/isCyrillic';
import commonStyles from '../../../../commonUtils/commonStyles';
import { USER_TYPES_LIST, ERROR_TYPES_LIST } from '../../../../constants';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

import Politics from '../../../../assets/politics.pdf';
import Rules from '../../../../assets/rules.pdf';

const addressErrors = {
  country: '',
  city: '',
  street: '',

  actualCountry: '',
  actualCity: '',
  actualStreet: '',
};

function SecondStep({
  firstStepValues,
  setCurrentStep,
  secondStepValues,
  handleSecondStepChange,
  handleSubmit,
  setSecondStepValues,
}) {
  const [isAgree, setIsAgree] = useState(false);
  const [errorsText, setErrorsText] = useState(addressErrors);
  const [valuesAreEqual, setValuesAreEqual] = useState(false);

  const toggleEqualValues = () => {
    setValuesAreEqual(!valuesAreEqual);
    setErrorsText((prevState) => ({
      ...prevState,
      actualCountry: '',
      actualCity: '',
      actualStreet: '',
    }));

    setSecondStepValues((prevState) => ({
      ...prevState,
      actualCountry: '',
      actualCity: '',
      actualStreet: '',
      actualBuilding: '',
      actualAppartment: '',
      actualPostalNumber: '',
    }));
  };

  const buildLegalEntityObj = () => {
    const userData = {
      type: USER_TYPES_LIST.legalEntities,
      middleName: firstStepValues.middleName,
      firstName: firstStepValues.firstName,
      lastName: firstStepValues.lastName,
      position: firstStepValues.position,
      companyName: firstStepValues.companyName,
      inn: firstStepValues.inn,
      phone: firstStepValues.phone.length > 4 ? firstStepValues.phone : '',
      email: firstStepValues.email,
      password: firstStepValues.password,

      legalLocation: {
        country: secondStepValues.country,
        city: secondStepValues.city,
        street: secondStepValues.street,
        building: secondStepValues.building,
        apartment: secondStepValues.apartment,
        postalNumber: secondStepValues.postalNumber,
      },
    };

    const individualLocation = {
      country: secondStepValues.actualCountry,
      city: secondStepValues.actualCity,
      street: secondStepValues.actualStreet,
      apartment: secondStepValues.actualAppartment,
      house: secondStepValues.building,
      postalCode: secondStepValues.actualPostalNumber,
    };

    if (valuesAreEqual) {
      userData.individualLocation = { ...userData.legalLocation };
    } else {
      userData.individualLocation = { ...individualLocation };
    }

    return userData;
  };

  const handleFormSubmit = async () => {
    const inputsToValidate = ['country', 'city', 'street', 'actualCountry', 'actualCity', 'actualStreet'];
    const newErrorTexts = {};

    inputsToValidate.forEach((field) => {
      if (secondStepValues[field] !== '') {
        const errorText = isCyrillic(secondStepValues[field]) ? '' : ERROR_TYPES_LIST.nonCyrillic;
        newErrorTexts[field] = errorText;
      } else {
        newErrorTexts[field] = '';
      }
    });

    setErrorsText(newErrorTexts);
    //console.log(newErrorTexts);

    const formHasNoErrors = Object.values(newErrorTexts).every((error) => error === '');
    //console.log('formHasNoErrors', formHasNoErrors);

    if (formHasNoErrors) {
      const userData = buildLegalEntityObj();
      handleSubmit(userData);
    }
  };

  return (
    <StyledGrid container>
      <Grid container>
        {/* Юридический адрес */}
        <Grid item xs={6} style={getStyles().gridItemLeft}>
          <Typography variant="body2" gutterBottom style={{ color: commonStyles.color.helperText }}>
            Юридический адрес
          </Typography>
          <AuthTextInputComponent
            id="country-2"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Страна"
            variant="outlined"
            value={secondStepValues.country}
            onChange={handleSecondStepChange('country')}
            error={!!errorsText.country}
            helperText={errorsText.country}
          />
          <AuthTextInputComponent
            id="city"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Населенный пункт"
            variant="outlined"
            value={secondStepValues.city}
            onChange={handleSecondStepChange('city')}
            error={!!errorsText.city}
            helperText={errorsText.city}
          />
          <AuthTextInputComponent
            id="street"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Улица"
            variant="outlined"
            value={secondStepValues.street}
            onChange={handleSecondStepChange('street')}
            error={!!errorsText.street}
            helperText={errorsText.street}
          />
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: '7px' }}>
              <AuthTextInputComponent
                id="building"
                style={{ marginBottom: '30px' }}
                type="text"
                label="Дом"
                variant="outlined"
                value={secondStepValues.building}
                onChange={handleSecondStepChange('building')}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: '7px' }}>
              <AuthTextInputComponent
                id="apartment"
                style={{ marginBottom: '30px' }}
                type="number"
                label="Квартира/Офис"
                variant="outlined"
                value={secondStepValues.apartment}
                onChange={handleSecondStepChange('apartment')}
              />
            </Grid>
          </Grid>
          <AuthTextInputComponent
            id="postalNumber"
            style={{ marginBottom: '30px' }}
            type="number"
            label="Почтовый индекс"
            variant="outlined"
            value={secondStepValues.postalNumber}
            onChange={handleSecondStepChange('postalNumber')}
          />
        </Grid>

        {/* Фактический адрес */}
        <Grid item xs={6} style={getStyles().gridItemLeft}>
          <Typography variant="body2" gutterBottom style={{ color: commonStyles.color.helperText }}>
            Фактический адрес
          </Typography>
          <AuthTextInputComponent
            id="actualCountry"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Страна"
            variant="outlined"
            value={secondStepValues.actualCountry}
            onChange={handleSecondStepChange('actualCountry')}
            disabled={valuesAreEqual}
            error={!!errorsText.actualCountry}
            helperText={errorsText.actualCountry}
          />
          <AuthTextInputComponent
            id="actualCity"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Населенный пункт"
            variant="outlined"
            value={secondStepValues.actualCity}
            onChange={handleSecondStepChange('actualCity')}
            disabled={valuesAreEqual}
            error={!!errorsText.actualCity}
            helperText={errorsText.actualCity}
          />
          <AuthTextInputComponent
            id="actualStreet"
            style={{ marginBottom: '30px' }}
            type="text"
            label="Улица"
            variant="outlined"
            value={secondStepValues.actualStreet}
            onChange={handleSecondStepChange('actualStreet')}
            disabled={valuesAreEqual}
            error={!!errorsText.actualStreet}
            helperText={errorsText.actualStreet}
          />
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: '7px' }}>
              <AuthTextInputComponent
                id="actualBuilding"
                style={{ marginBottom: '30px' }}
                type="text"
                label="Дом"
                variant="outlined"
                value={secondStepValues.actualBuilding}
                onChange={handleSecondStepChange('actualBuilding')}
                disabled={valuesAreEqual}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: '7px' }}>
              <AuthTextInputComponent
                id="actualAppartment"
                style={{ marginBottom: '30px' }}
                type="number"
                label="Квартира/Офис"
                variant="outlined"
                value={secondStepValues.actualAppartment}
                onChange={handleSecondStepChange('actualAppartment')}
                disabled={valuesAreEqual}
              />
            </Grid>
          </Grid>
          <AuthTextInputComponent
            id="actualPostalNumber"
            style={{ marginBottom: '30px' }}
            type="number"
            label="Почтовый индекс"
            variant="outlined"
            value={secondStepValues.actualPostalNumber}
            onChange={handleSecondStepChange('actualPostalNumber')}
            disabled={valuesAreEqual}
          />
        </Grid>
      </Grid>
      <Box component="div" style={getStyles().adressesText}>
        <Checkbox
          checked={valuesAreEqual}
          color="primary"
          onChange={toggleEqualValues}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          style={getStyles().checkBox}
        />
        <Box>Юридический и фактический адреса совпадают</Box>
      </Box>
      <Box component="div" style={getStyles().rulesContainer}>
        <Checkbox
          checked={isAgree}
          color="primary"
          onChange={() => setIsAgree(!isAgree)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          style={getStyles().checkBox}
        />
        <Box style={getStyles().rulesText}>
          Я принимаю условия{' '}
          <Link href={Politics} underline="always" target="_blank">
            Пользовательского соглашения
          </Link>{' '}
          и даю свое согласие “ЭТРА” на обработку моей персональной информации на условиях, определенных{' '}
          <Link href={Rules} underline="always" target="_blank">
            Политикой конфиденциальности
          </Link>
          .
        </Box>
      </Box>
      <Grid container justify="space-between" style={{ marginTop: '40px' }}>
        <Grid item>
          <ButtonComponent variant="outlined" color="primary" onClick={() => setCurrentStep(1)}>
            Назад
          </ButtonComponent>
        </Grid>
        <Grid item>
          <ButtonComponent variant="contained" color="primary" onClick={handleFormSubmit} disabled={!isAgree}>
            Подтвердить
          </ButtonComponent>
        </Grid>
      </Grid>
    </StyledGrid>
  );
}

const StyledGrid = styled(Grid)({
  padding: '0 40px 30px',
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    bottom: '-22px',
    fontSize: '0.85rem',
  },
  '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
});

const getStyles = () => {
  const styles = {
    gridItemLeft: {
      paddingRight: '15px',
    },
    gridItemRight: {
      paddingLeft: '15px',
    },
    rulesContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: '1rem',
    },
    adressesText: {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: '1rem',
      margin: '15px 0 30px',
    },
    checkBox: {
      padding: '0 20px 0 0',
    },
  };
  return styles;
};

export default SecondStep;
