import React from 'react';
import { Box, Typography, Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import TextInputComponent from '../../Components/Elements/TextInput/TextInputComponent';

import commonStyles from '../../../commonUtils/commonStyles';

const title = 'Параметры теплосети (ТС)';
const labels = {
  temperatureScheduleWinter: 'Температурный график ТС (зима)',
  temperatureGraphBreakPoint: 'Температурный график ТС (точка излома)',
  pressure: 'Давление в ТС',
  networkConnectionDiagram: 'Схема присоединения к тепловой сети',
  networkConnectionDiagramTwoPipes: '2-х трубная',
  networkConnectionDiagramThreePipes: '3-х трубная',
  networkConnectionDiagramFourPipes: '4-х трубная',
};

const NetworkParameters = ({ data, setValueHandler, stylesContext, formGridParameters }) => {
  const { titleText, formBox, box, formContainer, labelPosition, inputRightMargin } = stylesContext;
  const { md, xs } = formGridParameters;

  return (
    <Box style={{ ...formBox, ...box }}>
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {title}
      </Typography>

      <Grid container style={{ ...formContainer }} spacing={3}>
        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.temperatureScheduleWinter}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <Box style={{ ...inputRightMargin }} width="50%">
            <TextInputComponent
              value_type="°С, Вход (Т1)"
              value={data.temperatureScheduleWinterInput}
              onChange={(e) => setValueHandler(e, 'temperatureScheduleWinterInput')}
            />
          </Box>
          <Box width="50%">
            <TextInputComponent
              value_type="°С, Выход (Т2)"
              value={data.temperatureScheduleWinterOutput}
              onChange={(e) => setValueHandler(e, 'temperatureScheduleWinterOutput')}
            />
          </Box>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.temperatureGraphBreakPoint}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <Box style={{ ...inputRightMargin }} width="50%">
            <TextInputComponent
              value_type="°С, Вход (Т1)"
              value={data.temperatureGraphBreakPointInput}
              onChange={(e) => setValueHandler(e, 'temperatureGraphBreakPointInput')}
            />
          </Box>
          <Box width="50%">
            <TextInputComponent
              value_type="°С, Выход (Т2)"
              value={data.temperatureGraphBreakPointOutput}
              onChange={(e) => setValueHandler(e, 'temperatureGraphBreakPointOutput')}
            />
          </Box>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.pressure}
          </Typography>
        </Grid>
        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <Box style={{ ...inputRightMargin }} width="50%">
            <TextInputComponent
              value_type="кг\см², Вход (Р1)"
              value={data.pressureInput}
              onChange={(e) => setValueHandler(e, 'pressureInput')}
            />
          </Box>
          <Box width="50%">
            <TextInputComponent
              value_type="кг\см², Выход (Р2)"
              value={data.pressureOutput}
              onChange={(e) => setValueHandler(e, 'pressureOutput')}
            />
          </Box>
        </Grid>

        <Grid item xs={xs.label} md={md.label} style={{ ...labelPosition }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {labels.networkConnectionDiagram}
          </Typography>
        </Grid>

        <Grid item xs={xs.form} md={md.form} style={{ ...labelPosition }}>
          <RadioGroup
            value={data.networkConnectionDiagram}
            onChange={(e) => setValueHandler(e, 'networkConnectionDiagram')}
          >
            <Box display="flex">
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label={labels.networkConnectionDiagramTwoPipes}
              />
              <FormControlLabel
                value="3"
                control={<Radio color="primary" />}
                label={labels.networkConnectionDiagramThreePipes}
              />
              <FormControlLabel
                value="4"
                control={<Radio color="primary" />}
                label={labels.networkConnectionDiagramFourPipes}
              />
            </Box>
          </RadioGroup>
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    </Box>
  );
};

export default NetworkParameters;
