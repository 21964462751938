import React from 'react';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import CalculateRPTOMainComponent from './CalculateRPTOMainComponent';

function CalculateRPTOScreen() {
  return (
    <React.Fragment>
      <HeaderComponent screenNumber={0} topTabBarEnabled={true} topTabValue={0} />

      <CalculateRPTOMainComponent />

      <FooterComponent />
    </React.Fragment>
  );
}

export default CalculateRPTOScreen;
