import React from "react";
import { Tooltip, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import commonStyles from "../../../../commonUtils/commonStyles";
import Zoom from "@material-ui/core/Zoom";

const TooltipWrap = withStyles((theme) => ({
  tooltip: {
    maxWidth: "100%",
    backgroundColor: commonStyles.color.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 18,
    padding: 16,
    fontWeight: 400,
  },
}))(Tooltip);

function PopoverComponent({ text, size, placement, children }) {
  return (
    <TooltipWrap
      title={text}
      size={size}
      placement={placement}
      TransitionComponent={Zoom}
    >
      <Box>{children}</Box>
    </TooltipWrap>
  );
}

PopoverComponent.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  placement: PropTypes.string,
};

PopoverComponent.defaultProps = {
  size: "normal",
  placement: "right",
};

export default PopoverComponent;
