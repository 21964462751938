import React from 'react';
import { Paper, Grid, Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';
import CustomSelectComponent from '../../../Components/Elements/CustomSelect/CustomSelectComponent';
import commonStyles from '../../../../commonUtils/commonStyles';
import { buildOptions } from '../../../../commonUtils/commonUtils';

export default function TopBarComponent({
  uniqueObjects,
  uniqueFilter,
  setUniqueFilter,
  selectedRows,
  showCalculationPage,
  showCalculation,
  getModelLink,
  addToCart,
}) {
  let isSelectedRow = selectedRows.length === 1 && selectedRows[0].isCheck;
  /*
  if (selectedRows.length === 1) {
    console.log('TopBar: ' + JSON.stringify(selectedRows));
    console.log('isCheck: ' + selectedRows[0].isCheck);
  }
  */
  const uniqueValues = [{ value: '', label: 'Все' }, ...buildOptions(uniqueObjects)];
  return (
    <>
      <Paper style={getStyles().paper}>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonComponent
              variant="outlined"
              color="primary"
              onClick={() => getModelLink('revit')}
              disabled={!isSelectedRow}
              size="large"
            >
              3Д-модель для Autodesk Revit
            </ButtonComponent>
          </Grid>
          <Grid item>
            <ButtonComponent
              variant="outlined"
              color="primary"
              onClick={() => getModelLink('autocad')}
              disabled={!isSelectedRow}
              size="large"
            >
              3Д-модель для Autocad
            </ButtonComponent>
          </Grid>
          <Grid item style={getStyles().marginLeft}>
            <ButtonComponent
              variant="contained"
              color="primary"
              onClick={showCalculationPage}
              disabled={!isSelectedRow}
              size="large"
            >
              Поверочный расчет
            </ButtonComponent>
          </Grid>
          <Grid item>
            <ButtonComponent
              variant="contained"
              color="primary"
              onClick={showCalculation}
              disabled={isEmpty(selectedRows)}
              size="large"
            >
              Показать расчет
            </ButtonComponent>
          </Grid>
          <Grid item>
            <ButtonComponent
              variant="contained"
              color="primary"
              onClick={addToCart}
              disabled={isEmpty(selectedRows)}
              size="large"
            >
              Добавить в корзину
            </ButtonComponent>
          </Grid>
        </Grid>
      </Paper>
      <Box style={{ marginTop: '10px' }}>
        <Paper style={getStyles().paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular18,
                    minWidth: '160px',
                    marginRight: '15px',
                  }}
                >
                  Название объекта
                </Typography>
                <CustomSelectComponent
                  id="uniqueObjects"
                  values={uniqueValues}
                  selected={uniqueFilter}
                  onChange={(e) => setUniqueFilter(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

const getStyles = () => {
  const styles = {
    paper: {
      padding: '16px',
    },
    btn: {
      fontWeight: 600,
      fontSize: '16px',
      padding: '10px 14px',
      textTransform: 'none',
    },
    marginLeft: {
      marginLeft: 'auto',
    },
  };
  return styles;
};

TopBarComponent.propTypes = {
  uniqueObjects: PropTypes.array.isRequired,
  uniqueFilter: PropTypes.string.isRequired,
  setUniqueFilter: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  showCalculation: PropTypes.func.isRequired,
  showCalculationPage: PropTypes.func.isRequired,
};
